
.mod_form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}



.edit-form {
  position: relative;
  border: solid 10px #fff;
  border-radius: 20px;
  margin-bottom: 40px;
  @include media-breakpoint-down(md) {
    border-radius: 15px;
    margin-bottom: 20px;
  }

  &__header {
    position: relative;
    background: #fff;
    color: #000;
    text-align: center;
    padding: 0 10px 10px;
    .title {
      font-size: 1.6rem;
      font-weight: bold;
      margin: 0;
    }
  }
  &__inner {
    background: #65A5BA;
    padding: 20px;
  }

  .form-item {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    @include media-breakpoint-down(md) {
      flex-flow: row wrap;
    }
    &__icon {
      flex: 0 1 10%;
      @include media-breakpoint-down(md) {
        flex: 0 1 20%;
        order: 2;
      }
    }
    &__name {
      flex: 0 1 30%;
      padding: 5px 0;
      @include media-breakpoint-down(md) {
        flex: 0 1 80%;
        order: 1;
      }
    }
    &__main {
      flex: 0 1 70%;
      @include media-breakpoint-down(md) {
        flex: 0 1 100%;
        order: 3;
      }
    }
    &__btn {
      flex: 0 1 100%;
      text-align: center;
      padding-top: 60px;
      @include media-breakpoint-down(sm) {
        padding-top: 20px;
      }
    }
    .note {
      font-size: 1.4rem;
    }
  }

  .form-message {
    border: solid 5px #fff;
    border-radius: 20px;
    padding: 10px;
    margin: 0 3% 1em;
    background: rgba(6, 75, 96, .9);
    color: #fff;
    font-size: 1.6rem;
    text-align: center;
  }

  .alert-error {
    color: #f00;
    font-size: 1.4rem;
    padding: 10px;
  }

  .btn-disabled {
    background: none;
    border: solid 1px #999;
    padding: 10px;
    font-size: 1.4rem;
    color: #666;
  }

  .illustration-wrap {
    position: relative;
  }


  .illustration-1 {
    position: absolute;
    bottom: 0;
    left: 60px;
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: flex-end;
    @include media-breakpoint-down(md) {
      position: relative;
      bottom: 0;
      left: 0;
      margin-bottom: 20px;
    }
  }
  .illustration-2 {
    position: absolute;
    bottom: 0;
    right: 60px;
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: flex-end;
    @include media-breakpoint-down(md) {
      position: relative;
      bottom: 0;
      right: 0;
      justify-content: flex-end;
    }
    li {
      &:nth-child(even) {
        padding-bottom: 40px;
      }
    }
  }
}
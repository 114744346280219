#page-course-article {

  .side-nav {
    position: absolute;
    top: 0;
    right: 0;
    width: 320px;
    background: #fff;
    border-radius: 3px;
    .nav {
      .nav-link {
        color: #999;
        border-bottom: solid 2px #e5e5e5;
        padding: 1rem;
        background: rgba(255,255,255,.055);
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .main-content {
    min-height: 400px;
    width: 840px;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }


  .article {
    &__title-wrapper {
      margin-bottom: 20px;
    }
    &__before-title {
      font-weight: bold;
      font-size: 18px;
      margin-top: 20px;
      @include media-breakpoint-down(sm) {
        font-size: 1.4rem;
      }
    }
    &__title-inner {
      //width: 80%;
    }
    &__title-tip {
      display: inline-block;
      padding: 5px 10px;
      box-shadow: 3px 3px 0 #6B5B55;
      color: #fff;
      .name {
        border: solid 1px #fff;
      }
    }
    &__title {
      position: relative;
      //width: 1120px;
      margin: 0 auto;
      padding: 30px 40px;
      background-image: url("/statics/images/courses/bg_article_header.png");
      background-repeat: no-repeat;
      background-position: 5px center;
      background-size: 100% 100%;
      min-height: 180px;
      @include media-breakpoint-down(sm) {
        padding: 35px 20px 20px;
        font-size: 1.2rem;
      }
      .h2 {
        font-size: 3.2rem;
        @include media-breakpoint-down(sm) {
          font-size: 2.4rem;
        }
      }
    }
    &__body {
      padding: 20px;
      border-radius: 10px;
      background: rgba(255,255,255,.8);
      margin-bottom: 2em;
    }
    &__quest-list {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  .section {
    padding-bottom: 24px;
    &__header {
      border-bottom: solid 1px #e6e6e6;
      margin-bottom: 20px;
    }
    &__title {
      font-size: $h6-font-size;
      font-weight: bold;
      display: inline-block;
      letter-spacing: 0.5rem;
      padding: 5px 10px;
      color: #333;
      margin: 0 0 -1px 0;
      background: linear-gradient(transparent 50%, #f3f3bb 50%);
    }
    &__description {
      //background: #E1D2BE;
    }
  }

  .monster-in-title {
    position: absolute;
    top: 30px;
    right: 30px;
    margin-bottom: 20px;
    display: flex;
    &__body {
      text-align: center;
    }
    &__footer {
      padding: 10px;
      text-align: center;
      margin-bottom: 1em;
    }
    &__secondary {
      max-width: 160px;
    }
    .balloon {
      position: relative;
      display: inline-block;
      margin: 0 15px 0 0;
      padding: 7px 10px;
      min-width: 120px;
      max-width: 100%;
      color: #555;
      font-size: 16px;
      background: #fff;
      border-radius: 10px;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: -30px;
        margin-top: -15px;
        border: 15px solid transparent;
        border-left: 15px solid #fff;
      }
      p {
        margin: 0;
        padding: 0;
      }
    }
  }


  .system-monster {
    padding: 10px;
    border-radius: 10px;
    background: #097293;
    margin-bottom: 1em;
    border: solid 10px #fff;
    display: flex;
    justify-content: space-between;
    position: relative;
    @include media-breakpoint-down(sm) {
      display: block;
    }

    &__inner {
      text-align: center;
      flex: 0 1 800px;
      background: url("/statics/images/island/island_monster.svg") no-repeat center 0;
      @include media-breakpoint-down(sm) {
        margin-bottom: 10px;
      }
    }
    &__message {
      background: rgba(6, 75, 96,.9);
      border: solid 5px #fff;
      border-radius: 10px;
      margin: 20px 0 0;
      padding: 10px;
      text-align: left;
      color: #fff;
    }
    .button-area {
      padding-left: 10px;
    }

    .mini-monsters {
      position: relative;
      .monster {
        background: url("/statics/images/difficulty/monster_shadow.svg") no-repeat center bottom;
        background-size: 30px auto;
        padding: 50px 0 20px;
        animation: miniMonster 10s linear infinite;
      }
      .monster1 {
      }
      .monster2 {
        position: absolute;
        top: -20px;
        left: 35%;
        animation-delay: 1s;
      }
      .monster3 {
        position: absolute;
        top: -10px;
        left: 55%;
        animation-delay: 2s;
      }
    }
  }

  .heart-maker {
    .box {
      background: #FBDCFF;
      border: solid 4px #FF4D4D;
      padding: 20px;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include media-breakpoint-down(md) {
        display: block;
      }
    }
    .heart-list {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        margin: 0 5%;
        @include media-breakpoint-down(md) {
          margin: 0 2% 20px;
        }
      }
    }
    .heart_mark {
      cursor: pointer;
    }
    .button {
      position: relative;
      padding: 20px;
      @include media-breakpoint-down(md) {
        padding: 0;
        width: 100%;
        text-align: center;
      }
      .bln-custom {
        position: absolute;
        top: -70px;
        right: 0;
      }
    }
  }


}
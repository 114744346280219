// ----------------------------------------------------------------------------------------------------
// docs modules
// ----------------------------------------------------------------------------------------------------

#page-docs {
  &.page-guardian {
    line-height: 1.8;

    img {
      max-width: 100%;
    }

    .content-wrapper {
      //background: linear-gradient(to bottom, #0083bf 90%, #006a98 100% );
      padding-bottom: 50px;
      @include media-breakpoint-down(md) {
        overflow: visible;
      }
      &__inner {
        display: block;
      }
    }

    .pc-only {
      display: block;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .readme-content {
      @include media-breakpoint-down(md) {
        padding: 0 3%;
      }
    }

    .guard-content {
      max-width: 1024px;
      margin: auto;

      @include media-breakpoint-down(md) {
        padding: 0 3%;
      }
    }

    .guard-header {
      margin-bottom: 50px;
      padding-bottom: 20px;
      &__inner {
        padding: 20px;
        position: relative;
        z-index: 2;
        @include media-breakpoint-down(md) {
          padding: 10px;
        }

        .title {
          font-size: 3rem;
          font-weight: bold;
          color: #fff;
          text-align: center;
          margin-bottom: 1em;
        }

        .photo {
          text-align: center;
          margin-bottom: 2em;
        }
        
        .message {
          font-size: 1.8rem;
          text-align: center;
          color: #fff;
          @include media-breakpoint-down(md) {
            font-size: 1.6rem;
          }
        }
      }

    }

    .point-article {
      margin-bottom: 50px;

      > .title {
        color: #fff;
        background: #FF1D25;
        padding: 10px;
        font-size: 3rem;
        font-weight: bold;
        text-align: center;
        box-shadow: 0 0 3px #000;
        margin-bottom: 2rem;
        border-radius: 3px;
        @include media-breakpoint-down(md) {
          font-size: 2.4rem;
        }
      }

      .point-list {
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        margin: 0;
        padding: 0;
        list-style: none;

        > li {
          position: relative;
          flex: 0 1 50%;
          background: #fff;
          padding: 20px;
          text-align: center;

          @include media-breakpoint-down(md) {
            flex: 0 1 100%;
          }

          &:first-child {
            background: #00AEEF;
          }
          &:nth-child(2) {
            background: #CE61C1;
          }
          &:nth-child(3) {
            background: #FFC609;
          }
          &:nth-child(4) {
            background: #B2D235;
          }

          .title {
            color: #fff;
            font-size: 2.4rem;
            font-weight: bold;
            margin-bottom: 2rem;
            text-align: center;
            letter-spacing: 0.2rem;
            text-shadow: 1px 1px 0 rgba(0,0,0,.5);
          }
          .photo {
            text-align: center;
            margin-bottom: 2rem;

            img {
              border-radius: 20px;
              width: 313px;
              height: 212px;
              object-fit: cover;
            }
          }
          .message {
            min-height: 232px;
            background: rgba(255,255,255,.5);
            padding: 20px;
            border-radius: 10px;
            color: #2D1802;
            text-align: left;
            font-size: 1.8rem;
            font-weight: bold;
            text-shadow: 1px 1px 0 rgba(255,255,255,.5);
            @include media-breakpoint-down(md) {
              font-size: 1.6rem;
            }
          }
        }
      }

    }

    .functions-article {

      > .title {
        color: #fff;
        background: #FF1D25;
        padding: 10px;
        font-size: 3rem;
        font-weight: bold;
        text-align: center;
        box-shadow: 0 0 3px #000;
        margin-bottom: 2rem;
        border-radius: 3px;
        @include media-breakpoint-down(md) {
          font-size: 2.4rem;
        }
      }

      .functions-list {
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        margin: 0;
        padding: 0;
        list-style: none;

        > li {
          position: relative;
          flex: 0 1 32%;
          background: #fff;
          margin-bottom: 2rem;
          padding: 20px;
          text-align: center;
          border-radius: 3px;
          @include media-breakpoint-down(md) {
            flex: 0 1 100%;
          }

          .num {
            position: absolute;
            top: 0;
            left: 0;
          }
          .icon {
            padding: 30px 0 0;
            margin-bottom: 2rem;
          }
          .title {
            font-size: 2rem;
            font-weight: bold;
            margin-bottom: 1rem;
            min-height: 6rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .message {
            font-size: 1.8rem;
            text-align: left;
            @include media-breakpoint-down(md) {
              font-size: 1.6rem;
            }
          }
        }
      }

    }

    .digital-article {
      background: #000 url(/statics/images/docs/guardian/bg_1.svg) center 3px repeat;
      animation: docsDesitBg 20s linear infinite;
      background-size: auto;

      margin-bottom: 50px;
      @include media-breakpoint-down(md) {
        margin: 0 0 50px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &__inner {
        background-image: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,.7) 20%, rgba(0,0,0,.7) 80%, rgba(0,0,0,0) 100%);
        padding: 20px;
        width: 845px;
        margin: auto;
        text-align: center;
        color: #fff;
        @include media-breakpoint-down(md) {
          width: auto;
        }
      }
      
      .title {
        font-size: 2.4rem;
        font-weight: bold;
        margin-bottom: 2rem;
        color: #ff0;
        @include media-breakpoint-down(md) {
          font-size: 2rem;
        }
      }

      .message {
        font-size: 1.8rem;
        text-shadow: 0 0 3px #ff0;
        @include media-breakpoint-down(md) {
          font-size: 1.6rem;
        }

        p {
          margin-bottom: 2rem;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .world-article {
      margin-bottom: 50px;

      > .title {
        color: #fff;
        background: #FF1D25;
        padding: 10px;
        font-size: 3rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 2rem;
        border-radius: 3px;
        @include media-breakpoint-down(md) {
          font-size: 2.4rem;
        }
      }

      .world-list {
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        margin: 0;
        padding: 0;
        list-style: none;

        > li {
          position: relative;
          flex: 0 1 49%;
          background: #fff;
          margin-bottom: 2rem;
          text-align: center;
          border-radius: 3px;
          @include media-breakpoint-down(md) {
            flex: 0 1 100%;
          }

          .title {
            color: #fff;
            background: #FF1D25;
            padding: 10px;
            font-size: 2.4rem;
            font-weight: bold;
            text-align: center;
            @include media-breakpoint-down(md) {
              font-size: 2.4rem;
            }

            &.title-1 {
              background: #ED1E79;
            }
            &.title-2 {
              background: #B2D235;
            }
            &.title-3 {
              background: #FFC60B;
            }
            &.title-4 {
              background: #00AEEF;
            }
          }
          .icon {
            margin-bottom: 2rem;
            padding: 10px;
          }
          .message {
            text-align: left;
            font-size: 1.8rem;
            padding: 20px;
            min-height: 14rem;
            @include media-breakpoint-down(md) {
              font-size: 1.6rem;
            }
          }
        }
      }

    }

    .faq-article {
      background: #FFF0DE;
      padding: 20px;
      border-radius: 20px;
      margin-bottom: 50px;

      > .title {
        font-size: 3rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 2rem;
        @include media-breakpoint-down(md) {
          font-size: 2.4rem;
        }
      }
    }

    .children-voice-article {
      background: #D7EEF4;
      padding: 20px;
      border-radius: 20px;
      margin-bottom: 50px;

      > .title {
        font-size: 3rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 2rem;
        @include media-breakpoint-down(md) {
          font-size: 2.4rem;
        }
      }
    }

    .parents-voice-article {
      background: #F9DEEA;
      padding: 20px;
      border-radius: 20px;
      margin-bottom: 50px;

      > .title {
        font-size: 3rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 2rem;
        @include media-breakpoint-down(md) {
          font-size: 2.4rem;
        }
      }
    }

    .talk-section {
      margin-bottom: 60px;
      &:last-child {
        margin-bottom: 0;
      }

      .talk-box {
        display: flex;
        margin-bottom: 20px;

        &:nth-child(even) {
          //margin-left: 40px;
        }
      }
      .icon {
        flex: 0 0 90px;
        @include media-breakpoint-down(md) {
          flex: 0 0 50px;
        }

        img {
          border-radius: 40px;
          @include media-breakpoint-down(md) {
            width: 40px;
          }
        }
      }

      .balloon-left {
        flex: 0 1 auto;
        background: #fff;
        box-shadow: 3px 3px 1px rgba(0,0,0,.1);
        border-radius: 10px;
        padding: 20px;
        width: 100%;
        font-size: 1.8rem;

        position: relative;
        display: flex;
        align-items: center;
        margin-left: 20px;

        @include media-breakpoint-down(md) {
          font-size: 1.6rem;
        }

        &:before {
          content: "";
          position: absolute;
          top: 50px;
          left: -20px;
          margin-top: -15px;
          border: 10px solid transparent;
          border-right: 10px solid #fff;
        }
      }

      .balloon-right {
        flex: 0 1 auto;
        background: #fff;
        box-shadow: 3px 3px 1px rgba(0,0,0,.1);
        border-radius: 10px;
        padding: 20px;
        width: 100%;
        font-size: 1.8rem;

        position: relative;
        display: flex;
        align-items: center;
        margin-right: 20px;

        @include media-breakpoint-down(md) {
          font-size: 1.6rem;
        }

        &:before {
          content: "";
          position: absolute;
          top: 50px;
          right: -20px;
          margin-top: -15px;
          border: 10px solid transparent;
          border-left: 10px solid #fff;
        }
      }

      .message {
        &.question {
          position: relative;
          padding-left: 2em;
          font-weight: bold;
          &:before {
            display: block;
            content: "Q.";
            position: absolute;
            top: -10px;
            left: 0;
            color: #ED1D79;
            font-size: 3rem;
            font-weight: bold;
          }
        }
        &.answer {
          position: relative;
          padding-left: 2em;
          &:before {
            display: block;
            content: "A.";
            position: absolute;
            top: -10px;
            left: 0;
            color: #048BBF;
            font-size: 3rem;
            font-weight: bold;
          }
        }
      }

    }

    .regist {
      margin-bottom: 50px;
    }

    .footer-regist {
      position: sticky;
      z-index: 100;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      background: #006a98;
      padding: 10px;

      display: flex;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-down(md) {
        display: block;
      }

      a {
        img {
          transition: transform .2s;
          transform: scale(1);
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }

      .btn-regist {
        display: block;
        border: solid 10px #fff;
        border-radius: 20px;
        background: #F56900;
        padding: 10px 40px;
        color: #fff;
        transition: opacity .2s;
        @include media-breakpoint-down(md) {
          border: solid 7px #fff;
          padding: 5px;
        }
        &:hover {
          opacity: .8;
          text-decoration: none;
        }

        &__inner {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .icon {
          margin-right: 20px;
          @include media-breakpoint-down(md) {
            margin-right: 10px;
          }
        }
        .main {
          font-size: 3rem;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          @include media-breakpoint-down(md) {
            font-size: 2.4rem;
            display: block;
          }
        }
        .sub {
          font-size: 2rem;
          @include media-breakpoint-down(md) {
            display: block;
            font-size: 1.4rem;
          }
        }
      }
    }

  }


  // ----------------------------------------------------------------------------------------------------
  // page help
  // ----------------------------------------------------------------------------------------------------


  &.page-help {

    .docs-ttl {
      position: relative;
      background: url(/statics/images/students/regist_ttl.svg) no-repeat center 0;
      background-size: 100% auto;
      padding: 34px 50px 0 50px;
      min-height: 100px;
      margin-bottom: 30px;
      text-align: center;
      @include media-breakpoint-down(md) {
        padding: 0 10vw 10vw;
        min-height: auto;
      }
      .title {
        font-weight: bold;
        font-size: 2.4rem;
        margin: 0;
        @include media-breakpoint-down(md) {
          position: absolute;
          left: 5vw;
          bottom: 2vw;
          @include fz_vw(12);
        }
      }
    }

    .docs-article {
      margin-bottom: 4em;
    }

    .docs-sub-ttl {
      display: flex;
      align-items: center;
      margin-bottom: 1em;
      .icon {
        margin-right: 5px;
      }
      .title {
        position: relative;
        color: #fff;
        font-size: 2rem;
        font-weight: bold;
        background: rgba(0, 0, 0, .5);
        border-radius: 20px;
        width: 100%;
        padding: 10px 80px 10px 20px;
        margin: 0;
        @include media-breakpoint-down(md) {
          font-size: 1.6rem;
        }
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          right: 10px;
          display: block;
          width: 70px;
          height: 55px;
          background: url("/statics/images/icon/icon_ttl_bg.svg") no-repeat right bottom;
          background-size: 100%;
          @include media-breakpoint-down(md) {
            background-size: 80%;
          }
        }
      }
    }

    .docs-courses-container {
      display:flex; 
      flex-direction:row; 
      flex-wrap: wrap; 
      width:100%;
      align-items: center;
      justify-content: center;
    }
    .docs-courses-container {
      display:flex; 
      flex-direction:row; 
      flex-wrap: wrap; 
      width:100%;
      align-items: center;
      justify-content: center;
    }

    .docs-course-having {
      flex-direction: column; 
      justify-content: flex-start !important;
      background: rgba(255, 255, 255, .8) !important;
    }

    .docs-course-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 250px;
      height: 250px;
      background: rgba(255, 255, 255, .4);
      border-radius: 20px;
      margin: 5px;
      padding: 5px;
      line-height: 2;
      @include media-breakpoint-down(md) {
        width: 100%;
        border-radius: 5px;
        padding: 10px;
      }

      &__course {

        display: flex;
        flex-direction: column; 
        align-items: center;
        width: 100%;
        justify-content: center;

        .course_header_title {
          width: 100%;
          padding-top: 0px;
          padding-left: 8px;
          background: #fff;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2px;
        }

        .course_icon {
          display: flex;
          img {
            width: 150px;
            height: 150px;
          }
        }
        
      }

      .teacher {
        background: none !important;
        margin: 1px !important;
        .photo {
          flex: 0 1 30px;
          width: 30px !important;
          height: 30px !important;
          @include media-breakpoint-down(md) {
            width: 30px !important;
            height: 30px !important;
          }
        }
      }

      &__making {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 200px;
        height: 200px;
        border-radius: 50%;
        background: lightyellow;

        .making_title {
          display: flex;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          align-items: center;
          justify-content: center;
        }
        .making_status {
          display: flex;
          font-size: 18px;
          color: red;
          font-weight: bold;
          text-align: center;
          align-items: center;
          justify-content: center;
        }
      }

      &__empty_number {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 200px;
        height: 200px;
        border-radius: 50%;
        background: #fff;

        .empty_number {
          display: flex;
          font-size: 50px;
          align-items: center;
          justify-content: center;
        }
      }
    }


    .docs-workers-container {
      display:flex; 
      flex-direction:row; 
      flex-wrap: wrap; 
      width:100%;
      align-items: center;
      justify-content: center;
    }

    .docs-worker-having {
      flex-direction: column; 
      justify-content: flex-start !important;
      background: rgba(255, 255, 255, .8) !important;
    }

    .docs-worker-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 250px;
      height: 250px;
      background: rgba(255, 255, 255, .4);
      border-radius: 20px;
      margin: 5px;
      padding: 5px;
      line-height: 2;
      @include media-breakpoint-down(md) {
        width: 100%;
        border-radius: 5px;
        padding: 10px;
      }

      &__header {
        display: flex;
        flex-direction: row; 
        width: 100%;
        padding-top: 0px;
        padding-left: 8px;
        background: #fff;
        border-radius: 50px;

        .worker_header_number {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          .worker_number {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 8px;
            padding: 3px;
            font-size: 18px;
          }
        }

        .worker_header_title {
          display: flex;
          align-items: center;
          padding: 2px;
        }
      }

      &__teacher {

        display: flex;
        flex-direction: column; 
        padding: 5px;

        .teacher_icon {
          display: flex;
          img {
            width: 150px;
            height: 150px;
          }
        }

        .teacher_name {
          display: flex;

        }
      }

      &__empty_number {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 200px;
        height: 200px;
        border-radius: 50%;
        background: #fff;

        .empty_number {
          display: flex;
          font-size: 50px;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .docs-section-wrapper {
      background: rgba(255, 255, 255, .8);
      border-radius: 20px;
      padding: 20px;

      line-height: 2;

      @include media-breakpoint-down(md) {
        border-radius: 5px;
        padding: 10px;
      }
    }

    .docs-section {
      background: rgba(255, 255, 255, .8);
      margin-bottom: 1em;
      padding: 20px;
      border-radius: 10px;

      @include media-breakpoint-down(md) {
        font-size: 1.4rem;
        padding: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }


      .sub-ttl {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 1em;
        @include media-breakpoint-down(md) {
          font-size: 1.6rem;
        }
      }

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }

      .youtube {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        overflow: hidden;
        margin-bottom: 50px;

        iframe {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }


      .caution {
        border: solid 1px #f00;
        border-radius: 10px;
        padding: 20px;
        text-align: center;
      }

    }

  }
}

#page-mypage {

  // for profile page
  .mycard {
    position: relative;
    max-width: 840px;
    //height: 500px;
    margin: 0 auto 30px;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    .mine {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 12% 74% 0 9%;
      img {
        width: 100%;
      }
    }

    .name {
      position: absolute;
      z-index: 2;
      top: 340px;
      left: 0;
      width: 300px;
      text-align: center;
      @include media-breakpoint-down(sm) {
        top: 68%;
        width: 38%;
        line-height: 1.2;
      }
      &__sub {
        font-size: 1.2rem;
        margin-bottom: 3px;
        @include media-breakpoint-down(sm) {
          @include fz_vw(8);
        }
      }
      &__main {
        font-size: 1.8rem;
        font-weight: bold;
        @include media-breakpoint-down(sm) {
          @include fz_vw(14);
        }
      }
    }

    .friend-status {
      position: absolute;
      z-index: 2;
      top: 390px;
      left: 0;
      width: 300px;
      text-align: center;
      font-size: 14px;
      @include media-breakpoint-down(sm) {
        top: 85%;
        width: 38%;
      }
      .balloon1-top {
        font-size: 14px;
        margin-bottom: 10px;
        padding: 0 10px;
        @include media-breakpoint-down(sm) {
          top: 80%;
          width: 38%;
        }
      }
      &__balloon {
        display: none;
        position: absolute;
        top: -110px;
        margin: 1.5em 0 0 0;
        padding: 7px 10px;
        min-width: 120px;
        max-width: 100%;
        color: #555;
        font-size: 16px;
        background: #fff;
        &:before {
          content: "";
          position: absolute;
          bottom: -30px;
          left: 50%;
          margin-left: -15px;
          border: 15px solid transparent;
          border-top: 15px solid #fff;
        }
        p {
          margin: 0;
          padding: 0;
        }
        &.show {
          display: block;
        }
      }
    }

    .island-name {
      position: absolute;
      z-index: 1;
      top: 100px;
      right: 0;
      text-align: center;
      width: 540px;
      font-size: 30px;
      font-weight: bold;
      @include media-breakpoint-down(sm) {
        width: 60%;
        font-size: 12px;
        top: 18%;
      }
    }

    #map {
      position: absolute;
      top: 100px;
      bottom: 0;
      margin: auto;
      width: 100%;
      //width: 448px;
      //height: 280px;
      @include media-breakpoint-down(sm) {
        //bottom: 0;
        //right: 0;
        //margin: auto;
        //width: 192px;
        //height: 120px;
        transform: scale(0.5) translate(0,0);
      }
      .mymap {
        //background: #000;
        margin: 0 0 0 auto;
        //transform: scale(0.3);
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        //width: 60vw;
        width: 100%;

        &__inner {
          position: absolute;
          width: 320px;
          right: 10%;
          top: 0;
          @media screen and (max-width: 640px) {
            //width: 70%;
          }
          @include media-breakpoint-down(sm) {
            left: 35vw;
          }
        }

        .island {
          //width: 20vw;
          width: 128px;
          height: 128px;
          @include media-breakpoint-down(xl) {
            width: 64px;
            height: 64px;
          }

          img {
            width: 128px;
            max-width: 100%;
            @include media-breakpoint-down(sm) {
              width: 64px;
            }
          }
        }

        .pos1x1 {
          left: 0;
          bottom: 156px;
        }
        .pos1x2 {
          left: (32*1)+px;
          bottom: 156+16px;
        }
        .pos1x3 {
          left: (32*2)+px;
          bottom: 156+(16*2)+px;
        }
        .pos1x4 {
          left: (32*3)+px;
          bottom: 156+(16*3)+px;
        }
        .pos1x5 {
          left: (32*4)+px;
          bottom: 156+(16*4)+px;
        }
        .pos2x1 {
          left: 32px;
          bottom: 124+16+px;
        }
        .pos2x2 {
          left: (32*2)+px;
          bottom: 124+(16*2)+px;
        }
        .pos2x3 {
          left: (32*3)+px;
          bottom: 124+(16*3)+px;
        }
        .pos2x4 {
          left: (32*4)+px;
          bottom: 124+(16*4)+px;
        }
        .pos2x5 {
          left: (32*5)+px;
          bottom: 124+(16*5)+px;
        }
        .pos3x1 {
          left: (32*2)+px;
          bottom: 124px;
        }
        .pos3x2 {
          left: (32*3)+px;
          bottom: 124+16+px;
        }
        .pos3x3 {
          left: (32*4)+px;
          bottom: 124+(16*2)+px;
        }
        .pos3x4 {
          left: (32*5)+px;
          bottom: 124+(16*3)+px;
        }
        .pos3x5 {
          left: (32*6)+px;
          bottom: 124+(16*4)+px;
        }
        .pos4x1 {
          left: (32*3)+px;
          bottom: 108px;
        }
        .pos4x2 {
          left: (32*4)+px;
          bottom: 108+16+px;
        }
        .pos4x3 {
          left: (32*5)+px;
          bottom: 108+(16*2)+px;
        }
        .pos4x4 {
          left: (32*6)+px;
          bottom: 108+(16*3)+px;
        }
        .pos4x5 {
          left: (32*7)+px;
          bottom: 108+(16*4)+px;
        }
        .pos5x1 {
          left: (32*4)+px;
          bottom: 92px;
        }
        .pos5x2 {
          left: (32*5)+px;
          bottom: 92+(16*1)+px;
        }
        .pos5x3 {
          left: (32*6)+px;
          bottom: 92+(16*2)+px;
        }
        .pos5x4 {
          left: (32*7)+px;
          bottom: 92+(16*3)+px;
        }
        .pos5x5 {
          left: (32*8)+px;
          bottom: 92+(16*4)+px;
        }


      }
    }
  }

  #map .mymap {
    width: 640px;
    margin: auto;
    position: relative;;
    z-index: 5;
    @media screen and (max-width: 640px) {
      width: 100%;
    }
    &__inner {
      position: relative;
      height: 400px;
      @media screen and (max-width: 640px) {
        width: 100%;
        height: 60vw;
      }
    }

    .island {
      position: absolute;
      width: 128px;
      height: 128px;
      transition: all 0.2s;
      @media screen and (max-width: 640px) {
        width: 20vw;
      }

      img {
        position: absolute;
        bottom: 0;
        width: 128px;
        @media screen and (max-width: 640px) {
          width: 100%;
        }
      }
    }
  }


  #map .mymap .pos1x5 {
    left: 320-64px;
    bottom: 256+28px;
    z-index: 99;
    @media screen and (max-width: 640px) {
      left: 40vw;
      bottom: 40vw;
    }
  }

  #map .mymap .pos1x4 {
    left: 256-64px;
    bottom: 224+28px;
    z-index: 100;
    @media screen and (max-width: 640px) {
      left: 30vw;
      bottom: 35vw;
    }
  }

  #map .mymap .pos2x5 {
    left: 384-64px;
    bottom: 224+28px;
    z-index: 101;
    @media screen and (max-width: 640px) {
      left: 50vw;
      bottom: 30vw;
    }
  }

  #map .mymap .pos1x3 {
    left: 192-64px;
    bottom: 192+28px;
    z-index: 102;
    @media screen and (max-width: 640px) {
      left: 20vw;
      bottom: 30vw;
    }
  }

  #map .mymap .pos2x4 {
    left: 320-64px;
    bottom: 192+28px;
    z-index: 103;
    @media screen and (max-width: 640px) {
      left: 40vw;
      bottom: 30vw;
    }
  }

  #map .mymap .pos3x5 {
    left: 448-64px;
    bottom: 192+28px;
    z-index: 104;
    @media screen and (max-width: 640px) {
      left: 60vw;
      bottom: 30vw;
    }
  }

  #map .mymap .pos1x2 {
    left: 128-64px;
    bottom: 160+28px;
    z-index: 105;
    @media screen and (max-width: 640px) {
      left: 10vw;
      bottom: 25vw;
    }
  }

  #map .mymap .pos2x3 {
    left: 256-64px;
    bottom: 160+28px;
    z-index: 106;
    @media screen and (max-width: 640px) {
      left: 30vw;
      bottom: 25vw;
    }
  }

  #map .mymap .pos3x4 {
    left: 384-64px;
    bottom: 160+28px;
    z-index: 107;
    @media screen and (max-width: 640px) {
      left: 50vw;
      bottom: 25vw;
    }
  }

  #map .mymap .pos4x5 {
    left: 512-64px;
    bottom: 160+28px;
    z-index: 108;
    @media screen and (max-width: 640px) {
      left: 70vw;
      bottom: 25vw;
    }
  }

  #map .mymap .pos1x1 {
    left: 64-64px;
    bottom: 128+28px;
    z-index: 109;
    //background: rgba(255,28,75,.2);
    @media screen and (max-width: 640px) {
      left: 0;
      bottom: 20vw;
    }
  }
  #map .mymap .pos2x2 {
    left: 192-64px;
    bottom: 128+28px;
    z-index: 110;
    @media screen and (max-width: 640px) {
      left: 20vw;
      bottom: 20vw;
    }
  }
  #map .mymap .pos3x3 {
    left: 320-64px;
    bottom: 128+28px;
    z-index: 111;
    @media screen and (max-width: 640px) {
      left: 40vw;
      bottom: 20vw;
    }
  }
  #map .mymap .pos4x4 {
    left: 448-64px;
    bottom: 128+28px;
    z-index: 112;
    @media screen and (max-width: 640px) {
      left: 60vw;
      bottom: 20vw;
    }
  }
  #map .mymap .pos5x5 {
    left: 576-64px;
    bottom: 128+28px;
    z-index: 113;
    @media screen and (max-width: 640px) {
      left: 80vw;
      bottom: 20vw;
    }
  }

  #map .mymap .pos2x1 {
    left: 128-64px;
    bottom: 96+28px;
    z-index: 114;
    @media screen and (max-width: 640px) {
      left: 10vw;
      bottom: 15vw;
    }
  }
  #map .mymap .pos3x2 {
    left: 256-64px;
    bottom: 96+28px;
    z-index: 115;
    @media screen and (max-width: 640px) {
      left: 30vw;
      bottom: 15vw;
    }
  }
  #map .mymap .pos4x3 {
    left: 384-64px;
    bottom: 96+28px;
    z-index: 116;
    @media screen and (max-width: 640px) {
      left: 50vw;
      bottom: 15vw;
    }
  }
  #map .mymap .pos5x4 {
    left: 512-64px;
    bottom: 96+28px;
    z-index: 117;
    @media screen and (max-width: 640px) {
      left: 70vw;
      bottom: 15vw;
    }
  }

  #map .mymap .pos3x1 {
    left: 192-64px;
    bottom: 64+28px;
    z-index: 118;
    @media screen and (max-width: 640px) {
      left: 20vw;
      bottom: 10vw;
    }
  }
  #map .mymap .pos4x2 {
    left: 320-64px;
    bottom: 64+28px;
    z-index: 119;
    @media screen and (max-width: 640px) {
      left: 40vw;
      bottom: 10vw;
    }
  }
  #map .mymap .pos5x3 {
    left: 448-64px;
    bottom: 64+28px;
    z-index: 120;
    @media screen and (max-width: 640px) {
      left: 60vw;
      bottom: 10vw;
    }
  }

  #map .mymap .pos4x1 {
    left: 256-64px;
    bottom: 32+28px;
    z-index: 121;
    @media screen and (max-width: 640px) {
      left: 30vw;
      bottom: 5vw;
    }
  }

  #map .mymap .pos5x2 {
    left: 384-64px;
    bottom: 32+28px;
    z-index: 122;
    @media screen and (max-width: 640px) {
      left: 50vw;
      bottom: 5vw;
    }
  }
  #map .mymap .pos5x1 {
    left: 320-64px;
    bottom: 0+28px;
    z-index: 123;
    @media screen and (max-width: 640px) {
      left: 40vw;
      bottom: 0;
    }
  }









  #map .mymap .x1 {
    left: 0px; }

  #map .mymap .x2 {
    left: 64px; }
  #map .mymap .x3 {
    left: 128px; }
  #map .mymap .x4 {
    left: 192px; }
  #map .mymap .x5 {
    left: 256px; }
  #map .mymap .x6 {
    left: 320px; }
  #map .mymap .x7 {
    left: 384px; }
  #map .mymap .x8 {
    left: 448px; }
  #map .mymap .x9 {
    left: 512px; }
  #map .mymap .x10 {
    left: 576px; }
  #map .mymap .x11 {
    left: 640px; }
  #map .mymap .x12 {
    left: 704px; }
  #map .mymap .x13 {
    left: 768px; }
  #map .mymap .x14 {
    left: 832px; }
  #map .mymap .y1 {
    bottom: 0px; }
  #map .mymap .y2 {
    bottom: 32px; }
  #map .mymap .y3 {
    bottom: 64px; }
  #map .mymap .y4 {
    bottom: 96px; }
  #map .mymap .y5 {
    bottom: 128px; }
  #map .mymap .y6 {
    bottom: 160px; }
  #map .mymap .y7 {
    bottom: 192px; }
  #map .mymap .y8 {
    bottom: 224px; }
  #map .mymap .y9 {
    bottom: 256px; }
  #map .mymap .y10 {
    bottom: 288px; }
  #map .mymap .y11 {
    bottom: 320px; }
  #map .mymap .y12 {
    bottom: 352px; }
  #map .mymap .y13 {
    bottom: 384px; }
  #map .mymap .y14 {
    bottom: 416px; }

  $islandSize: 64;
  $islandBottom: 32;

  .diamond {
    display: block;
    width: 64px;
    height: 64px;
    background: gray;
    transform: skew(45deg, 45deg);
  }
  #map {
    .mymap {
      .pos1x1,
      .pos2x2,
      .pos3x3,
      .pos4x4,
      .pos5x5
      {
        //border: solid 1px #000;
        //display: block !important;
      }
      .island {
        cursor: pointer;
        //background: rgba(0,0,0,.5);
        //border: solid 1px #000;
        //display: none;
        //transform: skew(45deg,-45deg);
        //transform: rotate(45deg);
        img {
          //transform: rotate(-45deg);
          //transform: skew(-45deg,45deg) scale(.5) translate(10px, 47px);
        }
      }
    }
  }

  .mymap {
    width: 840px;
    margin-bottom: 3rem;
    &__inner {
      position: relative;
      height: 400px;
      @media screen and (max-width: 640px) {
        width: 100vw;
        margin: 0 -10px;
      }
    }
    &__grid-front {
      position: absolute;
      top: -2px;
      left: 0;
      z-index: 200;
      max-width: 640px;
      @media screen and (max-width: 640px) {
        max-width: 100vw;
        width: 100vw;
        top: auto;
        bottom: 3.5vw;
      }
      > img {
        max-width: 100%;
        @media screen and (max-width: 640px) {
          width: 100%;
        }
      }


      // svg設定start
      .cls-1{fill:#fff;fill-opacity:0.5;}
      .cls-2{fill:#fff;fill-opacity:0.5;}
      .cls-2,
      .cls-3{fill-rule:evenodd;}
      .cls-3{fill:#e4712d;}

      .clickable {
        fill: #fff;
        fill-opacity: 0;
        transform: fill-opacity .5s;
        &:hover {
          fill: #e4712d;
          fill-opacity: .5;
        }
      }

      .a {
        stroke-width: 0;
        opacity: 0.38;
        cursor: pointer;
      }
      svg {
        max-width: 100%;
        @media screen and (max-width: 640px) {
          width: 100%;
          height: auto;
        }
      }
      // svg設定end

    }
    &__grid-back {
      position: absolute;
      top: 32px;
      left: 0;
      z-index: 1;
      max-width: 640px;
      @media screen and (max-width: 640px) {
        max-width: 100vw;
        width: 100vw;
        top: auto;
        bottom: 0;
      }
      > img {
        max-width: 100%;
        @media screen and (max-width: 640px) {
          width: 100%;
        }
      }
    }
    &__clickarea {
      position: absolute;
      top: 400px;
      z-index: 201;
      .island {
        border: solid 1px #000;
      }
    }
    .island {
      display: block;
      position: absolute;
      width: $islandSize*2+px;
      height: $islandSize*2+px;
      img {
        position: absolute;
        bottom: 0;
        width: $islandSize*2+px;
      }
      transition: all 0.2s;
    }

    .x1 {
      left: $islandSize*0+px;
    }
    .x2 {
      left: $islandSize*1+px;
    }
    .x3 {
      left: $islandSize*2+px;
    }
    .x4 {
      left: $islandSize*3+px;
    }
    .x5 {
      left: $islandSize*4+px;
    }
    .x6 {
      left: $islandSize*5+px;
    }
    .x7 {
      left: $islandSize*6+px;
    }
    .x8 {
      left: $islandSize*7+px;
    }
    .x9 {
      left: $islandSize*8+px;
    }
    .x10 {
      left: $islandSize*9+px;
    }
    .x11 {
      left: $islandSize*10+px;
    }
    .x12 {
      left: $islandSize*11+px;
    }
    .x13 {
      left: $islandSize*12+px;
    }
    .x14 {
      left: $islandSize*13+px;
    }

    .y1 {
      bottom: $islandBottom*0+px;
    }
    .y2 {
      bottom: $islandBottom*1+px;
    }
    .y3 {
      bottom: $islandBottom*2+px;
    }
    .y4 {
      bottom: $islandBottom*3+px;
    }
    .y5 {
      bottom: $islandBottom*4+px;
    }
    .y6 {
      bottom: $islandBottom*5+px;
    }
    .y7 {
      bottom: $islandBottom*6+px;
    }
    .y8 {
      bottom: $islandBottom*7+px;
    }
    .y9 {
      bottom: $islandBottom*8+px;
    }
    .y10 {
      bottom: $islandBottom*9+px;
    }
    .y11 {
      bottom: $islandBottom*10+px;
    }
    .y12 {
      bottom: $islandBottom*11+px;
    }
    .y13 {
      bottom: $islandBottom*12+px;
    }
    .y14 {
      bottom: $islandBottom*13+px;
    }
  }

  .my-course-wrapper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .my-course {
    position: relative;
    border-radius: 10px;
    border: solid 10px #fff;
    margin-bottom: 30px;
    background: #fff;
    width: 340px;
    height: 420px;
    &__title-wrap {
      margin: 0 5px;
    }
    &__title-inner {
      padding: 5px;
      min-height: 64px;
    }
    &__title {
      font-size: 1.2rem;
      .title-tip {
        span {
          background: transparent;
          border: solid 1px #333;
          color: #333;
        }
      }
    }
    &__meta-count {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__body {
      padding-top: 40px;
    }
    &__difficulty {
      transform: scale(2);
      text-align: center;
    }
    &__buttons {
      position: absolute;
      bottom: 20px;
      left: 0;
      text-align: center;
      width: 100%;
    }
    &__homework {
      position: absolute;
      bottom: 20px;
      left: 20px;
    }
    &__homework-message {
      background: #ff1c4b;
      color: #fff;
      border-radius: 3px;
      padding: 3px;
      border: solid 3px #fff;
      font-size: 12px;
      transform: rotate(-10deg);
    }
    &__homework-icon {
      margin-left: 10px;
    }

    &.no-challenge {
      .my-course__title {
        font-size: 1.2rem;
        text-align: center;
      }
    }

    .progress-wrapper {
      margin-top: 40px;
      background: rgba(255,255,255,.5);
      padding: 20px;
      border-radius: 10px;
      .progress-bar {
        position: relative;
      }
      .progress-icon {
        position: absolute;
        top: 0;
        left: 0;
      }
      .progress {
        margin: 0 20px;
        box-shadow: 0 5px 0 #80001C;
      }
    }

  }

  .challenge-box {
    margin-bottom: 1em;
    position: relative;
    &__header {
      background: #fff;
      color: #000;
      font-weight: bold;
      text-align: center;
      padding: 10px;
      font-size: 1.4rem;
      border-radius: 20px;
      margin-bottom: 1rem;
    }
  }



  // ----------------------------------------------------------------------------------------------------
  // page friend in mypage
  // ----------------------------------------------------------------------------------------------------
  &.page-friend {

    .search {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: solid 3px #F56900;
      border-radius: 20px;
      padding: 10px;
      background: #fff;
      margin: 0;
      list-style: none;
      > li {
        margin-right: 10px;
      }
    }

  }

  // ----------------------------------------------------------------------------------------------------
  // page mypage-iconmaker in mypage
  // ----------------------------------------------------------------------------------------------------
  &.page-mypage-iconmaker {

    .icon-maker {
      @include media-breakpoint-down(sm) {
        margin-bottom: 5em;
      }
      &__tab {
        .tab-list {
          display: flex;
          margin: 0 0 0 10%;
          padding: 0;
          list-style: none;
          @include media-breakpoint-down(sm) {
            margin: 0 0 0 5%;
          }
          li {
            margin-right: 5px;
            @include media-breakpoint-down(sm) {
              margin-right: 1px;
            }
            button {
              cursor: pointer;
              outline: none;
              appearance: none;
              display: block;
              border-radius: 20px 20px 0 0;
              padding: 5px 20px 15px;
              border: solid 10px #234e63;
              border-width: 10px 10px 0 10px;
              background: #234e63;
              color: #fff;
              line-height: 1;
              @include media-breakpoint-down(sm) {
                padding: 5px 5px 15px;
              }
              img {
                width: 16px;
                @include media-breakpoint-down(sm) {
                  width: 30px;
                }
              }
              span {
                @include media-breakpoint-down(sm) {
                  display: none;
                }
              }
              &.selected {
                border-color:#fff;
                background: #fff;
                color: #000;
              }
            }
          }
        }
      }

      &__changer {
        position: relative;
        //top: -15px;
        text-align: center;
        border-radius: 20px;
        background: #097293;
        margin-bottom: 1em;
        border: solid 10px #fff;

        display: flex;
        @include media-breakpoint-down(md) {
        }
      }
      &__preview {
        position: relative;
        width: 311px;
        height: 410px;
        background: url("/statics/images/mypage/edit_bg.svg") no-repeat right center;
        background-size: 105%;
        border-radius: 10px 0 0 10px;
        @include media-breakpoint-down(sm) {
          border-radius: 10px 10px 0 0;
          width: 150px;
          height: 200px;
        }
        img {
          position: absolute;
          top: 13%;
          left: 20px;
          @include media-breakpoint-down(sm) {
            top: 28%;
            width: 50%;
          }
        }
      }

      .btn-submit {
        position: absolute;
        bottom: -30px;
        left: 30px;
        //width: 311px;
        text-align: center;
        @include media-breakpoint-down(sm) {
          z-index: 10;
          bottom: -30px;
          left: 4%;
          width: 100%;
        }
      }

      .primary {
        position: relative;
      }

      .secondary {
        width: 100%;
        .dress-finish {
          flex: 0 1 100%;
          color: #fff;
          padding: 40px;
        }
      }

      .dress {
        position: relative;
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
        }
        a {
          display: block;
        }

        &__inner {
          height: 410px;
          overflow-y: scroll;
          background: #097293;
          @include media-breakpoint-down(sm) {
            height: 200px;
            //min-width: 200px;
          }
        }



        .head-list,
        .glasses-list,
        .body-list,
        .legs-list,
        .item-list {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          @include media-breakpoint-down(sm) {
            display: block;
          }
        }

        .head-list,
        .glasses-list,
        .body-list,
        .legs-list,
        .item-list  {
          margin-left: 11%;
          margin-bottom: 80px;
          @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
          }
          li {
            position: relative;
            overflow: hidden;
            img {
              position: relative;
            }
            a {
              cursor: pointer;
              &:hover {
                background: rgba(255,255,255,.2);
              }
            }
          }
        }

        .head-list {
          li {
            height: 160px;
            @include media-breakpoint-down(sm) {
              height: 14vh;
            }
            img {
              @include media-breakpoint-down(sm) {
                top: -1vh;
              }
            }
          }
        }

        .glasses-list {
          li {
            position: relative;
            height: 100px;
            @include media-breakpoint-down(sm) {
              height: 8vh;
            }
            &:first-child {
              &::after {
                position: absolute;
                z-index: 1;
                top: 30%;
                left: 0;
                content: 'なし';
                width: 100%;
                text-align: center;
                color: #fff;
                font-size: 2rem;
              }
              a {
                position: relative;
                z-index: 2;
              }
            }
            img {
              top: -50px;
              @include media-breakpoint-down(sm) {
                top: -6vh;
              }
            }
          }
        }
        .body-list {
          li {
            height: 160px;
            @include media-breakpoint-down(sm) {
              height: 12vh;
            }
            img {
              top: -100px;
              @include media-breakpoint-down(sm) {
                top: -10vh;
              }
            }
          }
        }
        .legs-list {
          li {
            height: 160px;
            @include media-breakpoint-down(sm) {
              height: 13vh;
            }
            img {
              top: -160px;
              @include media-breakpoint-down(sm) {
                top: -17vh;
              }
            }
          }
        }
        .item-list {
          justify-content: flex-start;
          margin: 0;
          > li {
            flex: 0 1 23%;
            margin: 1%;
            border: solid 1px #fff;
            border-radius: 10px;
            background: rgba(255,255,255,.2);
            @include media-breakpoint-down(md) {
              flex: 0 1 100%;
            }
          }
          .item-header {
            background: #fff;
            padding: 4px;
            text-align: center;
            font-size: 1.4rem;
            .item-student-name {
              display: block;
            }
            .item-student-id {
              display: inline-block;
              border-radius: 20px;
              background: #eee;
              padding: 2px 10px;
              font-size: 1.1rem;
              font-weight: bold;
              color: #333;
            }
          }
          .item-image {
            text-align: center;
            margin-bottom: 10px;
          }
          .item-status {
            display: flex;
            justify-content: space-between;
            font-size: 1.4rem;
            margin-bottom: 10px;
          }
          .item-student-message {
            display: inline-block;
            border-radius: 20px;
            background: #fff;
            padding: 2px 10px;
            margin: 0 10px 10px;
            font-size: 1.4rem;
            color: #333;
          }
          .item-price,
          .item-count {
            padding: 3px;
            text-align: center;
            > span {
              display: inline-block;
              background: #FDF873;
              border-radius: 20px;
              padding: 4px;
              line-height:  1;
              margin-top: 3px;
              font-size: 1.1rem;
              font-weight: bold;
            }
          }
          .item-count {
            > span {
              background: #fff;
            }
          }
          .item-more {
            padding: 0 10px;
            margin-bottom: 10px;
            text-align: center;
          }
          .item-remove {
            padding: 0 10px;
            margin-bottom: 10px;
            text-align: center;
          }
          .item-btn-list {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: space-between;
            > li {
              flex: 0 1 50%;
            }
          }
        }

      }
    }


    .modal-dialog {
      .modal-content {
        //background: #3D8FA8;
      }
      .item-list {
        justify-content: flex-start;
        background: #3D8FA8;
        border: solid 3px;
        margin-bottom: 10px;
        padding: 10px;
        .item-header {
          background: #fff;
          padding: 4px;
          text-align: center;
          font-size: 1.4rem;
        }
        .item-image {
          text-align: center;
          margin-bottom: 10px;
        }
      }
      .item-status {
        display: flex;
        justify-content: center;
        font-size: 1.4rem;
        margin-bottom: 10px;
        .item-price,
        .item-count {
          padding: 3px;
          text-align: center;
          > span {
            display: inline-block;
            background: #FDF873;
            border-radius: 20px;
            padding: 4px;
            line-height:  1;
            margin-top: 3px;
            font-size: 1.1rem;
            font-weight: bold;
          }
        }
        .item-count {
          > span {
            background: #eee;
          }
        }
      }
      .btn-list {
        display: flex;
        justify-content: space-between;
        > div {
          flex: 0 1 49%;
        }
      }
    }

  }

  // ----------------------------------------------------------------------------------------------------
  // mypage-cards
  // ----------------------------------------------------------------------------------------------------
  &.page-mypage-cards {


    .for-print {
      display: none;
    }

    .sample {
      margin-bottom: 20px;
    }
    .print-cards-list {
      display: flex;
      flex-flow: row wrap;
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        width: 50%;
        background: #fff;
        display: flex;
        align-items: flex-end;
      }
      &.type-view {
        li {
          border: solid 1px #ccc;
          margin: auto;
          position: relative;
          overflow: hidden;
          @include media-breakpoint-down(sm) {
            width: 100%;
          }

          &::after {
            content: 'SAMPLE';
            position: absolute;
            top: 20px;
            right: -70px;
            transform: rotate(45deg);
            background: #1C415D;
            color: #fff;
            font-size: 1.4rem;
            width: 200px;
            text-align: center;
          }
        }
      }
      &.type-print{
        justify-content: center;
        border-bottom: dashed 1px #ccc;
        li {
          border-top: dashed 1px #ccc;
          border-left: dashed 1px #ccc;
          border-right: dashed 1px #ccc;
        }
      }

      .primary {
        flex: 0 1 50%;
        padding: 10px 5px 10px 10px;
      }
      .secondary {
        flex: 0 1 50%;
        padding: 10px 10px 10px 5px;
        text-align: center;
      }

      .logo {
        max-width: 150px;
        margin: auto;
      }

      .nickname {
        margin: 0 0 10px;
        padding: 0;
        dt {
          font-size: 1.2rem;
          font-weight: normal;
          margin: 0;
          padding: 0;
        }
        dd {
          font-size: 1.6rem;
          font-weight: bold;
          color: #EA5713;
          margin: 0;
          padding: 0;
        }
      }

      .oyakoyaid {
        margin: 0;
        padding: 0;
        dt {
          font-size: 1.2rem;
          font-weight: normal;
          margin: 0;
          padding: 0;
        }
        dd {
          font-size: 1.4rem;
          font-weight: bold;
          margin: 0;
          padding: 0;
          color: #EA5713;
        }
      }
    }
    
    .button {
      text-align: center;
    }

  }

  // ----------------------------------------------------------------------------------------------------
  // island
  // ----------------------------------------------------------------------------------------------------
  &.page-island {
    
    .mymap-controller {
      position: absolute;
      z-index: 201;
      bottom: -50px;
      right: -100px;

      @include media-breakpoint-down(md) {
        position: relative;
        right: 0;
        bottom: 0;
      }

      .button {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
        padding-right: 50px;

        @include media-breakpoint-down(md) {
          justify-content: center;
          margin-top: 20px;
          padding-right: 0;
        }
      }
    }

    .mymap-message {
      margin-top: 50px;
      background: #fff;
      padding: 20px;
      border-radius: 60px;
      text-align: center;
      @include media-breakpoint-down(md) {
        margin-top: 0;
      }
    }

  }
}





@media print{

  #page-mypage.page-mypage-cards  {

    .gheader,
    .gfooter,
    .header-wave,
    .side-content,
    .print-cards-list.type-view,
    .button,
    .mod_ttl_friend-list,
    .mod_news,
    .sample {
      display: none;
    }
    .for-print {
      display: block !important;
    }

    .content-wrapper {
      background: none;
      padding-bottom: 0;
    }
    .content-wrapper__inner {
      width: 100%;
    }
    .main-content {
      margin: 0;
      width: 190mm;
    }

    .print-cards-list {

      li {
        width: 91mm;
        height: 55mm;
        overflow: hidden;
      }

      .logo {
        max-width: 140px;
        margin: auto;
      }
      //.nickname {
      //  margin-bottom: 5px;
      //  dt {
      //    font-size: 1.2rem;
      //  }
      //  dd {
      //    font-size: 1rem;
      //  }
      //}
      //
      //.oyakoyaid {
      //  dt {
      //    font-size: 1.2rem;
      //  }
      //  dd {
      //    font-size: 1rem;
      //  }
      //}
    }

  }
}
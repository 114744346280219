
.markdown-preview-wrapper {
  border: solid 1px #999;
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  height: 500px;
  overflow: scroll;
  .markdown-preview {
    //overflow: scroll;
    word-break: break-all;
    //white-space: pre-wrap;
  }
}

.markdown-textarea {
  min-height: 20em;
}

#page-course-article .article__body {
  &.markdown-preview {
    background: #F9F0EB;
  }
}


// マークダウンではないが、textareaの改行を生かしたい場合
.preformatted-text-preview {
  white-space: pre-wrap;
  word-break: break-all;
}

.markdown-news-preview {
  line-height: 2;

  p {
    margin-bottom: 1rem;
  }

  h1 {
    color: #fff;
    margin-bottom: 1.5rem;
    //padding: 5px 5px 5px 60px;
    padding: 5px;
    border-radius: 10px;
    background: #ffa408;
    background-repeat: no-repeat;
    background-position: 15px center;
    background-size: 30px auto;
    @include media-breakpoint-down(md) {
      font-size: 2.4rem;
      margin-bottom: 1rem;
      padding: 5px;
      background-position: 7px center;
      background-size: 18px auto;
    }
  }
  &.difficulty-very_easy h1 {
    background-image: url("/statics/images/difficulty/difficulty_very_easy.svg");
  }
  &.difficulty-easy h1 {
    background-image: url("/statics/images/difficulty/difficulty_easy.svg");
  }
  &.difficulty-normal h1 {
    background-image: url("/statics/images/difficulty/difficulty_normal.svg");
    background-size: 25px auto;
  }
  &.difficulty-hard h1 {
    background-image: url("/statics/images/difficulty/difficulty_hard.svg");
    background-size: 25px auto;
  }
  &.difficulty-very_hard h1 {
    background-image: url("/statics/images/difficulty/difficulty_very_hard.svg");
    background-size: 25px auto;
  }
  h2 {
    color: #333;
    padding: 5px 5px 5px 40px;
    margin-bottom: 1rem;
    background-image: url("/statics/images/icon/icon_h2.svg");
    background-repeat: no-repeat;
    background-position: 5px center;
    background-size: auto 48px;
    @include media-breakpoint-down(md) {
      font-size: 2.2rem;
      background-size: auto 36px;
    }
  }
  h3 {
    margin-bottom: 1rem;
    padding: 5px 5px 5px 60px;
    background-image: url("/statics/images/icon/icon_h3.svg");
    background-repeat: no-repeat;
    background-position: 5px center;
    background-size: auto 40px;
    @include media-breakpoint-down(md) {
      font-size: 1.8rem;
      background-size: auto 30px;
    }
  }

  img {
    max-width: 100%;
  }

  strong {
    font-weight: bold;
    display: inline;
    padding: 0 .4em 0;
    background: linear-gradient(rgba(255,255,255,0) 60%, rgb(255, 235, 0) 60%);
    @media print {
      background: linear-gradient(rgba(255, 250, 193, 0) 60%, rgb(255, 235, 0) 60%);
    }
  }
  /// only IE11 hack/
  _:lang(x)::-ms-backdrop, strong {
    background: linear-gradient(transparent 50%, rgb(255, 235, 0) 50%, rgb(255, 235, 0) 70%, transparent 70%);
  }
  /// only Edge hack/
  _:-ms-lang(x)::backdrop, strong {
    background: linear-gradient(transparent 60%, rgb(255, 235, 0) 60%, rgb(255, 235, 0) 80%, transparent 80%);
  }

  .hint {
    display: flex;
    margin-bottom: 1em;
    &__icon {
      margin-right: 10px;
      width: 50px;
      height: 50px;
      border-radius: 50px;
      overflow: hidden;
      background-color: #fff;
      background-image: url("/statics/images/icon/icon_teacher_default-s.svg");
      background-repeat: no-repeat;
      background-position: center 10px;
      background-size: cover;
    }
    &__message {
      white-space: pre-wrap;
      word-break: break-all;
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 10px;
      width: 100%;
      color: #555;
      font-weight: bold;
      background: #fff;
      box-shadow: 0 0 3px #ccc;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: -12px;
        margin-top: -6px;
        border: 6px solid transparent;
        border-right: 6px solid #fff;
      }
    }
  }


  .monster {
    display: flex;
    margin-bottom: 1em;
    &__icon {
      margin-right: 10px;
      width: 50px;
      height: 50px;
      background-image: url("/statics/images/icon/icon_monster.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
    &__message {
      white-space: pre-wrap;
      word-break: break-all;
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 10px;
      width: 100%;
      color: #555;
      font-weight: bold;
      background: #fdfdc9;
      box-shadow: 0 0 3px #FDF873;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: -12px;
        margin-top: -6px;
        border: 6px solid transparent;
        border-right: 6px solid #fdfdc9;
      }
    }
  }

  .parent {
    white-space: pre-wrap;
    word-break: break-all;
    padding: 40px 10px 10px 10px;
    background: #D5E3E8;
    border-radius: 10px;
    margin-bottom: 1em;
    position: relative;
    &:before {
      content: "保護者の方へ";
      position: absolute;
      top: 10px;
      left: 10px;
      color: #fff;
      background: #3294CA;
      padding: 5px 10px;
      font-size: 12px;
      line-height: 1;
    }
  }

  .answer {
    white-space: pre-wrap;
    word-break: break-all;
    padding: 1em;
    background: #ffb0d3;
    border-radius: 10px;
    margin-bottom: 1em;
  }

  hr {
    border-top: 3px dashed #999;
    margin-top: 3em;
    margin-bottom: 3em;
  }

  .example {
    white-space: pre-wrap;
    word-break: break-all;
    padding: 10px 10px 10px 90px;
    background: #fff;
    border: solid 5px #9d5827;
    border-radius: 10px;
    margin-bottom: 1em;
    position: relative;
    &:before {
      content: "れいだい";
      position: absolute;
      top: 10px;
      left: 10px;
      color: #fff;
      background: #3294CA;
      padding: 5px 10px;
      font-size: 12px;
      line-height: 1;
    }
    &:after {
      content: "";
      position: absolute;
      top: 40px;
      left: 10px;
      width: 60px;
      height: 60px;
      background-image: url("/statics/images/icon/icon_challenge.svg");
      background-repeat: no-repeat;
      background-position: 0 center;
      background-size: cover;
    }

    &__inner {
      width: 100%;
    }

    .answer {}
  }

  .formula {
    white-space: pre-wrap;
    word-break: break-all;
    padding: 10px 10px 10px 90px;
    background: #fff;
    border: solid 5px #9d5827;
    border-radius: 10px;
    margin-bottom: 1em;
    position: relative;

    display: flex;
    align-items: center;
    min-height: 12rem;
    &:before {
      content: "公式";
      position: absolute;
      top: 10px;
      left: 10px;
      color: #fff;
      background: #3294CA;
      padding: 5px 10px;
      font-size: 12px;
      line-height: 1;
    }
    &:after {
      content: "";
      position: absolute;
      top: 40px;
      left: 10px;
      width: 60px;
      height: 60px;
      background-image: url("/statics/images/icon/icon_book.svg");
      background-repeat: no-repeat;
      background-position: 0 center;
      background-size: cover;
    }

    &__inner {
      width: 100%;
    }
  }

  code {
    display: block;
    white-space: pre-wrap;
    word-break: break-all;
    background: #F7F7F9;
    color: #D72B40;
    //padding: 40px 10px 10px 10px;
    padding: 3px;
    border-radius: 3px;
    border: solid 1px #E1E1E8;
    //display: block;
    //position: relative;
    //&:before {
    //  content: "コード";
    //  position: absolute;
    //  top: 10px;
    //  left: 10px;
    //  color: #fff;
    //  background: #3294CA;
    //  padding: 5px 10px;
    //  font-size: 12px;
    //  line-height: 1;
    //}
  }

  .user-img {
    text-align: center;
    img {
      //width: 100%;
      max-width: 100%;
      border: solid 3px #fff;
      box-shadow: 0 0 3px rgba(0,0,0,.3);
    }
  }

  blockquote {
    border-left: solid 10px #9D5828;
    padding-left: 1rem;
  }


  .quiz {
    white-space: pre-wrap;
    word-break: break-all;
    padding: 10px 10px 10px 90px;
    background: #fff;
    border: solid 5px #9d5827;
    border-radius: 10px;
    margin-bottom: 1em;
    position: relative;
    min-height: 120px;
    &:before {
      content: "練習問題";
      position: absolute;
      top: 10px;
      left: 10px;
      color: #fff;
      background: #3294CA;
      padding: 5px 10px;
      font-size: 12px;
      line-height: 1;
    }
    &:after {
      content: "";
      position: absolute;
      top: 40px;
      left: 10px;
      width: 60px;
      height: 60px;
      background-image: url("/statics/images/icon/icon_challenge.svg");
      background-repeat: no-repeat;
      background-position: 0 center;
      background-size: cover;
    }

    &__inner {
      width: 100%;
    }

    .parent {
    }
  }

  .speech {
    cursor: pointer;
    position: relative;
    margin-bottom: 1rem;
    border: solid 1px #ccc;
    border-radius: 10px;
    padding: 10px 10px 50px 10px;
    background: #fff;
    overflow: hidden;
    &:hover {
      opacity: 0.8;
    }
  }

  .btn-voice {
    position: absolute;
    bottom: 10px;
    right: 0;
    color: #333;
    padding: 3px 10px;
    line-height: 1;
    font-size: 12px;
    background: #eee;
  }

  .table {
    width: 100%;
    margin-bottom: 15px;
    border-collapse: collapse;
  }
  .table th,
  .table td {
    padding: 12px;
    border: #ccc 1px solid;
  }
  .table th {
    background-color: #FBB48E;
    text-align: center;
  }

  .wiki-textarea {
    position: relative;
    &:before {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      content: 'この中をタップして入力してみよう';
      color: #333;
      padding: 3px 10px;
      line-height: 1;
      font-size: 12px;
      background: #eee;
      border-radius: 3px;
    }
    &:focus-within:before {
      animation: textareaFocusOn .5s forwards;
    }
    &__lead {
      display: none;
    }
    textarea {
      position: relative;
      border: solid 6px #FBB48E;
      border-radius: 3px;
      padding: 10px;
      font-size: 16px;
      color: #333;
      margin: 0 0 20px;
      width: 100%;
      height: 100px;
      outline: none;
      box-shadow: inset 1px 4px 9px -6px rgba(0,0,0,0.5);
      &:focus {
        border-color: #F26600;
      }
    }
  }
  
  .youtube {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin-bottom: 50px;

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

}
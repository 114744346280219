#page-inquiries {

    .inquiry-ttl {
      position: relative;
      background: url(/statics/images/students/regist_ttl.svg) no-repeat center 0;
      background-size: 100% auto;
      padding: 34px 50px 0 50px;
      min-height: 100px;
      margin-bottom: 30px;
      text-align: center;
      @include media-breakpoint-down(md) {
        padding: 0 10vw 10vw;
        min-height: auto;
      }
      .title {
        font-weight: bold;
        font-size: 2.4rem;
        margin: 0;
        @include media-breakpoint-down(md) {
          position: absolute;
          left: 5vw;
          bottom: 2vw;
          @include fz_vw(12);
        }
      }
    }

    .inquiry-article {
      margin-bottom: 4em;
    }

    .inquiry-sub-ttl {
      display: flex;
      align-items: center;
      margin-bottom: 1em;
      .icon {
        margin-right: 5px;
      }
      .title {
        position: relative;
        color: #fff;
        font-size: 2rem;
        font-weight: bold;
        background: rgba(0, 0, 0, .5);
        border-radius: 20px;
        width: 100%;
        padding: 10px 80px 10px 20px;
        margin: 0;
        @include media-breakpoint-down(md) {
          font-size: 1.6rem;
        }
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          right: 10px;
          display: block;
          width: 70px;
          height: 55px;
          background: url("/statics/images/icon/icon_ttl_bg.svg") no-repeat right bottom;
          background-size: 100%;
          @include media-breakpoint-down(md) {
            background-size: 80%;
          }
        }
      }
    }


    .inquiry-section-wrapper {
      background: rgba(255, 255, 255, .8);
      border-radius: 20px;
      padding: 20px;

      line-height: 2;

      @include media-breakpoint-down(md) {
        border-radius: 5px;
        padding: 10px;
      }
    }

    .inquiry-section {
      background: rgba(255, 255, 255, .8);
      margin-bottom: 1em;
      padding: 20px;
      border-radius: 10px;

      @include media-breakpoint-down(md) {
        font-size: 1.4rem;
        padding: 10px;
      }

      &:last-child {
        margin-bottom: 0;
      }


      .sub-ttl {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 1em;
        @include media-breakpoint-down(md) {
          font-size: 1.6rem;
        }
      }

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }

      .caution {
        border: solid 1px #f00;
        border-radius: 10px;
        padding: 20px;
        text-align: center;
      }

    }

    .form-content {
      margin-bottom: 5rem;

      label {
        font-size: 1.6rem;
        display: block;
        line-height: 1.8;
        color: #0a0a0a;
      }

      input[type=text], input[type=email] {
        font-size: 1.6rem;
        background: #fff;
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
      }

      select {
        font-size: 1.6rem;
        background: #fff;
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        border: 1px solid #cacaca;
        border-radius: 0;
      }

      textarea {
        font-size: 1.6rem;
        background: #fff;
        display: block;
        width: 100%;
        padding: 6px 12px;
        max-width: 100%;
        height: auto;
        border: 1px solid #cacaca;
        border-radius: 0;
      }
    }

  }

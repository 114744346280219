#page-course {

  .course {
    &__title {
    }
  }

  .progress-wrapper {
    position: relative;

    .progress-bar {
      position: relative;
    }
    .progress-icon {
      position: absolute;
      top: 0;
      left: 0;
    }

    .progress {
      margin: 0 20px;
      box-shadow: 0 5px 0 #80001C;
    }
  }

  .course {
    &__title-wrapper {
      margin-bottom: 20px;
      padding: 20px;
      border-radius: 20px;
      background: #fff;
      display: flex;
      @include media-breakpoint-down(sm) {
        display: block;
      }
    }
    &__thumbs {
      background: #fff;
      width: 200px;
      max-height: 140px;
      margin-right: 10px;
      flex: 0 1 200px;
      border-radius: 3px;
      border: solid 1px #eee;
      @include media-breakpoint-down(sm) {
        width: 100%;
        max-height: initial;
        margin-bottom: 10px;
      }
      img {
        width: 200px;
        height: 140px;
        object-fit: cover;
        @include media-breakpoint-down(sm) {
          width: 100%;
          height: auto;
        }
      }
    }
    &__title {
      flex: 0 1 100%;
      .title-tip {
        span {
          background: transparent;
          border: solid 1px #fff;
          color: #fff;
        }
      }
    }
    &__title-inner {
      //padding: 10px;
      //border-radius: 3px;
      margin-bottom: 10px;
      color: #000;
      .title-tip {
        > span {
          font-size: 2.4rem;
          line-height: 1;
          @include media-breakpoint-down(sm) {
            font-size: 1.6rem;
          }
        }
      }
      .h2 {
        font-weight: bold;
        margin-bottom: 20px;
        @include media-breakpoint-down(sm) {
          font-size: 2.8rem;
        }
      }
    }
    &__wrapper_gadgets {
      display: flex;
      width: 100%;
      flex-direction: row;
      @include media-breakpoint-down(sm) {
        display: block;
      }
      .course__social_links {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: auto;
        @include media-breakpoint-down(sm) {
          justify-content: center;
        }
        .social_item {
          padding: 3px;
          display: flex;
          width: 80px;
          align-items: center;
        }
      }
      .course__meta-count {
        display: flex;
        align-items: center;
        @include media-breakpoint-down(sm) {
          justify-content: center;
          margin-bottom: 20px;
        }
        .meta {
          margin: 0 10px;
          @include media-breakpoint-down(md) {
            img {
              display: block;
            }
          }
        }
      }
    }
    &__lead {
      position: relative;
      .ani-mon_1 {
        position: absolute;
        top: -20px;
        right: 0;
        animation: aniMon1 10s infinite;
      }
    }
    &__body {
      padding: 20px;
      border-radius: 10px;
      background: rgba(255,255,255,.8);
      margin-bottom: 1em;

      font-size: 2.4rem;

      .body-inner {
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0;
        }
      }

      .title-icon {
        text-align: center;
        margin-top: -10px;
        margin-bottom: 20px;
      }
      .summary {
        font-size: 3rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
        @include media-breakpoint-down(sm) {
          font-size: 2.4rem;
        }
      }

      .quest-list {
        display: flex;
        //justify-content: space-between;
        flex-flow: row wrap;
        margin: 0;
        padding: 0;
        list-style: none;
        @include media-breakpoint-down(sm) {
          display: block;
        }

        > li {
          flex: 0 1 24%;
          padding: 0;
          margin: 0 5px 20px;
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
          a {
            text-decoration: none;
            opacity: 1;
            &:hover {
              text-decoration: none;
              opacity: 0.8;
              .quest-item {
                opacity: .8;
              }
            }
          }
        }
      }

      .quest-item {
        position: relative;
        background: #FBB03B;
        border-radius: 20px;
        padding: 50px 10px 10px;
        width: 100%;
        height: 100%;
        font-size: 1.8rem;
        transition: opacity .2s;

        .quest-balloon {
          position: absolute;
          top: -3px;
          right: -3px;
          animation: bntPlay 1s linear infinite;
        }

        &.grayscale {
          background: #BABABA;
        }

        &__header {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 100%;
          box-sizing: border-box;
          padding: 10px;
        }
        &__footer {
          position: absolute;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          width: 100%;
          box-sizing: border-box;
          padding: 10px;
        }
      }

      .quest-number {
        flex: 0 1 30%;
        text-align: center;
      }
      .quest-score {
        color: #fff;
        background: #2428BD;
        font-size: 1.6rem;
        line-height: 1;
        padding: 10px;
        border-radius: 50px;
        flex: 0 1 70%;
        text-align: center;
      }
      .quest-today {
        color: #fff;
        background: #F15A24;
        font-size: 1.6rem;
        line-height: 1;
        padding: 10px;
        border-radius: 50px;
        flex: 0 1 70%;
        text-align: center;
      }
      .quest-tomorrow {
        color: #fff;
        background: #666666;
        font-size: 1.6rem;
        line-height: 1;
        padding: 10px;
        border-radius: 50px;
        flex: 0 1 70%;
        text-align: center;
      }
      .quest-difficult {
        text-align: center;
        margin-bottom: 10px;
      }
      .quest-title {
        color: #000;
      }

      .quest-button {
        position: relative;
        text-align: center;

        .button-main {
        }
        .button-sub {
          position: absolute;
          top: 0;
          right: 0;
          @include media-breakpoint-down(sm) {
            position: relative;
            text-align: right;
            padding-top: 20px;
          }
        }
      }

      .open-hint-modal {
        cursor: pointer;
        background: none;
        border: none;
        outline: none;
        appearance: none;
        transition: opacity .2s;
        &:hover {
          opacity: .8;
        }
      }
      
      .courses_btn_play {
        display: inline-block;
        cursor: pointer;
        text-align: center;
        transition: opacity .2s;
        &:hover {
          opacity: .8;
          animation: bntPlay 1s linear infinite;
        }
      }

      .courses_btn_play_disabled {
        display: inline-block;
        text-align: center;
      }
      
      .buttons {
        text-align: center;
      }
      .courses_btn_for_adults {
        display: inline-block;
        color: #fff;
        background: #F15A24;
        border-radius: 50px;
        padding: 10px 20px;
        font-size: 1.8rem;
        transition: all .2s;
        line-height: 1;
        &:hover {
          text-decoration: none;
          color: #F15A24;
          background: #fff;
        }
      }


      .message1 {
        font-size: 2.4rem;
      }
      .message2 {
        font-size: 1.8rem;
      }

      .assignment {
        display: flex;
        justify-content: space-between;
        @include media-breakpoint-down(sm) {
          display: block;
        }

        .btn_assignment {
          display: block;
          background: none;
          position: relative;
          cursor: pointer;
          border: none;
          text-align: center;
          outline: none;
          appearance: none;
          @include media-breakpoint-down(sm) {
            margin: 20px auto 0;
          }
        }
      }
    }

    &__monster {
      padding: 10px;
      border-radius: 10px;
      background: #097293;
      margin-bottom: 1em;
      border: solid 10px #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      @include media-breakpoint-down(sm) {
        display: block;
      }
    }

    &__quest-list {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    &__description {
      padding: 10px;
      border-radius: 10px;
      background: rgba(255,255,255,.8);
    }
  }

  .course-tag {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    &.has-bg {
      padding: 20px;
      border-radius: 10px;
      background: rgba(255, 255, 255, .8);
    }
    .title {
      flex: 0 1 10%;
    }
    .btn-tag {
      margin: 3px;
      padding: 5px 10px;
      line-height: 1;
      border-radius: 10px;
      background: #fff;
      color: #666;
      font-size: 11px;
      &:hover {
        text-decoration: none;
        background: #666;
        color: #fff;
      }
    }
  }

  .side-nav {
    position: absolute;
    top: 0;
    right: 0;
    width: 320px;
    background: #fff;
    border-radius: 3px;
    .nav {
      .nav-link {
        color: #999;
        border-bottom: solid 2px #e5e5e5;
        padding: 1rem;
        background: rgba(255,255,255,.055);
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  .main-content-course {
    min-height: 400px;
    width: 840px;
    @include media-breakpoint-down(md) {
      width: auto;
      margin: 0 3%;
    }
  }

  .course-head {
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;
    @include media-breakpoint-down(sm) {
      display: block;
    }
    &__left {
      padding: 20px;
      border-radius: 10px;
      background: rgba(255,255,255,.8);
      flex: 0 1 520px;
      margin-right: 10px;
      @include media-breakpoint-down(sm) {
        display: block;
        margin-right: 0;
        margin-bottom: 1em;
      }
    }
    &__right {
      padding: 20px;
      border-radius: 10px;
      background: rgba(255,255,255,.8);
      flex: 0 1 300px;
    }
    &__exam {
      padding: 20px;
      border-radius: 10px;
      background: rgba(255,255,255,.8);
      flex: 0 1 520px;
      margin-right: 10px;
      @include media-breakpoint-down(sm) {
        margin-right: 0;
        margin-bottom: 1em;
      }
    }
    &__progress {
      padding: 20px;
      border-radius: 10px;
      background: rgba(255,255,255,.8);
      flex: 0 1 300px;
      @include media-breakpoint-down(sm) {
        margin-bottom: 1em;
      }
    }

    &__inner {
      display: flex;
      justify-content: space-between;
      @include media-breakpoint-down(sm) {
        display: block;
      }
      .primary {
        @include media-breakpoint-down(sm) {
          margin-bottom: 10px;
        }
      }
    }


    .balloon {
      position: relative;
      width: 171px;
      height: 140px;
      &__up {
        position: absolute;
      }
      &__human {
        position: absolute;
        top: 90px;
        left: 20px;
        img {
          position: absolute;
          top: 0;
        }
      }
      &__down {
        position: absolute;
      }
    }
  }




  //.section {
  //  padding-bottom: 24px;
  //  &__header {
  //    border-bottom: solid 1px #e6e6e6;
  //    margin-bottom: 20px;
  //  }
  //  &__title {
  //    font-size: $h6-font-size;
  //    font-weight: bold;
  //    display: inline-block;
  //    letter-spacing: 0.5rem;
  //    padding: 5px 10px;
  //    color: #333;
  //    margin: 0 0 -1px 0;
  //    background: linear-gradient(transparent 50%, #f3f3bb 50%);
  //  }
  //  &__description {
  //    //background: #E1D2BE;
  //  }
  //}


  .balloon-left {
    position: relative;
    display: inline-block;
    padding: 7px 10px;
    min-width: 120px;
    max-width: 100%;
    color: #555;
    font-size: 12px;
    background: #fff;
  }

  .balloon-left:before{
    content: "";
    position: absolute;
    top: 50%;
    left: -10px;
    margin-top: -5px;
    border: 5px solid transparent;
    border-right: 5px solid #fff;
  }

  .balloon-left p {
    margin: 0;
    padding: 0;
  }

  .system-monster-wrapper {
    padding: 10px;
    border-radius: 10px;
    background: #097293;
    margin-bottom: 1em;
    border: solid 10px #fff;
    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      @include media-breakpoint-down(sm) {
        display: block;
      }
    }
    &__title {
      font-weight: bold;
      font-size: 2.4rem;
      text-align: center;
      color: #fff;
    }
  }

  .system-monster {
    text-align: center;
    flex: 0 1 800px;
    background: url("/statics/images/island/island_monster.svg") no-repeat center 0;
    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
    }
    &__message {
      background: rgba(6, 75, 96,.9);
      border: solid 5px #fff;
      border-radius: 10px;
      margin: 20px 0 0;
      padding: 10px;
      text-align: left;
      color: #fff;
    }
    .monster {
      position: relative;
    }

    .boss-monster {
      animation: bossMonster 10s linear infinite;
      background: url("/statics/images/difficulty/monster_shadow.svg") no-repeat center bottom;
      background-size: 100px auto;
      padding: 0 0 10px;
    }

    .mini-monsters {
      position: relative;
      .monster {
        background: url("/statics/images/difficulty/monster_shadow.svg") no-repeat center bottom;
        background-size: 30px auto;
        padding: 50px 0 20px;
        animation: miniMonster 10s linear infinite;
      }
      .monster1 {
      }
      .monster2 {
        position: absolute;
        top: -20px;
        left: 35%;
        animation-delay: 1s;
      }
      .monster3 {
        position: absolute;
        top: -10px;
        left: 55%;
        animation-delay: 1s;
      }
    }

    .mini-balloon {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      margin: 0;
      padding: 7px 10px;
      width: 200px;
      color: #555;
      font-size: 16px;
      background: #fff;
      border-radius: 5px;
      text-align: left;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: -15px;
        margin-left: -15px;
        border: 15px solid transparent;
        border-right: 15px solid #fff;
      }
      &__inner {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        .txt {
          padding-left: 10px;
        }
      }
      p {
        margin: 0;
        padding: 0;
      }
      .btn {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

  }
  .fellow {
    background: rgba(6, 75, 96,.9);
    border: solid 5px #fff;
    border-radius: 10px;
    margin: 0 0 0 10px;
    padding: 10px;
    text-align: left;
    color: #fff;
  }

  .button-area {
    padding-left: 10px;
    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    .mini-balloon {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      margin: 0;
      padding: 7px 10px;
      width: 200px;
      color: #555;
      font-size: 16px;
      background: #fff;
      border-radius: 5px;
      text-align: left;
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: -15px;
        margin-left: -15px;
        border: 15px solid transparent;
        border-right: 15px solid #fff;
      }
      &__inner {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        .txt {
          padding-left: 10px;
        }
      }
      p {
        margin: 0;
        padding: 0;
      }
      .btn {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }


  .horizon {
    > .course__body {
      margin-left: 10px;
      margin-right: 10px;
      flex: 0 1 50%;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  
  
  .modal-dialog {
    &.modal-draw {
      //max-width: 638px;
      .modal-content {
        background: #FFF9C7 url("/statics/images/drawing/bg.svg") no-repeat center bottom;
        background-size: 639px auto;
        padding: 0;
        //width: 640px;
      }
      .modal-header {
        border: none;
        > div {
          width: 100%;
        }
      }

      .draw-teacher {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        &__text {
          font-size: 2.4rem;
        }
      }
    }

    .drawing-wrapper {
      position: relative;

      #container {
        width: 300px;
        height: 300px;
        margin: auto;
      }

      .color {
        position: absolute;
        padding: 10px;
        cursor: pointer;
        transition: opacity 0.2s;
        &:hover {
          opacity: 0.9;
          background: #F76900;
          border-radius: 10px;
        }
      }

      $colorTop: 50;

      .color-1 {
        top: ($colorTop*0)+px;
        left: 0;
        color:#3E3E3E;
      }
      .color-2 {
        top: ($colorTop*1)+px;
        left: 0;
        color:#7C5642;
      }
      .color-3 {
        top: ($colorTop*2)+px;
        left: 0;
        color:#fce2c4;
      }
      .color-4 {
        top: ($colorTop*3)+px;
        left: 0;
        color:#FF8944;
      }
      .color-5 {
        top: ($colorTop*4)+px;
        left: 0;
        color:#AD4E39;
      }
      .color-6 {
        top: ($colorTop*5)+px;
        left: 0;
        color:#D882A5;
      }
      .color-7 {
        top: ($colorTop*0)+px;
        right: 0;
        color:#F3D425;
      }
      .color-8 {
        top: ($colorTop*1)+px;
        right: 0;
        color:#94A639;
      }
      .color-9 {
        top: ($colorTop*2)+px;
        right: 0;
        color:#008954;
      }
      .color-10 {
        top: ($colorTop*3)+px;
        right: 0;
        color:#436BA1;
      }
      .color-11 {
        top: ($colorTop*4)+px;
        right: 0;
        color:#4A3F75;
      }
      .color-12 {
        top: ($colorTop*5)+px;
        right: 0;
        color:#5EA5C4;
      }

      .drawing-sub {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 300px;
        margin: 20px auto;
        .selected {
          padding: 10px;
        }
      }
      .selected {
        border-radius: 10px;
        background: #F76900;
      }
    }
    .btn-list {
      padding: 20px 100px 20px;
    }
  }


}

#page-categories {

  .header-wave {
    height: 200px;
  }

  .content-wrapper {
    position: relative;
    z-index: 1;
  }

  .side-nav {
    position: absolute;
    top: 0;
    right: 0;
    width: 320px;
    background: #fff;
    border-radius: 3px;
    .nav {
      .nav-link {
        color: #999;
        border-bottom: solid 2px #e5e5e5;
        padding: 1rem;
        background: rgba(255,255,255,.055);
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  .main-content {
    min-height: 400px;
    width: 840px;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .page-header {
    position: relative;
    z-index: 2;
    margin-top: -130px;
    margin-bottom: 30px;
    &__tips {
      position: absolute;
      top: 10px;
      background: #F66800;
      color: #fff;
      font-size: 10px;
      padding: 3px 10px;
      text-shadow: none;
    }
    &__title {
      position: absolute;
      width: 640px;
      box-sizing: border-box;
      bottom: 20px;
      margin: 0 0 0 200px;
      background: #F2E3D8;
      color: #333;
      font-weight: bold;
      font-size: 4em;
      text-shadow: 3px 3px 3px #eee;
      border: solid 6px #9D5928;
      padding: 20px;
      @include media-breakpoint-down(md) {
        width: auto;
        position: relative;
        bottom: auto;
        margin: 0;
      }
    }

    .balloon-right {
      position: relative;
      display: inline-block;
      margin: 1.5em 15px 1.5em 0;
      padding: 7px 10px;
      min-width: 120px;
      max-width: 100%;
      color: #555;
      font-size: 12px;
      background: #e0edff;
    }

    .balloon-right:before{
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -10px;
      border: 10px solid transparent;
      border-left: 10px solid #e0edff;
    }

    .balloon-right p {
      margin: 0;
      padding: 0;
    }

    .balloon {
      position: relative;
      width: 171px;
      height: 294px;
      @include media-breakpoint-down(md) {
        width: 100px;
        height: 164px;
      }
      &__up {
        position: absolute;
      }
      &__human {
        position: absolute;
        bottom: 0;
        left: 40px;
      }
      &__down {
        position: absolute;
      }
    }
  }

  main {

    .categories {
      background: #fff;
      margin-bottom: 20px;
      padding: 20px;
      border-radius: 10px;
      &__title {
        font-size: 18px;
        font-weight: bold;
      }
      &__list {
        margin-bottom: 20px;
        .category-link {
          margin: 3px;
          padding: 3px 10px;
          color: #fff;
          background: rgba(246, 105, 0, 1);
          border-radius: 3px;
          border: solid 1px #333;
        }
      }
      a {
        display: inline-block;
        color: #fff;
        text-align: center;
        width: 16%;
        padding: 0.375rem 0.75rem;
        font-size: 1.6rem;
        line-height: 1.5;
        border-radius: 0.25rem;
        margin-bottom: 5px;
        @include media-breakpoint-down(md) {
          width: 32%;
          font-size: 1.2rem;
          padding: 10px 0;
        }
        &:hover {
          opacity: 0.8;
          text-decoration: none;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

  }


  .difficult {
    &__wrap {
      //display: flex;
      //align-items: center;
    }
    &__title {
      font-size: 18px;
      font-weight: bold;
    }
    &__list {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      background: #eee;
      border-radius: 10px;
      li {
        text-align: center;
        padding: 0 20px;
      }
    }
  }

}

// --------------------------------------------------
// balloon modules
// --------------------------------------------------

.mod_bln {
  position: relative;
  z-index: 2;
  //display: flex;
  //align-items: center;
  padding: 7px 10px;
  min-width: 120px;
  max-width: 100%;
  color: #555;
  font-size: 16px;
  background: #fff;
  border-radius: 10px;
  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 70%;
    border: 15px solid transparent;
  }

  &.bln-h5 {
    min-height: 5em
  }

  &.bln-left {
    margin: 1.5em 0 1.5em 15px;
    &:before {
      left: -30px;
      margin-top: -15px;
      border-right: 15px solid #fff;
    }
  }

  &.bln-top {
    margin: 15px 0 0 0;
    &:before {
      top: -25px;
      left: 40%;
      border-bottom: 15px solid #fff;
    }
  }

  &.bln-bottom {
    margin: 0 0 15px 0;
    &:before {
      top: auto;
      bottom: -25px;
      left: 40%;
      border-top: 15px solid #fff;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }
  
  
  &.bln-blue {
    background: #D0E6F2;
    &.bln-left {
      &:before {
        border-right-color: #D0E6F2;
      }
    }
    &.bln-top {
      &:before {
        border-bottom-color: #D0E6F2;
      }
    }
    &.bln-bottom {
      &:before {
        border-top-color: #D0E6F2;
      }
    }
  }

}
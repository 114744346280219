@keyframes cloud1 {
  0% {
    left: 10vw;
  }
  50% {
    left: 20vw;
  }
  100% {
    left: 10vw;
  }
}

@keyframes cloud2 {
  0% {
    right: 10vw;
  }
  50% {
    right: 20vw;
  }
  100% {
    right: 10vw;
  }
}

@keyframes wave1 {
  0% {
    background-position: 0 bottom;
  }
  50% {
    background-position: left 100px bottom -5px;
  }
  100% {
    background-position: 0 bottom;
  }
}

@keyframes wave2 {
  0% {
    background-position: 0 bottom;
  }
  50% {
    background-position: left 80px bottom -5px;
  }
  100% {
    background-position: 0 bottom;
  }
}

@keyframes wave3 {
  0% {
    background-position: 0 bottom;
  }
  50% {
    background-position: left 60px bottom -5px;
  }
  100% {
    background-position: 0 bottom;
  }
}

@keyframes wave4 {
  0% {
    background-position: 0 bottom;
  }
  50% {
    background-position: left 40px bottom -5px;
  }
  100% {
    background-position: 0 bottom;
  }
}


@keyframes aniMon1 {
  0% {
    right: 0;
    transform: rotate(-10deg);
  }
  10% {
    transform: rotate(10deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  30% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    right: 200px;
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(-10deg);
  }
  70% {
    transform: rotate(10deg);
  }
  80% {
    transform: rotate(-10deg);
  }
  90% {
    transform: rotate(10deg);
  }
  100% {
    right: 0;
    transform: rotate(-10deg);
  }
}

@keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

@keyframes bntPlay {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.96);
  }
  20% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.96);
  }
  40% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bntPlay1on {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bntPlayMonsterOn {
  0% {
    transform: scale(1,1);
  }
  10% {
    transform: scale(1.5,1);
  }
  20% {
    transform: scale(1,1.5);
  }
  40% {
    transform: scale(1,1);
  }
  100% {
    transform: scale(1,1);
  }
}

@keyframes bntPlayScore {
  0% {
    transform: scale(1,1);
  }
  10% {
    transform: scale(1.5,1);
  }
  20% {
    transform: scale(1,1.5);
  }
  40% {
    transform: scale(1,1);
  }
  100% {
    transform: scale(1,1);
  }
}

@keyframes loading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loadingBg {
  0% {
    transform: rotate(0deg) scale(1,1);
    opacity: 1;
  }
  50% {
    transform: rotate(180deg) scale(.8,.8);
    opacity: .5;
  }
  100% {
    transform: rotate(360deg) scale(1,1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes shake {
  0% {
    transform: translate(0, 0) scale(1,1);
  }
  10% {
    transform: translate(-10px, 0) scale(1.05,1);
  }
  20% {
    transform: translate(0, 0) scale(1,1);
  }
  30% {
    transform: translate(10px,0) scale(1.05,1);
  }
}

@keyframes heartSleep {
  0% {
    transform: scale(1,1);
  }
  33% {
    transform: scale(0.8,0.8) rotate(20deg);
  }
  66% {
    transform: scale(0.8,0.8) rotate(-20deg);
  }
  100% {
    transform: scale(1,1) rotate(0deg);
  }
}

@keyframes perfectStarts {
  0% {
    transform: scale(1,1);
  }
  33% {
    transform: scale(0.8,0.8) rotate(20deg);
  }
  66% {
    transform: scale(0.8,0.8) rotate(-20deg);
  }
  100% {
    transform: scale(1,1) rotate(0deg);
  }
}

@keyframes perfectStartsBg {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes perfectStartsBgLight {
  0% {
    opacity: 0;
  }
  50% {
    opacity: .3;
  }
  100% {
    opacity: 0;
  }
}




@keyframes miniMonster {
  0% {
    transform: translate(0, 0) scale(1,1);
    opacity: 1;
  }
  10% {
    transform: scale(.8,1.2);
  }
  20% {
    transform: translate(-10px, -10px) scale(1.02,1.02);
    opacity: 0.5;
  }
  30% {
    transform: scale(.8,1.2);
  }
  40% {
    transform: translate(-10px, 10px) scale(1.02,1.02);
    opacity: 1;
  }
  50% {
    transform: scale(.8,1.2);
  }
  60% {
    transform: translate(10px, -10px) scale(1.02,1.02);
    opacity: 0.5;
  }
  70% {
    transform: scale(.8,1.2);
  }
  80% {
    transform: translate(10px, 10px) scale(1.02,1.02);
    opacity: 1;
  }
  90% {
    transform: scale(.8,1.2);
  }
  100% {
    transform: translate(0, 0) scale(1,1);
    opacity: 0.5;
  }
}

@keyframes bossMonster {
  0% {
    transform: translate(0, 0) scale(1,1);
  }
  10% {
    transform: scale(.8,1.2);
  }
  20% {
    transform: translate(-10px, -10px) scale(1.02,1.02);
  }
  30% {
    transform: scale(.8,1.2);
  }
  40% {
    transform: translate(-10px, 10px) scale(1.02,1.02);
  }
  50% {
    transform: scale(.8,1.2);
  }
  60% {
    transform: translate(10px, -10px) scale(1.02,1.02);
  }
  70% {
    transform: scale(.8,1.2);
  }
  80% {
    transform: translate(10px, 10px) scale(1.02,1.02);
  }
  90% {
    transform: scale(.8,1.2);
  }
  100% {
    transform: translate(0, 0) scale(1,1);
  }
}

@keyframes moveMonster1 {
  0% {
    transform: translate(0, 0) scale(1,1);
    opacity: 1;
  }
  10% {
    transform: scale(.8,1.2);
  }
  20% {
    transform: translate(-10px, -10px) scale(1.02,1.02);
  }
  30% {
    transform: scale(.8,1.2);
  }
  40% {
    transform: translate(-10px, 10px) scale(1.02,1.02);
    opacity: 0.5;
  }
  50% {
    transform: scale(.8,1.2);
  }
  60% {
    transform: translate(10px, -10px) scale(1.02,1.02);
    opacity: 1;
  }
  70% {
    transform: scale(.8,1.2);
  }
  80% {
    transform: translate(10px, 10px) scale(1.02,1.02);
    opacity: 1;
  }
  90% {
    transform: scale(.8,1.2);
  }
  100% {
    transform: translate(0, 0) scale(1,1);
    opacity: 1;
  }
}

@keyframes moveMonsterBoss {
  0% {
    transform: translate(0, 0) scale(1,1);
  }
  10% {
    transform: scale(.8,1.2);
  }
  20% {
    transform: translate(-10px, -10px) scale(1.02,1.02);
  }
  30% {
    transform: scale(.8,1.2);
  }
  40% {
    transform: translate(-10px, 10px) scale(1.02,1.02);
  }
  50% {
    transform: scale(.8,1.2);
  }
  60% {
    transform: translate(10px, -10px) scale(1.02,1.02);
  }
  70% {
    transform: scale(.8,1.2);
  }
  80% {
    transform: translate(10px, 10px) scale(1.02,1.02);
  }
  90% {
    transform: scale(.8,1.2);
  }
  100% {
    transform: translate(0, 0) scale(1,1);
  }
}


@keyframes slideIn {
  0% {
    transform: translate(100px, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translate(-100px, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes resultBalloon1 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -20%);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes resultBalloon2 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -10%);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes resultMonster1 {
  0% {
    padding-bottom: 20px;
    transform: translate(0, 0);
  }
  25% {
    padding-bottom: 20px;
  }
  30% {
    padding-bottom: 40px;
  }
  35% {
    padding-bottom: 20px;
  }
  40% {
    padding-bottom: 40px;
  }
  45% {
    padding-bottom: 20px;
    transform: translate(0, 0);
  }

  65% {
    transform: translate(-10vw, 0) rotateY(0deg);
  }
  70% {
    transform: translate(-10vw, 0) rotateY(180deg);
  }
  95% {
    transform: translate(0, 0) rotateY(180deg);
  }
  100% {
    transform: translate(0, 0) rotateY(0deg);
  }
}

@keyframes docsDesitBg {
  0% {
    background-position: center 0;
  }
  100% {
    background-position: center 100%;
  }
}
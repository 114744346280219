.modal {
  display: none;
  //background: rgba(0,0,0,0.5);
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  z-index: 1041;
}
.island {
  .mymap {
    position: relative;
    &__1 {
      position: absolute;
      bottom: 0;

    }
  }
}
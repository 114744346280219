#page-series {

  .series-index-header {
    position: relative;
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    margin-bottom: 20px;
    @include media-breakpoint-down(sm) {
      border-radius: 10px;
      display: block;
    }

    .primary {
      margin-right: 1em;
      @include media-breakpoint-down(sm) {
        text-align: center;
        padding-top: 16px;
      }
    }

    .icon {
      img {
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }

    .title {
      font-size: 4.3rem;
      font-weight: bold;
      @include media-breakpoint-down(sm) {
        text-align: center;
        padding-top: 16px;
      }
    }
  }


  .series-group {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    > .series-card {
      margin-bottom: 20px;
      flex: 0 1 49%;
      @include media-breakpoint-down(sm) {
        flex: 0 1 100%;
      }
    }
  }



  .series-card {
    display: block;
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    transition: opacity .2s;
    @include media-breakpoint-down(sm) {
      border-radius: 10px;
      padding: 10px;
    }
    
    .card-thumbs {
      //background: #eee;
      + .card-title {
        margin-top: 8px;
      }
    }

    .card-title {
      font-size: 1.8rem;
      font-weight: bold;
      margin: 0;
      letter-spacing: normal;
    }

    &:hover {
      opacity: .8;
      text-decoration: none;
    }
  }

  .social-links {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    min-width: 160px;
    .social-item {
      display: flex;
      align-items: center;
      margin: 0 4px;
    }
  }
  
  
  
  // show

  .series-show-header {
    position: relative;
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;
    @include media-breakpoint-down(sm) {
      padding: 10px;
      margin-bottom: 40px;
    }

    .series-about {
      background: #E6E6E6;
      font-size: 1.8rem;
      text-align: center;
      padding: 10px;
      margin: -20px -20px 20px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      @include media-breakpoint-down(sm) {
        font-size: 1.2rem;
        margin: -10px -10px 10px;
      }
    }

    .series-thumb {
      margin-bottom: 10px;
      @include media-breakpoint-down(sm) {
        margin-bottom: 4px;
      }
      img {
        width: 100%;
      }
    }
    .series-title {
      font-size: 4.8rem;
      margin-bottom: 10px;
      @include media-breakpoint-down(sm) {
        font-size: 2.4rem;
        margin-bottom: 4px;
      }
    }

    .series-summary {
      font-size: 2.1rem;
      margin-bottom: 20px;
      @include media-breakpoint-down(sm) {
        font-size: 1.4rem;
        margin-bottom: 10px;
      }
    }

    .series-meta-count {
      position: relative;
      display: flex;
      margin-top: 32px;
      margin-bottom: 10px;
      @include media-breakpoint-down(sm) {
        display: block;
      }

      .count-heart {
        display: flex;
        align-items: center;
        margin-right: 20px;
        @include media-breakpoint-down(sm) {
          margin-bottom: 10px;
        }
        .icon {
          margin-right: 4px;
        }
      }

      .social-links {
        margin: 0 0 0 auto;
        position: relative;
        top: 0;
        min-width: inherit;
        @include media-breakpoint-down(sm) {
          position: relative;
          top: 0;
          right: 0;
          margin-bottom: 10px;
          justify-content: flex-end;
        }
      }

      .custom-share-button {
        display: flex;
        align-items: center;
        border-radius: 4px;
        font-size: 1.2rem;
        line-height: 1;
        padding: 4px 8px;
        transition: opacity .2s;
        .icon {
          margin-right: 2px;
          img {
            vertical-align: top;
          }
        }
        .name {
          color: #fff;
        }
        &.facebook {
          background: #1977F2;
        }
        &.twitter {
          background: #1DA2F3;
        }
        &:hover {
          text-decoration: none;
          opacity: .8;
        }
      }
    }

    .series-readme {
      background: #E6E6E6;
      border-radius: 10px;
      padding: 10px;
      .readme-title {
        font-size: 1.4rem;
      }
      .readme-message {
        font-size: 1.4rem;
      }
    }

    .series-teacher {
      display: flex;
      align-items: center;
      @include media-breakpoint-down(sm) {
        margin-bottom: 8px;
      }
      .icon {
        margin-right: 4px;
        border-radius: 50px;
        overflow: hidden;
      }
      .heading {
        font-size: 1.1rem;
        font-weight: bold;
      }
      .name {
        font-size: 1.4rem;
        font-weight: normal;
        margin: 0;
        a {
          color: #666;
        }
      }
    }
  }


}

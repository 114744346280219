// old common

// リンクじゃないのでそう見えるように
.page-item .page-link.active {
  z-index: 2;
  color: #fff;
  background-color: #F66900;
  border-color: #F66900;
}


.media-image {
  border-radius: 50%;
  width: 120px;
}

.card-title {
  letter-spacing: 0.5rem;
}

.main-container {
  position: relative;
}





.global-nav {
  width: 100%;
}




$islandSize: 64;
$islandBottom: 32;

.mymap_old {
  background: #007396;
  border-radius: 10px;
  padding: 50px;
  width: 1100px;
  margin: 0 auto;
  &__inner {
    position: relative;
    height: 300px;
  }
  .island {
    position: absolute;
    width: $islandSize*2+px;
    height: $islandSize*2+px;
    img {
      position: absolute;
      bottom: 0;
      width: $islandSize*2+px;
    }
    transition: all 0.2s;
    &:hover {
      transform: scale(1.1);
    }
  }

  .x1 {
    left: $islandSize*0+px;
  }
  .x2 {
    left: $islandSize*1+px;
  }
  .x3 {
    left: $islandSize*2+px;
  }
  .x4 {
    left: $islandSize*3+px;
  }
  .x5 {
    left: $islandSize*4+px;
  }
  .x6 {
    left: $islandSize*5+px;
  }
  .x7 {
    left: $islandSize*6+px;
  }
  .x8 {
    left: $islandSize*7+px;
  }
  .x9 {
    left: $islandSize*8+px;
  }
  .x10 {
    left: $islandSize*9+px;
  }
  .x11 {
    left: $islandSize*10+px;
  }
  .x12 {
    left: $islandSize*11+px;
  }
  .x13 {
    left: $islandSize*12+px;
  }
  .x14 {
    left: $islandSize*13+px;
  }

  .y1 {
    bottom: $islandBottom*0+px;
  }
  .y2 {
    bottom: $islandBottom*1+px;
  }
  .y3 {
    bottom: $islandBottom*2+px;
  }
  .y4 {
    bottom: $islandBottom*3+px;
  }
  .y5 {
    bottom: $islandBottom*4+px;
  }
  .y6 {
    bottom: $islandBottom*5+px;
  }
  .y7 {
    bottom: $islandBottom*6+px;
  }
  .y8 {
    bottom: $islandBottom*7+px;
  }
  .y9 {
    bottom: $islandBottom*8+px;
  }
  .y10 {
    bottom: $islandBottom*9+px;
  }
  .y11 {
    bottom: $islandBottom*10+px;
  }
  .y12 {
    bottom: $islandBottom*11+px;
  }
  .y13 {
    bottom: $islandBottom*12+px;
  }
  .y14 {
    bottom: $islandBottom*13+px;
  }
}






.btn-border {
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-left: solid 5px rgba(255,255,255,.5);
    height: 100%;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-left: solid 5px rgba(255,255,255,.5);
    height: 100%;
  }
}


.balloon1-top {
  position: relative;
  display: inline-block;
  margin: 1.5em 0 0 0;
  padding: 7px 10px;
  min-width: 120px;
  max-width: 100%;
  color: #555;
  font-size: 16px;
  background: #fff;
  &:before {
    content: "";
    position: absolute;
    top: -30px;
    left: 50%;
    margin-left: -15px;
    border: 15px solid transparent;
    border-bottom: 15px solid #fff;
  }
  p {
    margin: 0;
    padding: 0;
  }
}

.balloon1-bottom {
  position: relative;
  display: inline-block;
  margin: 1.5em 0 0 0;
  padding: 7px 10px;
  min-width: 120px;
  max-width: 100%;
  color: #555;
  font-size: 16px;
  background: #fff;
  &:before {
    content: "";
    position: absolute;
    top: -30px;
    left: 50%;
    margin-left: -15px;
    border: 15px solid transparent;
    border-bottom: 15px solid #fff;
  }
  p {
    margin: 0;
    padding: 0;
  }
}

.balloon1-left {
  position: relative;
  display: inline-block;
  margin: 1.5em 0 1.5em 15px;
  padding: 7px 10px;
  min-width: 120px;
  max-width: 100%;
  color: #555;
  font-size: 16px;
  background: #e0edff;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -30px;
    margin-top: -15px;
    border: 15px solid transparent;
    border-right: 15px solid #e0edff;
  }
  p {
    margin: 0;
    padding: 0;
  }
}

.balloon1-right {
  position: relative;
  display: inline-block;
  margin: 1.5em 15px 1.5em 0;
  padding: 7px 10px;
  min-width: 120px;
  max-width: 100%;
  color: #555;
  font-size: 16px;
  background: #e0edff;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    right: -30px;
    margin-top: -15px;
    border: 15px solid transparent;
    border-left: 15px solid #e0edff;
  }
  p {
    margin: 0;
    padding: 0;
  }
}



.question-user-img {
  text-align: center;
  margin-top: 1rem;
  img {
    //width: 100%;
    max-width: 100%;
    border: solid 3px #fff;
    box-shadow: 0 0 3px rgba(0,0,0,.3);
  }
}


// --------------------------------------------------
// base layout new
// --------------------------------------------------


@function get_vw($size, $viewport:320){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@mixin fz_vw($font_size:10){
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}


html {
  font-size: 62.5%;
}
body {
  background: #e7fcfb;
  font-size: 16px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  font-weight: 500;

  min-width: 1200px;
  @include media-breakpoint-down(md) {
    min-width: auto;
  }
}
.font-gothic {
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  font-weight: 500;
}
.font-mincho {
  font-family: "游明朝体", YuMincho, "游明朝", "Yu Mincho", serif;
  font-weight: 400;
}

img {
  @include media-breakpoint-down(md) {
    max-width: 100%;
  }
}


.title-tip {
  > span {
    display: inline-block;
    font-size: 11px;
    font-weight: bold;
    color: #fff;
    background: #3195ca;
    padding: 3px 10px;
    border-radius: 1px;
    margin-bottom: 5px;
  }
}

.course-group {
  display: flex;
  flex-wrap: wrap;
  @include media-breakpoint-down(md) {
    //justify-content: space-between;
  }
  @include media-breakpoint-down(sm) {
    display: block;
  }

  .course {
    display: block;
    width: 273px;
    margin: 10px 0;
    background: #fff;
    border-radius: 10px;
    border: solid 10px #fff;
    position: relative;
    color: #212529;
    transition: all .2s;

    &:hover {
      text-decoration: none;
      opacity: .9;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }


    // flexbox均等の対応(space-between使わない）
    &:nth-child(3n+2) {
      margin: 10px;
      @include media-breakpoint-down(md) {
        margin: 10px;
      }
      @include media-breakpoint-down(sm) {
        margin: 10px 0;
      }
    }

    &__thumbs {
      position: relative;
      height: 200px;
      background: #fff;
      box-shadow: 0 0 3px rgba(0, 0, 0, .1);
      margin-bottom: 10px;

      .thumbs {
        text-align: center;

        img {
          width: 250px;
          height: 200px;
          object-fit: cover;
        }
      }

      .status {
        position: absolute;
        top: 10px;
        left: 10px;
      }
    }

    &__making {
      background: #fff;
      max-height: 216px;
      margin-right: 10px;
      flex: 0 1 200px;
      padding-top: 50px;
      text-align: center;
      @include media-breakpoint-down(sm) {
        width: 100%;
        max-height: initial;
        margin-bottom: 10px;
        padding-top: 10px;
      }
      img {
        width: 221px;
        height: 216px;
        object-fit: cover;
        @include media-breakpoint-down(sm) {
          width: 100%;
          height: auto;
        }
      }
    }

    &__header {
      padding: 5px;
      color: #fff;
    }

    &__main {
      position: relative;
      margin-bottom: 100px;
      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
      }

      .btn {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    .progress-wrapper {
      margin-bottom: 40px;
      @include media-breakpoint-down(sm) {
        padding: 30vw 10px 10vw;
        margin-bottom: 0;
      }
    }

    &__difficulty {
      font-size: 12px;
      display: flex;
      align-items: center;
      @include media-breakpoint-down(md) {
        display: block;
        margin-bottom: 1rem;
      }

      .balloon {
        position: relative;
        display: inline-block;
        margin: 1.5em 15px 1.5em 0;
        padding: 7px 10px;
        min-width: 120px;
        max-width: 100%;
        color: #555;
        font-size: 12px;
        background: #e0edff;
        @include media-breakpoint-down(md) {
          margin: 0;
        }

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 100%;
          margin-top: -10px;
          border: 10px solid transparent;
          border-left: 10px solid #e0edff;
          @include media-breakpoint-down(md) {
            top: 120%;
            left: 70%;
            transform: rotate(90deg);
          }
        }

        p {
          margin: 0;
          padding: 0;
        }
      }

      //.balloon {
      //  position: relative;
      //  width: 171px;
      //  height: 294px;
      //  &__up {
      //    position: absolute;
      //  }
      //  &__human {
      //    position: absolute;
      //    bottom: 0;
      //    left: 40px;
      //  }
      //  &__down {
      //    position: absolute;
      //  }
      //}
      .difficulty_monstars {
        display: flex;
        align-items: center;
        @include media-breakpoint-down(md) {
          justify-content: flex-end;
        }
      }

    }

    &__description {
      min-height: 3em;
      @include media-breakpoint-down(md) {
        font-size: 1.4rem;
      }
    }

    &__footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      @include media-breakpoint-down(md) {
        position: relative;
      }
    }

    .title-tip {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      > span {
        background: transparent;
        border: solid 1px #fff;
      }

      .heart {
        display: inline-block;
        border-radius: 20px;
        padding: 2px 10px;
        background: rgba(255, 255, 255, .5);
        line-height: 1;

        .count {
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
  }
}

.p-note-group {
  display: flex;
  flex-wrap: wrap;
  @include media-breakpoint-down(md) {
    //justify-content: space-between;
  }
  @include media-breakpoint-down(sm) {
    display: block;
  }

  .p-note {
    margin: 10px 0;
    // flexbox均等の対応(space-between使わない）
    &:nth-child(2),
    &:nth-child(3n+2) {
      margin: 10px;
      @include media-breakpoint-down(md) {
        margin: 10px;
      }
      @include media-breakpoint-down(sm) {
        margin: 10px 0;
      }
    }
  }

}

.p-note {
  display: block;
  width: 273px;
  margin: 10px 0;
  padding-bottom: 80px;
  background: #fff;
  border-radius: 10px;
  border: solid 10px #fff;
  position: relative;
  color: #212529;
  transition: all .2s;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  &:hover {
    text-decoration: none;
    opacity: .9;
  }

  &__header {
    padding: 5px;
    color: #000;
    text-align: center;
  }
  &__main {
    margin-bottom: 10px;
    //min-height: 6em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
  }

  &__category-wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: solid 10px #fff;
  }
  &__category {
    display: inline-block;
    border: solid 1px #fff;
    color: #fff;
    padding: 5px 10px;
    font-size: 1.1rem;
    margin-right: 20px;
  }

  &__icon {
    > span {
      display: block;
    }
    .icon-image {}
    .icon-text {
      font-size: 1.2rem;
      color: #f66900;
    }
  }
  &__title {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    word-break: break-all;
  }
  &__teacher {
    font-size: 1.1rem;
    .icon-teacher {
      margin-right: 10px;
    }
  }

}


.p-note-detail {

  &__header {
    background: #fff;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 20px;
  }

  &__icon-teacher {
    display: flex;
    align-items: center;
    text-align: center;
    margin-right: 20px;
    margin-bottom: 20px;
    .icon-image {
      margin-right: 10px;
      img {
        width: 24px;
        @include media-breakpoint-down(sm) {
          width: 24px;
        }
      }
    }
    .icon-text {
      font-weight: bold;
      font-size: 1.6rem;
      @include media-breakpoint-down(sm) {
        font-size: 1.2rem;
      }
    }
  }
  &__title-wrapper {
    display: flex;
  }
  &__icon-note {
    text-align: center;
    margin-right: 20px;
    margin-bottom: 20px;
    .icon-image {
      margin-bottom: 5px;
      img {
        width: 60px;
        @include media-breakpoint-down(sm) {
          width: 40px;
        }
      }
    }
    .icon-text {
      font-weight: bold;
      font-size: 1.2rem;
      color: #f66900;
      @include media-breakpoint-down(sm) {
        font-size: 1.2rem;
      }
    }
  }
  &__title {
    font-size: 4rem;
    font-weight: bold;
    word-break: break-all;
    @include media-breakpoint-down(sm) {
      font-size: 3rem;
    }
  }

  &__category {
    display: inline-block;
    color: #fff;
    border: solid 1px #fff;
    font-size: 1.1rem;
    line-height: 1;
    padding: 5px 10px;
  }

  &__tag-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  &__tag {
    &:not(:last-child) {
      margin-right: 20px;
    }
    a {
      color: #333;
      border-bottom: dashed 1px #999;
      &:not(:last-child) {
        margin-right: 20px;
      }
      .oi {
        font-size: 1.4rem;
        color: #F66900;
      }
      &:hover {
        opacity: .8;
        text-decoration: none;
      }
    }
  }

  &__article {
    background: #fff;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 20px;
    font-size: 2.4rem;
    line-height: 2;
    p {
      margin-block-start: 1em;
      margin-block-end: 1em;
      word-break: break-all;
    }
  }

  &__answer {
    position: relative;
    min-height: 400px;
    @include media-breakpoint-down(sm) {
      min-height: 200px;
    }
    .answer-button {
      display: flex;
      padding: 10px 20px;
      color: #212529;
      background: #fff;
      border: solid 4px #F66900;
      border-radius: 50px;
      text-align: center;
      text-decoration: none;
      max-width: 400px;
      margin: 100px auto 40px;
      cursor: pointer;
      font-size: 2rem;
      font-weight: bold;
      transition: opacity .2s;
      //box-shadow: 5px 5px 0 #D9B499;
      background: linear-gradient(to bottom, #fff 50%, #eee 100%);
      @include media-breakpoint-down(sm) {
        font-size: 1.6rem;
      }
      &::before {
        position: absolute;
        top: -60px;
        left: 50%;
        margin-left: -10px;
        content: '';
        border-top: solid 4px #fff;
        border-right: solid 4px #fff;
        width: 20px;
        height: 20px;
        display: block;
        transform: rotate(135deg);
      }
      &:hover {
        color: #fff;
        background: #F66900;
      }

      &__icon {
        display: block;
        margin: -40px 50px -40px -50px;
        @include media-breakpoint-down(sm) {
          margin: -20px 25px -20px -25px;
        }
        img {
          @include media-breakpoint-down(sm) {
            width: 92px;
          }
        }
      }

      &__text {
        display: block;
        margin: 0;
      }

    }
    > input[type="checkbox"] {
      display: none;
    }
    .answer-content {
      transform: scale(1,0);
      height: 0;
    }
    > input[type="checkbox"]:checked ~ .answer-button {
      display: none;
    }
    > input[type="checkbox"]:checked ~ .answer-content {
      animation: noteAnswerContent .2s forwards;
    }
  }

  &__send-message-wrapper {
    //max-width: 600px;
    margin: 0 auto 100px;
  }

  &__send-message {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    background: #d2e7f3;
    padding: 10px;
    border-radius: 20px;
    margin-top: 50px;
    .send-message-icon {
      margin: -50px 20px;
    }
    .send-message-text {
      margin-right: 20px;
    }
  }

  &__tips-aside {
    margin: 0 auto 40px;
    background: #8BC4E0;
    border-radius: 20px;
    padding: 30px;

    .aside-inner {
      max-width: 600px;
      margin: 0 auto;
      position: relative;
      //border: solid 1px #ccc;
      background: #D2E8F3;
      padding: 30px;
      border-radius: 3px;
      //overflow: hidden;
      &::before,
      &::after {
        content: '';
        position: absolute;
        transform: rotate(-35deg);
        width: 70px;
        height: 25px;
        background: #8BC4E0;
        z-index: 1;
      }
      &::before {
        top: -10px;
        left: -25px;
        border-bottom: solid 1px #ccc;
      }
      &::after {
        bottom: -10px;
        right: -25px;
        border-top: solid 1px #ccc;
      }
    }
    .sub-title {
      position: absolute;
      top: 0;
      left: 0;
      background: #3DCE9C;
      line-height: 1;
      color: #fff;
      font-size: 1.4rem;
      font-weight: bold;
      padding: 10px 20px;
    }
    .aside-item {
      display: flex;
      //justify-content: center;
      @include media-breakpoint-down(sm) {
        display: block;
      }

      .photo {
        @include media-breakpoint-down(sm) {
          margin-bottom: 20px;
        }
        .icon {
          //padding: 20px;
          //background: #eee;
          margin-right: 40px;
          @include media-breakpoint-down(sm) {
            text-align: center;
            margin-right: 0;
          }

          img {
            overflow: hidden;
            box-shadow: 0 0 5px rgba(0,0,0,.3);
            border-radius: 200px;
            object-fit: cover;
            width: 100px;
            height: 100px;
          }
        }
        .link {
          margin-right: 20px;
          @include media-breakpoint-down(sm) {
            margin-right: 0;
          }
          a {
            display: block;
            background: #fff;
            border-radius: 50px;
            border: solid 1px #3DCE9C;
            text-align: center;
            color: #3DCE9C;
            font-size: 1.4rem;
          }
        }
      }

      .name {
        font-size: 1.8rem;
        line-height: 2;
        @include media-breakpoint-down(sm) {
          font-size: 1.4rem;
        }
      }
      .message {
        font-size: 1.6rem;
        line-height: 2;
        @include media-breakpoint-down(sm) {
          font-size: 1.4rem;
        }
      }
      .more-list {
        display: flex;
        //justify-content: center;
        align-items: center;
        margin: 0 40px 0 0;
        padding: 10px 0 0 0;
        list-style: none;
        max-width: 100px;
        @include media-breakpoint-down(sm) {
          //justify-content: center;
          margin: auto;
        }
        > li {
          margin: 0 10px 0 0;
        }
      }

      .btn-more {
        position: relative;
        padding-left: 16px;
        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -6px;
          line-height: 1;
          display: block;
          content: '▼';
          transform: rotate(-90deg);
        }
      }
    }

  }

  &__tips-other {
    margin-bottom: 100px;

    .other-inner {
      position: relative;
      //border: solid 1px #ccc;
      overflow: hidden;
      border-bottom: solid 1px #ccc;
      padding-bottom: 30px;
    }

    .line-title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      span {
        flex: 0 0 auto;
        padding: 0 20px;
        font-weight: bold;
        font-size: 1.4rem;
        color: #fff;
      }
      &::before {
        flex: 0 1 100%;
        content: '';
        height: 1px;
        width: 100%;
        background: #ccc;
      }
      &::after {
        flex: 0 1 100%;
        content: '';
        height: 1px;
        width: 100%;
        background: #ccc;
      }
    }

    .aside-other-tips {
      display: flex;
      //justify-content: center;
      @include media-breakpoint-down(sm) {
        display: block;
      }

      .tips {
        flex: 0 1 33%;
        font-size: 1.4rem;
        background: #eee;
        //border: solid 1px #ccc;
        margin: 0 10px;
        padding: 10px;
        min-height: 140px;
        min-width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url(/statics/images/everybody-teachers/question_bg.png) center top / 100% 100% no-repeat;
        @include media-breakpoint-down(sm) {
          margin-bottom: 20px;
        }
      }
    }

  }

}

@keyframes noteAnswerContent {
  0% {
    height: 0;
    transform: scale(1,0);
    opacity: 0;
  }
  100% {
    height: 100%;
    transform: scale(1,1);
    opacity: 1;
  }
}



.progress-wrapper {
  position: relative;
  margin-top: 10px;
  background: url("/statics/images/mypage/course_progress_bg.svg") no-repeat center 0;
  background-size: 100% auto;
  padding: 76px 10px 0;
  min-height: 114px;
  overflow: hidden;
  @include media-breakpoint-down(sm) {
    padding: 30vw 10px 10vw;
    margin-bottom: 0;
  }

  .progress-char {
    position: absolute;
    z-index: 3;
    bottom: 32px;
    margin-left: -10px;
    @include media-breakpoint-down(sm) {
      bottom: 12vw;
    }
  }

  .progress-board {
    position: absolute;
    z-index: 2;
    bottom: 30px;
    left: 0;
    width: 100%;
    padding: 0 10px;
    @include media-breakpoint-down(sm) {
      bottom: 12vw;
    }
  }

  .progress-bar {
    position: relative;
    z-index: 1;
  }

  .progress {
    position: relative;
    margin: 0 20px;
    height: 15px;
    overflow: hidden;
  }
}


.finished-wrapper {
  overflow: hidden;
  position: relative;
  margin-top: 10px;
  border-radius: 20px;
  background: #eee;
  

  padding: 10px;
  min-height: 114px;
  @include media-breakpoint-down(sm) {
    padding: 30vw 10px 10vw;
    margin-bottom: 0;
  }

  .finished-board {
    display: flex;
    align-items: center;

    .message {
      border-radius: 20px;
      padding: 10px 0 10px 10px;
      font-size: 1.4rem;
    }
  }

}

.icon_homework {
  position: absolute;
  top: 10px;
  right: -28px;
  background: #ff1c4b;
  color: #fff;
  border-radius: 3px;
  padding: 3px;
  font-size: 12px;
  transform: rotate(45deg);
  min-width: 100px;
  text-align: center;
}

.course-group-mini {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  @include media-breakpoint-down(md) {
    justify-content: space-between;
  }
  @include media-breakpoint-down(sm) {
    display: block;
  }

  .course-mini {
    flex: 0 1 32%;
    margin: 5px;
    border-radius: 10px;
    border: solid 10px rgba(255,255,255,.2);
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    a {
      padding: 10px;
      display: block;
      text-decoration: none;
      color: #fff;
      background: rgba(255, 255, 255, .2);
      border: solid 1px rgba(255,255,255,.8);
      &:hover {
        text-decoration: none;
        opacity: .8;
      }
    }

    .course-mini-title {
      height: 3em;
      overflow: hidden;
    }

    .title-tip {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      > span {
        background: transparent;
        border: solid 1px #fff;
      }
      .heart {
        display: inline-block;
        border-radius: 20px;
        padding: 2px 10px;
        background: rgba(255,255,255,.1);
        line-height: 1;
        .count {
          font-size: 12px;
          font-weight: bold;
          color: #fff;
        }
      }
    }

  }
}


.category-undefined {
  background: #666 !important;
}
.category-kokugo {
  background: #EA4828 !important;
}
.category-syakai {
  background: #EF6A6A !important;
}
.category-sansu {
  background: #FF7F00 !important;
}
.category-rika {
  background: #76C100 !important;
}
.category-ongaku {
  background: #00CC66 !important;
}
.category-zukou {
  background: #00D1C1 !important;
}
.category-kateika {
  background: #0077ED !important;
}
.category-taiiku {
  background: #2635F7 !important;
}
.category-gaikokugo {
  background: #7229FF !important;
}
.category-pc {
  background: #BC1AFF !important;
}
.category-misc {
  background: #E500E5 !important;
}

.category-oshigoto {
  background: #CD853F !important;
}

.category-color-undefined {
  color: #666 !important;
}
.category-color-kokugo {
  color: #EA4828 !important;
}
.category-color-syakai {
  color: #EF6A6A !important;
}
.category-color-sansu {
  color: #FF7F00 !important;
}
.category-color-rika {
  color: #76C100 !important;
}
.category-color-ongaku {
  color: #00CC66 !important;
}
.category-color-zukou {
  color: #00D1C1 !important;
}
.category-color-kateika {
  color: #0077ED !important;
}
.category-color-taiiku {
  color: #2635F7 !important;
}
.category-color-gaikokugo {
  color: #7229FF !important;
}
.category-color-pc {
  color: #BC1AFF !important;
}
.category-color-misc {
  color: #E500E5 !important;
}
.category-color-oshigoto {
  color: #CD853F !important;
}


.teacher-list-wrap {
  margin-bottom: 20px;
}
.teacher-list {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;
  width: 100%;
}
.teacher-list-name {
  display: inline-block;
  border: solid 2px #fff;
  border-radius: 3px;
  padding: 10px 40px;
  margin: 0 0 5px;
  background: rgba(6, 75, 96, .9);
  color: #fff;
  font-size: 1.6rem;
}

.teacher {
  flex: 0 1 32%;
  background: rgba(255,255,255,.2);
  border-radius: 10px;
  margin: 5px;
  @include media-breakpoint-down(md) {
    flex: 0 1 47%;
  }
  .icon-teacher {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    color: #000;
    &:hover {
      text-decoration: none;
      background: rgba(255,255,255,.4);
    }
  }
  .photo {
    position: relative;
    width: 60px;
    height: 60px;
    overflow: hidden;
    margin-bottom: 5px;
    flex: 0 1 60px;
    border-radius: 100px;
    @include media-breakpoint-down(md) {
      width: 40px;
      height: 40px;
      flex: 0 1 40px;
    }
    > img {
      position: absolute;
      width: auto;
      height: auto;
      top: 50%;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      transform: translateX(-50%) translateY(-50%);
      background: #eee;
      @supports ( object-fit: cover ) {
        position: static;
        height: 100%;
        width: 100%;
        left: 0;
        object-fit: cover;
        top: 0;
        transform: none;
        border-radius: 100px;
        background: #eee;
      }
    }
  }
  .name {
    font-size: 14px;
    font-weight: bold;
    padding-left: 10px;
    flex: 1 0 0;
  }
}


.teacher-l-list {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.teacher-l-section {
  flex: 0 1 25%;
  margin-bottom: 20px;
  @include media-breakpoint-down(md) {
    flex: 0 1 49%;
    max-width: 50%;
  }
}
.teacher-l {
  max-width: 160px;
  margin: 0 auto;
  @include media-breakpoint-down(md) {
    max-width: 100%;
    margin: 0 5px;
  }

  &__icon {
    min-width: 5em;
  }
  &__name {
    font-weight: bold;
    font-size: 1.6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__image {
    border-radius: 20px;
    background: #fff;
    img {
      width: 160px;
      //height: 160px;
      border-radius: 20px;
    }
  }
  &__profile {
    font-size: 1.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;

    &.all-text {
      overflow: visible;
      text-overflow: initial;
      white-space: normal;
    }
  }
}


.mod-course-title {
  display: flex;
  align-items: center;
  &__icon {}
  &__name {
    position: relative;
    color: #fff;
    font-size: 1.6rem;
    background: rgba(0, 0, 0, .5);
    border-radius: 20px;
    width: 100%;
    padding: 10px 80px 10px 20px;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 10px;
      display: block;
      width: 70px;
      height: 55px;
      background: url("/statics/images/icon/icon_ttl_bg.svg") no-repeat 0 0;
      background-size: 100%;
    }
  }
}


.course-index-wrapper {
  margin-bottom: 20px;
  .more {
    margin-top: 10px;
    text-align: right;
  }
  .btn-more-l {
    position: relative;
    display: inline-block;
    background: #fff;
    color: #F66901;
    line-height: 1;
    padding: 8px 15px 8px 30px;
    border-radius: 20px;
    //border: solid 2px #F66901;
    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border: 0;
      border-top: solid 4px #FCB48D;
      border-right: solid 4px #FCB48D;
      transform: rotate(45deg);
      position: absolute;
      top: 50%;
      left: 10px;
      margin-top: -6px;
    }
    &:hover {
      text-decoration: none;
      opacity: 0.8;
    }
  }
}



.gheader {
  position: relative;
  z-index: 10;
  text-align: center;
  //display: flex;
  //justify-content: space-around;
  //align-items: center;
  width: 1200px;
  margin: auto;
  padding: 15px 0;
  min-height: 165px;
  @include media-breakpoint-down(md) {
    width: auto;
    display: block;
    padding: 60px 0 15px;
  }
  &__logo {
    margin: 0;
    @include media-breakpoint-down(md) {
      margin-bottom: 2vh;
    }
    img {
      width: 312px;
      @include media-breakpoint-down(md) {
        width: 300px;
      }
      @include media-breakpoint-down(sm) {
        width: 200px;
      }
    }
  }
  &__about-box {
    position: absolute;
    top: 60px;
    left: 0;
    @include media-breakpoint-down(md) {
      position: relative;
      top: 0;
      left: 0;
    }
  }
  &__login-box {
    position: absolute;
    top: 60px;
    right: 0;
    @include media-breakpoint-down(md) {
      top: 0;
      right: 0;
    }
    &.status-logon {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
  &__login-box-inner {
    display: flex;
    align-items: center;
  }
  &__logout {
    margin-left: 20px;
  }
  .mini-prof {
    display: flex;
    align-items: center;
    &__icon {
      background: #fff;
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 50px;
      margin: 5px;
      @include media-breakpoint-down(md) {
        width: 32px;
        height: 32px;
        overflow: hidden;
      }
      img {
        width: 40px;
        @include media-breakpoint-down(md) {
          width: 32px;
        }
      }
    }
    &__name {
      color: #333;
      text-shadow: 1px 1px 0 #fff;
      margin: 5px;
    }
  }
}


.header-wave {
  position: absolute;
  z-index: -1;
  margin-top: 0;
  height: 180px;
  top: 0;
  width: 100%;

  min-width: 1200px;
  @include media-breakpoint-down(md) {
    min-width: auto;
  }

  @include media-breakpoint-down(md) {
    height: 240px;
  }
  @include media-breakpoint-down(sm) {
    height: 200px;
  }
  .cloud1 {
    position: absolute;
    top: 10px;
    left: 10vw;
    animation: cloud1 5s infinite;
  }
  .cloud2 {
    position: absolute;
    top: 30px;
    right: 10vw;
    animation: cloud2 8s infinite;
  }
  .wave1 {
    position: absolute;
    bottom: 0;
    background: url("/statics/images/bg_wave_1@2x.png") repeat-x center bottom;
    background-size: auto 50%;
    width: 100%;
    height: 100px;
    animation: wave1 5s infinite;
  }
  .wave2 {
    position: absolute;
    bottom: 30px;
    background: url("/statics/images/bg_wave_2@2x.png") repeat-x center bottom;
    background-size: auto 50%;
    width: 100%;
    height: 100px;
    animation: wave1 6s infinite;
  }
  .wave3 {
    position: absolute;
    bottom: 50px;
    background: url("/statics/images/bg_wave_3@2x.png") repeat-x center bottom;
    background-size: auto 50%;
    width: 100%;
    height: 100px;
    animation: wave1 7.5s infinite;
  }
  .wave4 {
    position: absolute;
    bottom: 65px;
    background: url("/statics/images/bg_wave_4@2x.png") repeat-x center bottom;
    background-size: auto 50%;
    width: 100%;
    height: 100px;
    animation: wave1 9.5s infinite;
  }
}



.gfooter {
  text-align: center;
  background: url("/statics/images/footer_bg@2x.png") no-repeat center top, linear-gradient(#0083bf 265px, #FFFEEA 265px);
  background-size: 100% 265px, 100% 100%;
  width: 100%;
  @include media-breakpoint-down(md) {
    min-width: auto;
    background-size: auto 265px, 100% 100%;
  }
  &__img {
    background: url("/statics/images/footer_img@2x.png") no-repeat center bottom;
    background-size: 921px auto;
    height: 265px;
    @include media-breakpoint-down(md) {
      background-size: 90% auto;
      height: 200px;
    }
  }
  &__inner {
    max-width: 1200px;
    margin: auto;
    text-align: left;
    padding: 20px 0 0;
    @include media-breakpoint-down(md) {
      width: auto;
    }
  }
  .nav-list {
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    line-height: 2;
    @include media-breakpoint-down(md) {
      display: block;
      padding: 0 10px;
    }
    h4.ttl {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .nav-section {
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }

    &__inner {
      display: flex;
      @include media-breakpoint-down(md) {
        display: block;
      }
      ul {
        margin-right: 20px;
      }
      li {
        position: relative;
        //display: inline-block;
        padding-left: 12px;
        &::before {
          content: '';
          width: 6px;
          height: 6px;
          border: 0;
          border-top: solid 2px #5bc0de;
          border-right: solid 2px #5bc0de;
          transform: rotate(45deg);
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -2px;
        }
      }
    }
  }
  .copyright {
    padding: 30px;
    font-size: 14px;
  }
  ul,li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}




.content-wrapper {
  background: #0083bf;
  padding-bottom: 100px;
  @include media-breakpoint-down(sm) {
    overflow: hidden;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: auto;
    @include media-breakpoint-down(md) {
      width: auto;
      display: block;
    }
  }
}


.main-content {
  width: 840px;
  flex: 0 0 auto;
  @include media-breakpoint-down(md) {
    width: auto;
    padding: 0 10px;
  }
  .about {
    height: 430px;
    background: url("/statics/images/home_bg_info.svg") no-repeat center 0;
    background-size: 100% auto;
    @include media-breakpoint-down(md) {
      height: 45vw;
    }
    &__message {
      padding: 110px 0 0 0;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #333;
      text-shadow: 1px 1px 3px #fff;
      @include media-breakpoint-down(md) {
        padding: 10vw;
        @include fz_vw(8);
      }
    }
  }
}
.side-content {
  width: 300px;
  flex: 0 0 auto;
  @include media-breakpoint-down(md) {
    width: auto;
    padding: 10px;
    border-radius: 5px;
    background: rgba(255,255,255,.2);
    margin: 40px 10px 10px;
  }
}


.single-content {
  width: 845px;
  margin: auto;
  @include media-breakpoint-down(md) {
    width: auto;
    padding: 0 10px;
  }
}

.max-content {
  
}

.sp-header {
  display: none;
  padding: 5px 10px;
  @include media-breakpoint-down(md) {
    display: block;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(255,255,255,.9);
    font-size: 1.2rem;
    box-shadow: 0 0 3px rgba(0,0,0,.1);
  }
  &.status-logout {
    display: block;
    text-align: right;
  }
  .hamburger-menu {
    cursor: pointer;
    user-select: none;
    text-align: left;
    padding: 5px;
    border-radius: 5px;
    background: #fff;
    color: #F66900;
    border: solid 1px #F66900;
    
    .off {
      display: none;
    }
    
    &.blue {
      .on {
        display: none;
      }
      .off {
        display: block;
      }
    }
  }
  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__sub {
    background: #fff;
    padding: 10px;
    transition: all 0.5s;
    opacity: 0;
    position: absolute;
    top: -2000px;
    left: 0;
    width: 100%;
    &.show {
      opacity: 1;
      top: 52px;
    }

    .sp-nav-list {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      margin: 0;
      padding: 0;
      list-style: none;
      > li {
        display: block;
        flex: 0 1 49%;
        margin-bottom: 1rem;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 4em;
          background: #F56900;
          border-radius: 3px;
          color: #fff;
          font-size: 1.4rem;
        }
      }
    }
  }
  .money-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .profile__money {
    flex: 0 1 49%;
    position: relative;
    background: #FDF873;
    border-radius: 3px;
    padding: 5px;
    margin-bottom: 10px;

    .profile__money-inner {
      position: relative;
      max-width: 200px;
      margin: auto;
    }

    .icon {
      display: block;
      position: absolute;
      bottom: 0;
      left: 4px;
      img {
      }
    }
    .gold-holder,
    .ticket-holder {
      display: block;
      text-align: right;
    }
  }
  .mini-prof__icon {
    box-shadow: 0 0 3px rgba(0,0,0,.3);
  }
}



.mod-student-monsters {

}



.mod_news {
  position: relative;
  background: #fffac1;
  padding: 20px;
  margin-bottom: 20px;
  @include media-breakpoint-down(md) {
    padding: 15px 10px 5px 10px;
    font-size: 1.4rem;
  }
  &__ttl {
    position: absolute;
    top: -20px;
    left: -15px;
    transform: rotate(-10deg);
    @include media-breakpoint-down(md) {
      top: -25px;
      left: 0;
    }
  }
  &__detail {
    //margin-bottom: 20px;
  }
  &__stars {
    display: flex;
    align-items: center;
    > div {
      padding: 0 10px;
    }
  }
}





.mod_ttl_course-clear {
  position: relative;
  background: url(/statics/images/ttl_course_clear.svg) no-repeat center 0;
  background-size: 100% auto;
  padding: 100px 50px 0 50px;
  min-height: 150px;
  margin-bottom: 30px;
  @include media-breakpoint-down(md) {
    padding: 8vw 10vw 10vw;
    min-height: auto;
  }
  .title {
    font-weight: bold;
    font-size: 2.4rem;
    @include media-breakpoint-down(md) {
      position: absolute;
      left: 5vw;
      bottom: 2vw;
      @include fz_vw(12);
    }
  }
}
.mod_ttl_item-shop {
  position: relative;
  background: url(/statics/images/ttl_item_shop.svg) no-repeat center 0;
  background-size: 100% auto;
  padding: 150px 50px 0 50px;
  min-height: 210px;
  margin-bottom: 30px;
  @include media-breakpoint-down(md) {
    padding: 14vw 10vw 10vw;
    min-height: auto;
  }
  .title {
    font-weight: bold;
    font-size: 2.4rem;
    @include media-breakpoint-down(md) {
      position: absolute;
      left: 5vw;
      bottom: 2vw;
      @include fz_vw(12);
    }
  }
}
.mod_ttl_my-character {
  position: relative;
  background: url(/statics/images/ttl_my_character.svg) no-repeat center 0;
  background-size: 100% auto;
  padding: 100px 50px 0 50px;
  min-height: 170px;
  margin-bottom: 30px;
  @include media-breakpoint-down(md) {
    padding: 9vw 10vw 10vw;
    min-height: auto;
  }
  .title {
    font-weight: bold;
    font-size: 2.4rem;
    @include media-breakpoint-down(md) {
      position: absolute;
      left: 5vw;
      bottom: 2vw;
      @include fz_vw(12);
    }
  }
}
.mod_ttl_friend-list {
  position: relative;
  background: url(/statics/images/ttl_friend_list.svg) no-repeat center 0;
  background-size: 100% auto;
  padding: 100px 50px 0 50px;
  min-height: 170px;
  margin-bottom: 30px;
  @include media-breakpoint-down(md) {
    padding: 9vw 10vw 10vw;
    min-height: auto;
  }
  .title {
    font-weight: bold;
    font-size: 2.4rem;
    @include media-breakpoint-down(md) {
      position: absolute;
      left: 5vw;
      bottom: 2vw;
      @include fz_vw(12);
    }
  }
}
.mod_ttl_teacher {
  position: relative;
  background: url(/statics/images/ttl_teacher.svg) no-repeat center 0;
  background-size: 100% auto;
  padding: 125px 50px 0 50px;
  min-height: 180px;
  margin-bottom: 30px;
  @include media-breakpoint-down(md) {
    padding: 10vw 10vw 10vw;
    min-height: auto;
  }
  &.hasIcon {
    padding: 115px 50px 0 50px;
    @include media-breakpoint-down(md) {
      padding: 13vw 10vw 0 5vw;
      min-height: auto;
      margin-bottom: 15px;
    }
  }
  .title {
    font-weight: bold;
    font-size: 2.4rem;
    margin: 0;
    @include media-breakpoint-down(md) {
      position: absolute;
      left: 5vw;
      bottom: 2vw;
      @include fz_vw(12);
    }
  }
  
  .title-wrapper {
    display: flex;
    align-items: center;
    .icon {
      line-height: 1;
      margin-right: 10px;
      img {
        vertical-align: top;
        border: solid 1px #34251C;
        @include media-breakpoint-down(md) {
          width: 15px;
        }
      }
    }
    .title {
      @include media-breakpoint-down(md) {
        position: relative;
        left: 0;
        bottom: 2vw;
        @include fz_vw(12);
      }
    }
  }

}
.mod_ttl_simple {
  position: relative;
  padding: 10px;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 20px;
  @include media-breakpoint-down(md) {
    padding: 4vw 10vw;
    min-height: auto;
  }
  .title {
    font-weight: normal;
    font-size: 1.6rem;
    margin: 0;
    @include media-breakpoint-down(md) {
      @include fz_vw(14);
    }
  }
  .center{
    text-align: center;
  }
}

.mod_ttl_monsters {
  position: relative;
  background: url(/statics/images/ttl_monsters.svg) no-repeat center 0;
  background-size: 100% auto;
  padding: 100px 50px 0 50px;
  min-height: 170px;
  margin-bottom: 30px;
  @include media-breakpoint-down(md) {
    padding: 9vw 10vw 10vw;
    min-height: auto;
  }
  .title {
    font-weight: bold;
    font-size: 2.4rem;
    @include media-breakpoint-down(md) {
      position: absolute;
      left: 5vw;
      bottom: 2vw;
      @include fz_vw(12);
    }
  }
}
.mod_ttl_island_build {
  height: 192px;
  background: url("/statics/images/ttl_island_build.svg") no-repeat center 0;
  background-size: 100% auto;
  margin-bottom: 40px;
  @include media-breakpoint-down(md) {
    height: 23vw;
    margin-bottom: 20px;
  }
  .title {
    padding: 132px 0 0 80px;
    text-align: left;
    font-size: 3rem;
    font-weight: bold;
    color: #333;
    text-shadow: 1px 1px 3px #fff;
    @include media-breakpoint-down(md) {
      padding: 15vw 4vw 4vw;
      @include fz_vw(12);
    }
  }
}


// --------------------------------------------------
// form
// --------------------------------------------------


.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}


.mod_student-course-wrapper {
  display: flex;
  //justify-content: space-between;
  flex-flow: row wrap;

  &.only-stutends {
    padding: 2% 2% 0;
  }

  .more {
    margin-bottom: 30px;
  }
}

.mod_student-course {
  flex: 0 1 32%;
  border: solid 5px #fff;
  border-radius: 20px;
  margin-bottom: 2%;
  overflow: hidden;
  width: 100%;
  position: relative;
  @include media-breakpoint-down(md) {
    flex: 0 1 100%;
  }

  // flexbox均等の対応(space-between使わない）
  &:nth-child(3n+1) {
    margin-right: 6px;
  }
  &:nth-child(3n+2) {
    margin-right: 6px;
    margin-left: 6px;
  }
  &:nth-child(3n+3) {
    margin-left: 6px;
  }


  &.complete {
    background: #FFFE03 url("/statics/images/monsters_complete_bg.svg") no-repeat center 100%;
    background-size: 170% auto;
  }
  &__category {
    position: absolute;
    z-index: 10;
    top: 10px;
    left: 10px;
    padding: 3px 10px;
    font-size: 1.0rem;
    border: solid 1px #fff;
    color: #fff;
    line-height: 1;
  }
  &__image {
    position: relative;
    min-height: 150px;
    text-align: center;
    padding-top: 10px;
    > img {
      position: absolute;
      bottom: 50px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    &.very_easy {
      background: url("/statics/images/monsters_bg_1.svg") no-repeat center bottom;
      background-size: 100% auto;
    }
    &.easy {
      background: url("/statics/images/monsters_bg_2.svg") no-repeat center bottom;
      background-size: 100% auto;
    }
    &.normal {
      background: url("/statics/images/monsters_bg_3.svg") no-repeat center bottom;
      background-size: 100% auto;
    }
    &.hard {
      background: url("/statics/images/monsters_bg_4.svg") no-repeat center bottom;
      background-size: 100% auto;
    }
    &.very_hard {
      background: url("/statics/images/monsters_bg_5.svg") no-repeat center bottom;
      background-size: 100% auto;
    }
  }
  &__monster-name {
    color: #fff;
    background: #000;
    padding: 5px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__inner {
    padding: 5px;
    &.very_easy {
      background: #3DCE9D;
    }
    &.easy {
      background: #49C3DE;
    }
    &.normal {
      background: #6971CC;
    }
    &.hard {
      background: #C891DB;
    }
    &.very_hard {
      background: #E88492;
    }
  }
  &__title {
    > a {
      display: block;
      padding: 10px;
      background: #fff;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        color: #fff;
        background: #F56900;
        text-decoration: none;
      }
    }
  }
}


.stars {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  padding: 5px;
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    flex: 1 0 0;
    > li {
      flex: 0 1 20%;
    }
  }
  &__large {
    flex: 0 1 28%;
    text-align: center;
  }

}


.stars-circle {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 30px;
      height: 30px;
      &:nth-child(1) {
        bottom: 60%;
        left: 45%;
      }
      &:nth-child(2) {
        bottom: 23%;
        left: 70%;
      }
      &:nth-child(3) {
        top: 23%;
        left: 70%;
      }
      &:nth-child(4) {
        top: 60%;
        left: 45%;
      }
      &:nth-child(5) {
        top: auto;
        bottom: 5%;
      }
      &:nth-child(6) {
        top: 60%;
        right: 45%;
      }
      &:nth-child(7) {
        top: 23%;
        right: 70%;
      }
      &:nth-child(8) {
        bottom: 23%;
        right: 70%;
      }
      &:nth-child(9) {
        bottom: 60%;
        right: 45%;
      }
      &:nth-child(10) {
        top: 5%;
        bottom: auto;
      }
    }
  }
  &__large {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 120px;
    height: 120px;
  }
}

.stars-line {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
      width: 30px;
      height: 30px;
    }
  }
  &__large {
  }
}




.bg-black30 {
  background: rgba(0,0,0,.3);
}

.mod_heart {
  display: inline-block;
  border-radius: 20px;
  padding: 2px 10px;
  background: rgba(255,255,255,.1);
  line-height: 1;
  &.bg-black {
    background: rgba(0,0,0,.5);
  }
  .count {
    font-size: 12px;
    font-weight: bold;
    color: #fff;
  }
}

.has-pointer {
  cursor: pointer;
}


.loading {
  animation: loading 1s;
  &__bg {
    background: url("/statics/images/loading.svg") no-repeat center center;
    background-size: 100%;
    width: 200px;
    height: 200px;
    margin: auto;
    animation: loadingBg 1.5s linear infinite;
  }
  &__txt {
    font-weight: bold;
    text-align: center;
    font-size: 2rem;
    color: #fff;
  }
}



.news {
  position: relative;
  background: #fffac1;
  padding: 20px 20px 5px 20px;
  margin-bottom: 20px;
  @include media-breakpoint-down(md) {
    margin-top: 30px;
    padding: 15px 10px 5px 10px;
    font-size: 1.4rem;
  }
  &.news-index {
    padding: 2em 20px 2em 20px;
  }
  &__ttl {
    position: absolute;
    top: -20px;
    left: -15px;
    transform: rotate(-10deg);
    @include media-breakpoint-down(md) {
      top: -25px;
      left: 0;
    }
  }
  &__list {
    margin: 0;
    padding: 0;
    > li {
      display: flex;
      margin-bottom: 5px;
      @include media-breakpoint-down(md) {
        align-items: center;
      }
    }
    .icon {
      flex: 0 1 220px;
      padding: 2px;
      border-radius: 30px;
      text-align: center;
      color: #fff;
      margin-right: 10px;
      font-weight: bold;
      &.for_teacher {
        background: #7a7a7a;
      }
      &.for_parent {
        background: #ec722f;
      }
      &.for_student {
        background: #0040f5;
      }
      &.for_hiroba {
        background: #00c124;
      }
    }
    .detail {
      display: flex;
      padding: 2px 10px;
      border-radius: 3px;
      background: #f3f6be;
      width: 100%;
      font-weight: bold;
      @include media-breakpoint-down(md) {
        display: block;
      }
    }
    .date {
      margin-right: 10px;
      white-space: nowrap;
    }
  }
  &__more {
    text-align: right;
  }
  .btn-more {
    position: relative;
    display: inline-block;
    padding-left: 16px;
    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border: 0;
      border-top: solid 4px #5bc0de;
      border-right: solid 4px #5bc0de;
      transform: rotate(45deg);
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -4px;
    }
  }

  .article {
    padding: 1em;
    @include media-breakpoint-down(md) {
      padding: 0;
    }
    .title {
      background: rgba(255, 255, 255, .5);
      color: #000;
      font-weight: bold;
      padding: 10px;
      font-size: 2rem;
      border-radius: 5px;
      margin-bottom: 1em;
    }
    .social_links {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .social_item {
        padding: 3px;
        width: 80px;
        display: flex;
        align-items: center;
      }
    }
    .body {
      padding-top: 2em;
    }
  }
}


.news-detail {
  position: relative;
  margin-bottom: 20px;
  @include media-breakpoint-down(md) {
    margin-top: 30px;
    font-size: 1.4rem;
  }
  &.news-index {
    padding: 2em 20px 2em 20px;
  }
  &__ttl {
    position: absolute;
    top: -20px;
    left: -15px;
    transform: rotate(-10deg);
    @include media-breakpoint-down(md) {
      top: -25px;
      left: 0;
    }
  }
  &__list {
    margin: 0;
    padding: 0;
    > li {
      display: flex;
      margin-bottom: 5px;
      @include media-breakpoint-down(md) {
        align-items: center;
      }
    }
    .icon {
      flex: 0 1 220px;
      padding: 2px;
      border-radius: 30px;
      text-align: center;
      color: #fff;
      margin-right: 10px;
      font-weight: bold;
      &.for_teacher {
        background: #7a7a7a;
      }
      &.for_parent {
        background: #ec722f;
      }
      &.for_student {
        background: #0040f5;
      }
      &.for_hiroba {
        background: #00c124;
      }
    }
    .detail {
      display: flex;
      padding: 2px 10px;
      border-radius: 3px;
      width: 100%;
      font-weight: bold;
      @include media-breakpoint-down(md) {
        display: block;
      }
    }
    .date {
      margin-right: 10px;
      font-weight: normal;
      font-size: 1.4rem;
    }
  }
  &__more {
    text-align: right;
  }
  .btn-more {
    position: relative;
    display: inline-block;
    padding-left: 16px;
    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border: 0;
      border-top: solid 4px #5bc0de;
      border-right: solid 4px #5bc0de;
      transform: rotate(45deg);
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -4px;
    }
  }

  &__header {
    background: #fff;
    border-radius: 20px;
    padding: 20px 20px 5px 20px;
    margin-bottom: 20px;

    .social_links {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .social_item {
        padding: 3px;
        width: 80px;
        display: flex;
        align-items: center;
      }
    }
  }

  .article {
    background: #fffac1;
    //padding: 20px 20px 5px 20px;
    padding: 1em;
    @include media-breakpoint-down(md) {
      //padding: 0;
    }
    .title {
      background: rgba(255, 255, 255, .5);
      color: #000;
      font-weight: bold;
      padding: 10px;
      font-size: 2rem;
      border-radius: 5px;
      margin-bottom: 1em;
    }
    .body {
      padding-top: 2em;
    }
  }
}

.news-profile {
  background: #fff;
  margin-bottom: 20px;
  border-radius: 10px;
  @include media-breakpoint-down(md) {
    margin-top: 30px;
    font-size: 1.4rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: #fff;
    background: #F15A24;
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    @include media-breakpoint-down(md) {
      display: block;
      padding: 10px;
    }
    .name-wrapper {
      font-size: 1.8rem;
      @include media-breakpoint-down(md) {
        font-size: 1.6rem;
        margin-bottom: 10px;
      }
      .group-name {
        font-weight: bold;
        margin-right: 1em;
        @include media-breakpoint-down(md) {
          display: block;
        }
      }
    }
    .sns-list {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      @include media-breakpoint-down(md) {
        justify-content: flex-end;
      }
      > li {
        margin-left: 5px;
      }
    }
  }

  &__profile-list {
    display: flex;
    font-size: 1.6rem;
    border-radius: 10px;
    padding: 0;
    margin: 0 0 10px;
    dt,dd {
      margin: 0;
    }
    dt {
      min-width: 100px;
    }
  }
  &__profile-detail {
    padding: 20px;
    margin-bottom: 10px;
    @include media-breakpoint-down(md) {
      padding: 10px;
    }
  }
  &__prefecture {
    display: flex;
    align-items: center;
    padding: 0 20px 20px;
    @include media-breakpoint-down(md) {
      padding: 0 10px 10px;
    }
    .icon {
      margin-right: 1em;
    }
  }
}

.news-about {
  color: #fff;
  background: #2C8A38;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  @include media-breakpoint-down(md) {
    margin-top: 30px;
    padding: 10px;
    font-size: 1.4rem;
  }

  &__title {
    font-size: 1.8rem;
    font-weight: bold;
    margin: 0 0 10px;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    @include media-breakpoint-down(md) {
      display: block;
    }
  }
  &__image {
    padding-left: 20px;
    @include media-breakpoint-down(md) {
      padding-left: 0;
      text-align: center;
    }
  }
}

.news-editor {
  background: #D7EEF4;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  @include media-breakpoint-down(md) {
    margin-top: 30px;
    padding: 10px;
  }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .editor-title {
      display: inline-block;
      border-radius: 20px;
      padding: 4px 20px;
      line-height: 1;
      background: #F05A24;
      font-size: 1.4rem;
      color: #fff;
      margin-right: 1em;
      @include media-breakpoint-down(md) {
        font-size: 1.2rem;
      }
    }
    .editor-name {
      font-size: 1.8rem;
      margin-right: 1em;
      @include media-breakpoint-down(md) {
        font-size: 1.4rem;
      }
    }
  }
  &__detail {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(md) {
      display: block;
    }
    .detail-icon {
      flex: 0 0 auto;
      margin-right: 1em;
      @include media-breakpoint-down(md) {
        text-align: center;
        margin-bottom: 10px;
      }
      .image {
        display: inline-block;
        text-align: center;
        border-radius: 50%;
        border: solid 4px #808080;
        overflow: hidden;
        img {
          width: 120px;
        }
      }
    }
    .detail-message {
      flex: 0 1 auto;
      @include media-breakpoint-down(md) {
        font-size: 1.4rem;
      }
    }
  }
}

.news-pager {
  display: flex;
  justify-content: center;
  margin: 0 0 1em;
  padding: 0;
  list-style: none;
  li {
    margin: 1px;
  }
  a {
    display: block;
    color: #F66901;
    font-weight: bold;
    background: #fff;
    padding: 10px 20px;
    &:hover {
      text-decoration: none;
      background: rgba(255,255,255,.5);
    }
  }
  span {
    display: block;
    color: #fff;
    font-weight: bold;
    background: #ccc;
    padding: 10px 20px;
  }
  .prev {
    a,
    span {
      border-radius: 10px 0 0 10px;
    }
  }
  .next {
    a,
    span {
      border-radius: 0 10px 10px 0;
    }
  }
}

.open-security_report-modal {
  cursor: pointer;
}


.mod_alert {
  margin-bottom: 30px;
  @include media-breakpoint-down(md) {
    margin-bottom: 15px;
  }
  &.alert-danger {
    background: transparent;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(md) {
      display: block;
    }
    .primary {
      padding-right: 20px;
      @include media-breakpoint-down(md) {
        padding-right: 0;
        margin-bottom: 10px;
      }
    }
    .secondary {
      width: 100%;
      border: solid 4px #F00;
      border-radius: 20px;
      background: #FFE1E7;
      padding: 20px;
      .inner {
        display: flex;
        align-items: center;
        @include media-breakpoint-down(md) {
          display: block;
        }
        .icon {
          @include media-breakpoint-down(md) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}


.mod_recommend_start {
  display: flex;
  justify-content: center;
  align-items: center;
  .primary {
    padding-right: 20px;
    .monster-icon {
      animation: bossMonster 10s linear infinite;
    }
  }
  .secondary {
    display: flex;
    justify-content: center;
    align-items: center;
    .inner {
      background: #3DCE9C;
      min-width: 300px;
      padding: 20px;
      border-radius: 20px;
      text-align: center;
      @include media-breakpoint-down(md) {
        min-width: initial;
      }
    }
    .message {
      margin-bottom: 20px;
      color: #fff;
    }
  }
}

.ticket-box {
  background: #FDF873;
  max-width: 320px;
  margin: 0 auto 20px;
  padding: 10px;
  font-size: 1.6rem;
  text-align: center;
  border-radius: 50px;
  box-shadow: inset 0 0 3px rgba(0,0,0,.2);

  .ticket-title {
    font-size: 1.4rem;
    font-weight: bold;
  }
  .ticket-count {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    .icon {
      margin-right: 1em;
    }
    .count {
      color: #333;
      font-size: 3rem;
      font-weight: bold;
      text-shadow: -1px -1px 1px rgba(0, 0, 0, .7),
      1px 1px 1px rgba(255, 255, 255, .7);
    }
  }
}
#page-course-exam {

  .header-wave {
    height: 200px;
  }

  .side-nav {
    position: absolute;
    top: 0;
    right: 0;
    width: 320px;
    background: #fff;
    border-radius: 3px;
    .nav {
      .nav-link {
        color: #999;
        border-bottom: solid 2px #e5e5e5;
        padding: 1rem;
        background: rgba(255,255,255,.055);
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  .main-container {
    margin: auto;
    width: 840px;
    @include media-breakpoint-down(md) {
      width: 100%;
      padding: 0 3%;
    }
  }
  .main-content {
    min-height: 400px;
    width: 840px;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }



  .article {
    &__title-wrapper {
      margin-bottom: 20px;
    }
    &__before-title {
      font-weight: bold;
      font-size: 18px;
      margin-top: 20px;
    }
    &__title-inner {
      //width: 80%;
    }
    &__title-tip {
      display: inline-block;
      padding: 5px 10px;
      box-shadow: 3px 3px 0 #6B5B55;
      color: #fff;
      .name {
        border: solid 1px #fff;
      }
    }
    &__title {
      position: relative;
      //width: 1120px;
      margin: 0 auto;
      padding: 30px 40px;
      border-radius: 20px;
      background-image: url("/statics/images/courses/bg_article_header.png");
      background-repeat: no-repeat;
      background-position: 5px center;
      background-size: 100% 100%;
      min-height: 180px;
    }
    &__body {
      padding: 20px;
      border-radius: 10px;
      background: rgba(255,255,255,.8);
      margin-bottom: 2em;
    }
    &__quest-list {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  .section {
    padding-bottom: 24px;
    &__header {
      border-bottom: solid 1px #e6e6e6;
      margin-bottom: 20px;
    }
    &__title {
      font-size: $h6-font-size;
      font-weight: bold;
      display: inline-block;
      letter-spacing: 0.5rem;
      padding: 5px 10px;
      color: #333;
      margin: 0 0 -1px 0;
      background: linear-gradient(transparent 50%, #f3f3bb 50%);
    }
    &__description {
      //background: #E1D2BE;
    }
  }


  .system-monster {
    padding: 10px;
    border-radius: 10px;
    background: #097293;
    margin-bottom: 1em;
    border: solid 10px #fff;
    display: flex;
    justify-content: space-between;
    position: relative;

    &__inner {
      text-align: center;
      flex: 0 1 800px;
      background: url("/statics/images/island/island_monster.svg") no-repeat center 0;
    }
    &__message {
      background: rgba(6, 75, 96,.9);
      border: solid 5px #fff;
      border-radius: 10px;
      margin: 20px 0 0;
      padding: 10px;
      text-align: left;
      color: #fff;
    }
  }



  .question {
    padding: 10px;
    border-radius: 10px;
    background: #097293;
    margin-bottom: 1em;
    border: solid 10px #fff;
  }

  .question-header {
    margin-bottom: -60px;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    @include media-breakpoint-down(md) {
      display: block;
    }
    &__title {
      color: #fff;
      font-size: 3.6rem;
      font-weight: bold;
      display: flex;
      align-items: center;
      padding-bottom: 60px;
      @include media-breakpoint-down(md) {
        font-size: 2.4rem;
      }
      span {
        font-size: 12rem;
        font-weight: bold;
        padding: 0 50px;
        @include media-breakpoint-down(md) {
          font-size: 6rem;
        }
      }
    }
    &__monster {
      position: relative;
      padding-top: 30px;

      .mini-monsters {
        position: relative;
        .monster {
          padding-bottom: 20px;
        }
        .monster1 {
        }
        .monster2 {
          top: 0;
          left: -60px;
        }
        .monster3 {
          top: 50px;
          left: 60px;
        }
      }

      .monster {
        position: absolute;
        background: url("/statics/images/difficulty/monster_shadow.svg") no-repeat center bottom;
        background-size: 30px auto;
        top: 30px;
        left: 0 ;
        width: 100%;
        text-align: center;
      }
      .island {
        @include media-breakpoint-down(md) {
          text-align: center;
        }
      }
    }
  }

  .question-inner {
    position: relative;
    z-index: 2;
    background: rgba(0,0,0,.64);
    border: solid 5px #fff;
    border-radius: 10px;
    padding: 20px;
    text-align: left;
    color: #fff;
  }

  .question-main {

    .question-user-text {
      margin-bottom: 20px;
      white-space: pre-wrap;
      word-break: break-all;
    }

    .question-user-img {
      margin-bottom: 20px;
    }

  }

  .question-editor {

    textarea.form-textarea {
      border: solid 1px #ccc;
      padding: 3px;
      margin:0 0 20px;
      box-shadow: 0 1px rgba(255, 255, 255, 0.5);
      border-radius: 3px;
      width: 100%;
      font-size:1.6rem;
      min-height: 5em;
    }

    pre.form-pre {
      font-size:1.6rem;
      font-weight: normal;
      color: #fff;
      white-space: pre-wrap;
      word-break: break-all;
    }

  }

  .question-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-down(sm) {
      flex-flow: column wrap;
    }
    .primary {
      @include media-breakpoint-down(sm) {
        order: 1;
        margin-bottom: 10px;
      }
    }
    .secondary {
      @include media-breakpoint-down(sm) {
        order: 2;
      }
    }

    .button-list {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
      @include media-breakpoint-down(sm) {
        display: block;
      }
      > li {
        margin-left: 20px;
        &:first-child {
          @include media-breakpoint-down(sm) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }


  .answer {
    border-radius: 10px;
    background: #097293;
    margin-bottom: 1em;
    border: solid 10px #fff;
  }

  .answer-header {
    padding: 10px;
    background: #fff;
    color: #000;

    .title {
      font-size: 1.8rem;
      font-weight: bold;
    }

    .question-select-list {
      list-style: disc;
      margin: 1em 0;
      padding-left: 40px;
    }

    .text {
      white-space: pre-wrap;
      word-break: break-all;
    }
  }

  .answer-inner {
    padding: 10px;
  }

  .answer-main {
    position: relative;
    z-index: 2;
    background: #009245;
    border: solid 5px #fff;
    border-radius: 10px;
    padding: 20px;
    text-align: left;
    color: #fff;
    margin-bottom: 10px;

    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      display: block;
    }

    .title {
      font-size: 1.8rem;
      font-weight: bold;
    }

    .text {
      white-space: pre-wrap;
      word-break: break-all;
    }
    
    .icon {
      text-align: center;
    }
  }

  .answer-sub {
    position: relative;
    z-index: 2;
    background: #7AF1F4;
    border: solid 5px #fff;
    border-radius: 10px;
    padding: 20px;
    text-align: left;
    color: #000;
    margin-bottom: 10px;

    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      display: block;
    }

    .title {
      font-size: 1.8rem;
      font-weight: bold;
    }

    .icon {
      text-align: center;
    }

    .text_maru {
      color: #ED1C24;
    }
    .text_batsu {
      color: #0010FF;
    }

    pre.form-pre {
      font-weight: normal;
      white-space: pre-wrap;
      word-break: break-all;
    }
  }

  .answer-control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
    .primary {
      flex: 0 1 49%;
    }
    .secondary {
      flex: 0 1 49%;
    }

  }

  .answer-finish {
    display: flex;
    justify-content: center;
  }
  
  
  .result {
    padding: 10px;
    border-radius: 10px;
    background: #009245;
    margin-bottom: 1em;
    border: solid 10px #fff;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  .result-teacher {
    @include media-breakpoint-down(sm) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;
    }
  }

  .result-main {
    color: #fff;
    padding: 0 10px;
    @include media-breakpoint-down(sm) {
      padding: 0;
    }

    .title {
      font-size: 2.4rem;
      margin-bottom: 10px;
      @include media-breakpoint-down(sm) {
        text-align: center;
        margin-bottom: 1em;
      }
    }

    .points {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .icon {
        padding-right: 10px;
      }
      .text {
        font-size: 2.4rem;
        font-weight: bold;
        @include media-breakpoint-down(sm) {
          font-size: 2rem;
        }
      }
    }

    .gold {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .icon {
        padding-right: 10px;
      }
      .text {
        font-size: 2.4rem;
        font-weight: bold;
        @include media-breakpoint-down(sm) {
          font-size: 2rem;
        }
      }
    }

    .letter {
      background: #fff;
      border-radius: 10px;
      color: #000;
      padding: 10px;
      text-align: center;
    }

  }

  .result-movie {
    position: relative;
    min-height: 600px;

    .balloon1 {
      top: 10%;
      left: 20%;
    }
    .balloon2 {
      top: 5%;
      left: 50%;
    }

    .balloon {
      position: absolute;
      z-index: 2;
      width: 173px;
      height: 290px;
      &__up {
        position: absolute;
      }
      &__human {
        position: absolute;
        bottom: 5%;
        left: 0;
        text-align: center;
        width: 100%;
      }
      &__monster {
        position: absolute;
        bottom: 10%;
        left: 0;
        text-align: center;
        width: 100%;
      }
      &__down {
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        width: 100%;
      }
      .boss-monster {
        animation: bossMonster 10s linear infinite;
      }
    }

    .island {
      position: relative;
      z-index: 1;
      padding-top: 400px;
      text-align: center;
      &__monster {
        position: absolute;
        bottom: 15%;
        right: 30%;
        background: url("/statics/images/difficulty/monster_shadow.svg") no-repeat center bottom;
        background-size: 50px auto;
        padding: 0 0 20px;
      }
    }

  }

}
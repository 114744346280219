// --------------------------------------------------
// box modules
// --------------------------------------------------

.mod_box-border {
  border: solid 10px #fff;
  border-radius: 20px;
  margin-bottom: 40px;
  @include media-breakpoint-down(md) {
  }
  @include media-breakpoint-down(sm) {
  }
  &__header {
    background: #fff;
    color: #000;
    font-weight: bold;
    text-align: center;
    padding: 0 10px 10px;
    font-size: 1.4rem;
    .title {
      font-size: 1.6rem;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }
  }
  &__inner {
    padding: 10px;
    border-radius: 10px;
  }
}




.anim-shake {
  animation: shake 1s;
}

.anim-heart-sleep {
  animation: heartSleep 3s;
}

.anim-infinite {
  animation-iteration-count: infinite;
}



.anim-monster-move-1 {
  animation: moveMonster1 10s linear infinite;
}
.anim-monster-move-2 {
  animation: moveMonster1 8s linear infinite;
}
.anim-monster-move-3 {
  animation: moveMonster1 12s linear infinite;
}

.anim-monster-move-boss {
  animation: moveMonsterBoss 10s linear infinite;
}




.aniFadeIn {
  animation: fadeIn 1s;
}



.animSlideIn {
  animation: slideIn 1s;
}
.animSlideOut {
  animation: slideOut 1s;
}


.anim-result-balloon-1 {
  animation: resultBalloon1 5s infinite;
}
.anim-result-balloon-2 {
  animation: resultBalloon2 7s infinite;
}
.anim-result-monster-1 {
  animation: resultMonster1 5s infinite;
}
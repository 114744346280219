// --------------------------------------------------
// list modules
// --------------------------------------------------

.mod_monster-count {
  border: solid 10px #fff;
  border-radius: 20px;
  margin-bottom: 40px;
  background: url("/statics/images/monsters_count_bg.svg") no-repeat center 0;
  background-size: 100% auto;
  @include media-breakpoint-down(md) {
    background: url("/statics/images/monsters_count_bg.svg") no-repeat center 0;
    background-size: 100% auto;
  }
  @include media-breakpoint-down(sm) {
    background: url("/statics/images/monsters_count_bg.svg") no-repeat center 34%;
    background-size: 100% auto;
  }
  &__header {
    background: #fff;
    color: #000;
    font-weight: bold;
    text-align: center;
    padding: 0 10px 10px;
    font-size: 1.4rem;
    .title {
      font-size: 1.6rem;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }
  }
  &__inner {
    padding: 10px;
  }
  &__list {
    padding: 0 80px 60px;
    display: flex;
    justify-content: space-around;
    min-height: 160px;
    @include media-breakpoint-down(md) {
      padding: 0 10vw 10vw;
    }
  }
  &__number {
    border: solid 5px #fff;
    border-radius: 20px;
    padding: 10px;
    margin: 0 80px;
    display: flex;
    justify-content: space-around;
    background: rgba(6, 75, 96, .9);
    color: #fff;
    font-size: 1.6rem;
    @include media-breakpoint-down(md) {
      margin: 0 5vw;
    }
    @include media-breakpoint-down(sm) {
      margin: 0;
      font-size: 1.2rem;
    }
  }
}



.mod_icon-select-box {
  border-radius: 10px;
  background: #097293;
  margin-bottom: 1em;
  border: solid 10px #fff;
  position: relative;
  &__header {
    background: #fff;
    color: #000;
    font-weight: bold;
    text-align: center;
    padding: 0 10px 10px;
    font-size: 1.4rem;
    .title {
      font-size: 1.6rem;
      font-weight: bold;
      margin: 0;
      padding: 0;
      .count {
        display: inline-block;
        border-radius: 20px;
        color: #fff;
        background: rgba(6, 75, 96, .9);
        padding: 2px 10px;
      }
    }
  }
  &__body {
    padding: 10px;
    background: #3D8FA8;
    &.no-bg {
      //background: #0083bf;
    }

    .item-list {
      display: flex;
      justify-content: flex-start;
      flex-flow: row wrap;
      margin: 0;
      padding: 0;
      list-style: none;

      > li {
        flex: 0 1 23%;
        margin: 1%;
        border: solid 1px #fff;
        border-radius: 10px;
        background: rgba(255, 255, 255, .2);
        @include media-breakpoint-down(md) {
          flex: 0 1 100%;
        }
      }

      .item-header {
        background: #fff;
        border-radius: 8px 8px 0 0;
        padding: 4px;
        text-align: center;
        font-size: 1.4rem;

        .item-student-name {
          display: block;
        }

        .item-student-id {
          display: inline-block;
          border-radius: 20px;
          background: #eee;
          padding: 2px 10px;
          font-size: 1.1rem;
          font-weight: bold;
          color: #333;
        }
      }

      .item-image {
        text-align: center;
        margin-bottom: 10px;
      }

      .item-status {
        display: flex;
        justify-content: space-between;
        font-size: 1.4rem;
        margin-bottom: 10px;
      }

      .item-student-message {
        display: inline-block;
        border-radius: 20px;
        background: #fff;
        padding: 2px 10px;
        margin: 0 10px 10px;
        font-size: 1.4rem;
        color: #333;
      }

      .item-price,
      .item-count {
        padding: 3px;
        text-align: center;

        > span {
          display: inline-block;
          background: #FDF873;
          border-radius: 20px;
          padding: 4px;
          line-height: 1;
          margin-top: 3px;
          font-size: 1.1rem;
          font-weight: bold;
        }
      }

      .item-count {
        > span {
          background: #fff;
        }
      }

      .item-more {
        padding: 0 10px;
        margin-bottom: 10px;
        text-align: center;
      }
      .item-remove {
        padding: 0 10px;
        margin-bottom: 10px;
        text-align: center;
      }

      .item-button {
        padding: 5px;
      }



      .item-btn-list {
        display: flex;
        justify-content: center;
        padding: 0 10px;
        margin: 0 0 10px;
        list-style: none;
        > li {
          margin: 0 10px;
        }
      }

    }
  }
  &__more-right {
    text-align: right;
  }
}



.mod_not-clear {
  border: solid 10px #fff;
  border-radius: 20px;
  margin-bottom: 40px;
  background: url("/statics/images/courses/img_not_clear.png") no-repeat center 0;
  background-size: auto;
  @include media-breakpoint-down(md) {
    background: url("/statics/images/courses/img_not_clear.png") no-repeat center 0;
    background-size: 100% auto;
  }
  @include media-breakpoint-down(sm) {
    background: url("/statics/images/courses/img_not_clear.png") no-repeat center 34%;
    background-size: 100% auto;
  }
  &__header {
    background: #fff;
    color: #000;
    font-weight: bold;
    text-align: center;
    padding: 0 10px 10px;
    font-size: 1.4rem;
    .title {
      font-size: 1.6rem;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }
  }
  &__inner {
    padding: 10px;
  }
  &__list {
    min-height: 100px;
  }
  &__message {
    background: rgba(6, 75, 96,.9);
    border: solid 5px #fff;
    border-radius: 10px;
    margin: 20px 0 0;
    padding: 10px;
    text-align: center;
    color: #fff;
  }
}



.mod_message_list {
  display: flex;
  //justify-content: space-between;
  flex-flow: row wrap;
}

.mod_message {
  flex: 0 1 24%;
  margin-bottom: 1em;
  background: #FFF9C6;
  padding: 10px;
  border-radius: 3px;
  @include media-breakpoint-down(sm) {
    flex: 0 1 100%;
  }


  // flexbox均等の対応(space-between使わない）
  &:nth-child(4n+1) {
    margin-right: 5px;
    @include media-breakpoint-down(sm) {
      margin-right: 0;
    }
  }
  &:nth-child(4n+2) {
    margin-right: 5px;
    margin-left: 5px;
    @include media-breakpoint-down(sm) {
      margin-right: 0;
      margin-left: 0;
    }
  }
  &:nth-child(4n+3) {
    margin-right: 5px;
    margin-left: 5px;
    @include media-breakpoint-down(sm) {
      margin-right: 0;
      margin-left: 0;
    }
  }
  &:nth-child(4n+4) {
    margin-left: 5px;
    @include media-breakpoint-down(sm) {
      margin-left: 0;
    }
  }

  &__img {
    margin-bottom: 1%;
    background: #fff;
    img {
      border: solid 1px #000;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }
  &__course {
    word-break: break-all;
    display: flex;
    align-items: flex-start;
    height: 3em;
    overflow: hidden;
  }
  &__note {
    word-break: break-all;
    display: flex;
    align-items: center;
    height: auto;
    .note-icon {
      padding-right: 5px;
    }
    .note-title {
      font-size: 1.6rem;
    }
  }
  &__student {
    word-break: break-all;
    display: flex;
    align-items: flex-start;
    min-height: 3em;
    overflow: hidden;
    font-size: 1.8rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.6rem;
    }
    > img {
      margin-top: -5px;
    }
  }
  a {
    //color: #000;
    &:hover {
      //color: rgba(0,0,0,.5);
      text-decoration: none;
    }
  }


}


.error-title {
  font-weight: bold;
  margin-bottom: 1em;
}
.error-list {
  li {
    text-align: left;
  }
}
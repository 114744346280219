// ----------------------------------------------------------------------------------------------------
// modal modules
// ----------------------------------------------------------------------------------------------------


.modal {
  // ----------------------------------------------------------------------------------------------------
  // common
  // ----------------------------------------------------------------------------------------------------
  &.modal-common {

    .close {
      position: absolute;
      top: -5px;
      right: 0;
      cursor: pointer;
    }

    .error {
      text-align: center;
      color: #f00;
      font-size: 14px;
      margin-bottom: 30px;
    }

    .modal-dialog {
      max-width: 638px;
      text-align: center;

      margin: 0;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      .modal-content {
        background: transparent;
        padding: 0;
        max-width: 640px;
        border: none;
        margin-bottom: 30px;

        .content-shop {
          position: relative;
          border: solid 10px #fff;
          border-radius: 20px;
          margin: 10px;
          @include media-breakpoint-down(md) {
            border-radius: 15px;
            margin-bottom: 20px;
          }

          &__header {
            position: relative;
            background: #fff;
            color: #000;
            text-align: center;
            padding: 0 10px 10px;
            .title {
              font-size: 1.6rem;
              font-weight: bold;
              margin: 0;
            }
          }

          &__inner {
            color: #fff;
            background: #009245;
            padding: 10px;
            overflow: scroll;
            max-height: 90vh;
            @include media-breakpoint-down(md) {
              max-height: 80vh;
            }

            .message {
              padding: 20px;
              min-height: 100px;
              font-size: 3rem;
              line-height: 2;
            }
          }

          .select-btn-list {
            display: flex;
            justify-content: center;
            list-style: none;
            margin: 0;
            padding: 0;
            width: 100%;

            li {
              flex: 0 1 30%;
              padding: 10px;
            }
          }

          .island_select-box {
            position: relative;

            .item-list {
              display: flex;
              justify-content: flex-start;
              flex-flow: row wrap;
              margin: 0;
              padding: 0;
              list-style: none;

              > li {
                flex: 0 1 23%;
                margin: .8%;
                border: solid 1px #fff;
                border-radius: 10px;
                background: #0083BF;
                @include media-breakpoint-down(md) {
                  flex: 0 1 100%;
                }
              }

              .item-header {
                background: #fff;
                border-radius: 8px 8px 0 0;
                padding: 4px;
                text-align: center;
                font-size: 1.4rem;

                .item-student-name {
                  display: block;
                }

                .item-student-id {
                  display: inline-block;
                  border-radius: 20px;
                  background: #eee;
                  padding: 2px 10px;
                  font-size: 1.1rem;
                  font-weight: bold;
                  color: #333;
                }
              }

              .item-image {
                text-align: center;
                margin-bottom: 10px;
              }

              .item-status {
                display: flex;
                justify-content: space-between;
                font-size: 1.4rem;
                margin-bottom: 10px;
              }

              .item-student-message {
                display: inline-block;
                border-radius: 20px;
                background: #fff;
                padding: 2px 10px;
                margin: 0 10px 10px;
                font-size: 1.4rem;
                color: #333;
              }

              .item-price,
              .item-count {
                padding: 3px;
                text-align: center;

                > span {
                  display: inline-block;
                  background: #FDF873;
                  border-radius: 20px;
                  padding: 4px;
                  line-height: 1;
                  margin-top: 3px;
                  font-size: 1.1rem;
                  font-weight: bold;
                }
              }

              .item-count {
                > span {
                  background: #fff;
                }
              }

              .item-more {
                padding: 0 10px;
                margin-bottom: 10px;
                text-align: center;
              }
              .item-remove {
                padding: 0 10px;
                margin-bottom: 10px;
                text-align: center;
              }

              .item-button {
                padding: 5px;
              }
            }
            &__body {
            }
            &__more-right {
              text-align: right;
            }
          }

        }
      }

      .modal-footer {
        border-top: none;
        justify-content: center;
      }

      .modal-regist {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 10px;
        .regist-text {
          color: #fff;
          font-size: 12px;
          padding-right: 10px;
        }
        .regist-button {

        }
      }

      .draw-teacher {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        &__text {
          font-size: 2.4rem;
        }
      }

      .btn-login {
        min-width: 200px;
      }
    }

  }
  // ----------------------------------------------------------------------------------------------------
  // shop
  // ----------------------------------------------------------------------------------------------------
  &.modal-shop {

    .close {
      position: absolute;
      top: -5px;
      right: 0;
      cursor: pointer;
    }

    .error {
      text-align: center;
      color: #f00;
      font-size: 14px;
      margin-bottom: 30px;
    }

    .modal-dialog {
      max-width: 638px;
      text-align: center;

      margin: 0;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      .modal-content {
        background: transparent;
        padding: 0;
        max-width: 640px;
        border: none;
        margin-bottom: 30px;

        .content-shop {
          position: relative;
          border: solid 10px #fff;
          border-radius: 20px;
          margin: 10px;
          @include media-breakpoint-down(md) {
            border-radius: 15px;
            margin-bottom: 20px;
          }

          &__header {
            position: relative;
            background: #fff;
            color: #000;
            text-align: center;
            padding: 0 10px 10px;
            .title {
              font-size: 1.6rem;
              font-weight: bold;
              margin: 0;
            }
          }

          &__inner {
            background: rgba(255, 255, 255, .5);
            padding: 10px;
            overflow: scroll;
            max-height: 90vh;
            @include media-breakpoint-down(md) {
              max-height: 80vh;
            }

            &.type-message {
              background: #65A5BA;
            }

            .message {
            }
          }

          .select-btn-list {
            display: flex;
            justify-content: center;
            list-style: none;
            margin: 0;
            padding: 0;
            width: 100%;

            li {
              flex: 0 1 30%;
              padding: 10px;
            }
          }

          .island_select-box {
            position: relative;

            .item-list {
              display: flex;
              justify-content: flex-start;
              flex-flow: row wrap;
              margin: 0;
              padding: 0;
              list-style: none;

              > li {
                flex: 0 1 23%;
                margin: .8%;
                border: solid 1px #fff;
                border-radius: 10px;
                background: #0083BF;
                @include media-breakpoint-down(md) {
                  flex: 0 1 100%;
                }
              }

              .item-header {
                background: #fff;
                border-radius: 8px 8px 0 0;
                padding: 4px;
                text-align: center;
                font-size: 1.4rem;

                .item-student-name {
                  display: block;
                }

                .item-student-id {
                  display: inline-block;
                  border-radius: 20px;
                  background: #eee;
                  padding: 2px 10px;
                  font-size: 1.1rem;
                  font-weight: bold;
                  color: #333;
                }
              }

              .item-image {
                text-align: center;
                margin-bottom: 10px;
              }

              .item-status {
                display: flex;
                justify-content: space-between;
                font-size: 1.4rem;
                margin-bottom: 10px;
              }

              .item-student-message {
                display: inline-block;
                border-radius: 20px;
                background: #fff;
                padding: 2px 10px;
                margin: 0 10px 10px;
                font-size: 1.4rem;
                color: #333;
              }

              .item-price,
              .item-count {
                padding: 3px;
                text-align: center;

                > span {
                  display: inline-block;
                  background: #FDF873;
                  border-radius: 20px;
                  padding: 4px;
                  line-height: 1;
                  margin-top: 3px;
                  font-size: 1.1rem;
                  font-weight: bold;
                }
              }

              .item-count {
                > span {
                  background: #fff;
                }
              }

              .item-more {
                padding: 0 10px;
                margin-bottom: 10px;
                text-align: center;
              }
              .item-remove {
                padding: 0 10px;
                margin-bottom: 10px;
                text-align: center;
              }

              .item-button {
                padding: 5px;
              }
            }
            &__body {
            }
            &__more-right {
              text-align: right;
            }
          }

        }
      }

      .modal-footer {
        border-top: none;
        justify-content: center;
      }

      .modal-regist {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 10px;
        .regist-text {
          color: #fff;
          font-size: 12px;
          padding-right: 10px;
        }
        .regist-button {

        }
      }

      .draw-teacher {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        &__text {
          font-size: 2.4rem;
        }
      }

      .btn-login {
        min-width: 200px;
      }
    }

  }
}

.modal-dialog {

  .btn-list {
    padding: 20px 100px 20px;
  }


  // ----------------------------------------------------------------------------------------------------
  // login
  // ----------------------------------------------------------------------------------------------------
  &.modal-login {
    max-width: 638px;
    text-align: center;
    .error {
      text-align: center;
      color: #f00;
      font-size: 14px;
      margin-bottom: 30px;
    }

    .modal-login-img {
      margin-bottom: -30px;
      img {
        @include media-breakpoint-down(md) {
          width: 100px;
        }
      }
    }

    .modal-content {
      background: #3DCE9B;
      padding: 0;
      max-width: 640px;
      border: none;
      margin-bottom: 30px;
    }
    .modal-header {
      border: none;
      display: block;
      text-align: center;
      > div {
        width: 100%;
      }
      .close {
        margin-top: -45px;
      }
    }
    .modal-footer {
      border-top: none;
      justify-content: center;
    }

    .modal-regist {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 10px;
      .regist-text {
        color: #fff;
        font-size: 12px;
        padding-right: 10px;
      }
      .regist-button {

      }
    }

    .draw-teacher {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      &__text {
        font-size: 2.4rem;
      }
    }

    .btn-login {
      min-width: 200px;
    }
  }

  // ----------------------------------------------------------------------------------------------------
  // security
  // ----------------------------------------------------------------------------------------------------
  &.modal-security {
    max-width: 638px;
    text-align: center;

    .close {
      position: absolute;
      top: -5px;
      right: 0;
      cursor: pointer;
    }
    .error {
      text-align: center;
      color: #f00;
      font-size: 14px;
      margin-bottom: 30px;
    }

    .modal-login-img {
      margin-bottom: -30px;
      img {
        @include media-breakpoint-down(md) {
          width: 100px;
        }
      }
    }

    .modal-content {
      background: transparent;
      padding: 0;
      max-width: 640px;
      border: none;
      margin-bottom: 30px;
    }
    .modal-header {
      border: none;
      display: block;
      text-align: center;
      > div {
        width: 100%;
      }
      .close {
        margin-top: -45px;
      }
    }
    .modal-footer {
      border-top: none;
      justify-content: center;
    }

    .modal-regist {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 10px;
      .regist-text {
        color: #fff;
        font-size: 12px;
        padding-right: 10px;
      }
      .regist-button {

      }
    }

    .draw-teacher {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      &__text {
        font-size: 2.4rem;
      }
    }

    .btn-login {
      min-width: 200px;
    }
  }
  // ----------------------------------------------------------------------------------------------------
  // hint
  // ----------------------------------------------------------------------------------------------------
  &.modal-hint {
    max-width: 638px;
    text-align: center;
    padding-top: 100px;

    .modal-content {
      position: relative;
      background: #4ADEED;
      padding: 0;
      max-width: 640px;
      border: none;
      margin-bottom: 30px;
      font-size: 2.1rem;
    }
    .close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .modal-header {
      border: none;
      display: block;
      text-align: center;
      > div {
        width: 100%;
      }

      .modal-title {
        margin-top: -50px;
      }
    }
    .modal-footer {
      border-top: none;
      justify-content: center;
    }

    .image-list {
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
      > li {
        padding: 0 10px;
      }
    }
  }

}



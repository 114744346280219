.side-content {

  .widget-wide {
    margin-bottom: 1em;
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }


  .widget-banner {
    margin-bottom: 1em;
    @include media-breakpoint-down(md) {
      text-align: center;
    }
    img {
      max-width: 100%;
    }
  }

  .widget {
    background: #fff;
    border-radius: 20px;
    margin-bottom: 1em;
    &__title {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      background: #1d415d;
      color: #fff;
      border-radius: 20px 20px 0 0;
      padding: 10px;
    }
    &__inner {
      padding: 20px;
    }
  }

  .signup {
    padding: 0;
    background: transparent;
  }

  .profile {
    background: rgba(0,0,0,.5);
    &__name-group {
      background: #fff;
      border-radius: 3px;
      padding: 5px;
      margin-bottom: 10px;
      position: relative;
    }
    &__icon {
      position: absolute;
      top: -35px;
      left: 0;
      width: 50px;
    }
    &__name {
      padding-left: 50px;
    }
    &__level {
      background: #fff;
      border-radius: 3px;
      padding: 5px;
      margin-bottom: 10px;
      flex: 0 1 50%;
    }
    &__money {
      position: relative;
      background: #FDF873;
      border-radius: 3px;
      padding: 5px;
      margin-bottom: 10px;

      .icon {
        display: block;
        position: absolute;
        bottom: 4px;
        left: 4px;
        img {
        }
      }
      .gold-holder,
      .ticket-holder {
        display: block;
        text-align: right;
      }
    }
    .widget__title {
      color: #fff;
    }
  }

  .friend-search {
    position: relative;
    text-align: center;
    &__icons {
      margin-top: -30px;
    }
  }

  .friend-recommend {
    margin-bottom: 10px;

    .item-list {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      margin: 0;
      padding: 0;
      list-style: none;

      > li {
        flex: 0 1 100%;
        margin: 0 0 1em;
        border: solid 2px #65A5BA;
        border-radius: 10px;
        background: #65A5BA;
        overflow: hidden;
        @include media-breakpoint-down(md) {
          flex: 0 1 100%;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      .item-message {
        color: #fff;
        border-radius: 8px 8px 0 0;
        padding: 4px 4px 4px 1.5em;
        text-indent: -1em;
        text-align: left;
        font-size: 1.4rem;
        margin-bottom: 1em;
      }

      .item-header {
        background: #fff;
        border-radius: 8px 8px 0 0;
        padding: 4px;
        text-align: center;
        font-size: 1.4rem;
        margin-bottom: 1em;
        overflow: hidden;

        .item-student-name {
          display: block;
        }

        .item-student-id {
          display: inline-block;
          border-radius: 20px;
          background: #eee;
          padding: 2px 10px;
          font-size: 1.1rem;
          font-weight: bold;
          color: #333;
        }

        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100px;
        }
        .course {
          color: #fff;
          display: flex;
          overflow: hidden;
          a {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 120px;
          }
        }
      }

      .item-image {
        text-align: center;
        margin-top: 1em;
        margin-bottom: 10px;
      }

      .item-status {
        display: flex;
        justify-content: space-between;
        font-size: 1.4rem;
        margin-bottom: 10px;
      }

      .item-student-message {
        display: block;
        border-radius: 20px;
        background: #fff;
        padding: 2px 10px;
        margin: 0 10px 10px;
        font-size: 1.2rem;
        color: #333;
        text-align: center;
      }
      
      .item-btn-list {
        display: flex;
        justify-content: center;
        padding: 0 10px;
        margin: 0 0 10px;
        list-style: none;
        > li {
          margin: 0 10px;
        }
      }

      .item-price,
      .item-count {
        padding: 3px;
        text-align: center;

        > span {
          display: inline-block;
          background: #FDF873;
          border-radius: 20px;
          padding: 4px;
          line-height: 1;
          margin-top: 3px;
          font-size: 1.1rem;
          font-weight: bold;
        }
      }

      .item-count {
        > span {
          background: #fff;
        }
      }

      .item-more {
        padding: 0 10px;
        margin-bottom: 10px;
        text-align: center;
      }
    }

    &__challenge {
      background: #FBB48E;
      border-radius: 5px;
      padding: 10px;
    }
  }

  .categories {
    &__list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 0;
      a {
        flex: 0 1 48%;
        color: #fff;
        font-weight: bold;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        padding: 0.375rem 0;
        font-size: 1.6rem;
        line-height: 1.5;
        border-radius: 0.25rem;
        margin-bottom: 5px;
        &:hover {
          opacity: 0.8;
          text-decoration: none;
        }
        &:last-child {
 //         margin-bottom: 0; // 偶数だといらなとか？
        }
      }
    }
  }

  .ranking {
    &__list {
      margin: 0;
      padding: 0;
      list-style: none;
      > li {
        padding-left: 2em;
        text-indent: -1em;
        margin-bottom: 10px;
      }
      dt {
        display: block;
        padding-right: 10px;
        flex: 0 1 10%;
      }
      dd {
        display: block;
        background: #2D60BB;
        flex: 0 1 90%;
        padding: 5px 10px;
        border-radius: 5px;
        color: #fff;
      }
    }
    .more {
      text-align: right;
    }
  }

  .mini-student {
    margin-bottom: 10px;

    .item-list {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      margin: 0;
      padding: 0;
      list-style: none;

      > li {
        flex: 0 1 48%;
        margin: 0 0 1em;
        border: solid 2px #65A5BA;
        border-radius: 10px;
        background: #65A5BA;
        overflow: hidden;
        @include media-breakpoint-down(md) {
          flex: 0 1 100%;
        }
      }

      .item-message {
        color: #fff;
        border-radius: 8px 8px 0 0;
        padding: 4px 4px 4px 1.5em;
        text-indent: -1em;
        text-align: left;
        font-size: 1.4rem;
        margin-bottom: 1em;
      }

      .item-header {
        background: #fff;
        border-radius: 8px 8px 0 0;
        padding: 4px;
        text-align: center;
        font-size: 1.4rem;
        margin-bottom: 1em;
        overflow: hidden;

        .item-student-name {
          display: block;
        }

        .item-student-id {
          display: inline-block;
          border-radius: 20px;
          background: #eee;
          padding: 2px 10px;
          font-size: 1.1rem;
          font-weight: bold;
          color: #333;
        }

        .name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100px;
        }
        .course {
          color: #fff;
          display: flex;
          overflow: hidden;
          a {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 120px;
          }
        }
      }

      .item-image {
        text-align: center;
        margin-top: 1em;
        margin-bottom: 10px;
      }

      .item-status {
        display: flex;
        justify-content: space-between;
        font-size: 1.4rem;
        margin-bottom: 10px;
      }

      .item-student-message {
        display: inline-block;
        border-radius: 20px;
        background: #fff;
        padding: 2px 10px;
        margin: 0 10px 10px;
        font-size: 1.4rem;
        color: #333;
      }

      .item-price,
      .item-count {
        padding: 3px;
        text-align: center;

        > span {
          display: inline-block;
          background: #FDF873;
          border-radius: 20px;
          padding: 4px;
          line-height: 1;
          margin-top: 3px;
          font-size: 1.1rem;
          font-weight: bold;
        }
      }

      .item-count {
        > span {
          background: #fff;
        }
      }

      .item-more {
        padding: 0 10px;
        margin-bottom: 10px;
        text-align: center;
      }
    }

    &__challenge {
      background: #FBB48E;
      border-radius: 5px;
      padding: 10px;
    }
  }

  .mini-teachers {

    .mini-teacher-list {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      margin: 0;
      padding: 0;
      list-style: none;
      > li {
        margin-bottom: 2em;
      }
    }

    .mini-teacher {
      &__icon {
        min-width: 5em;
      }
      &__name {
        font-weight: bold;
        font-size: 1.2rem;
        a {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100px;
          display: block;
        }
      }
      &__image {
        border-radius: 20px;
        background: #fff;
        img {
          border-radius: 20px;
        }
      }
      &__profile {
        font-size: 1.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 160px;
      }
    }

  }

  .mini-messages {

    .mini-message_list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      > li {
        flex: 0 1 48%;
        overflow: hidden;
      }
    }

    .mini-message {
      margin-bottom: 1em;
      background: #FFF9C6;
      padding: 10px;
      border-radius: 3px;
      width: 100%;
      @include media-breakpoint-down(sm) {
        flex: 0 1 100%;
      }

      &__img {
        margin-bottom: 1%;
        background: #fff;
        img {
          border: solid 1px #000;
          @include media-breakpoint-down(sm) {
            width: 100%;
          }
        }
      }
      &__course {
        margin-bottom: 5px;
        overflow: hidden;
        a {
          //color: #000;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100px;
          display: inline-block;
          &:hover {
            //color: rgba(0,0,0,.5);
            text-decoration: none;
          }
        }
      }
      &__student {
        word-break: break-all;
        overflow: hidden;
        display: flex;
        align-items: center;
        a {
          //color: #000;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100px;
          display: inline-block;
          &:hover {
            //color: rgba(0,0,0,.5);
            text-decoration: none;
          }
        }
      }


    }

  }


}

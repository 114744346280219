#page-teachers {

  .teacher-header {
    position: relative;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(sm) {
      display: block;
    }
    &__img {
      position: relative;
      margin-right: 10px;
      @include media-breakpoint-down(sm) {
        width: 160px;
        margin: 0 auto 10px;
      }
      img {
        border-radius: 20px;
      }
      .mod_heart {
        position: absolute;
        bottom: 5px;
        right: 5px;
      }
    }
    &__message {
      background: rgba(255,255,255,.2);
      color: #fff;
      border: solid 1px rba(255,255,.5);
      border-radius: 20px;
      padding: 12px;
      display: flex;
      align-items: center;
      width: 100%;
      margin-right: 20px;
      font-size: 1.6rem;

      white-space: pre-wrap;
      word-break: break-all;
      @include media-breakpoint-down(sm) {
        margin-right: 0;
        font-size: 1.4rem;
      }

      .inner {
        max-height: 134px;
        overflow: scroll;
        @include media-breakpoint-down(sm) {
          max-height: initial;
        }
      }
    }
    &__primary {
      display: flex;
      flex: 0 1 80%;
      @include media-breakpoint-down(sm) {
        display: block;
        margin-bottom: 20px;
      }
    }
    &__secondary {
      position: relative;
      flex: 0 1 20%;
      text-align: center;
      display: flex;
      justify-content: center;
      .chart-ttl {
        padding-top: 10px;
        text-align: center;
        color: #fff;
        font-size: 1.6rem;
      }
      .make-quest {
        position: absolute;
        top: 42%;
        left: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        width: 100%;
      }
      .chart {
        width: 160px;
        height: 160px;
      }
    }
    &__single {
      display: flex;
      align-items: center;
      flex: 0 1 100%;
    }
    &__profile {
      .name {
        color: #fff;
      }
    }

  }

  .footer-img {
    text-align: center;
  }



}
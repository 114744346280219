.mod_more {
  text-align: right;
  margin-bottom: 30px;
}
.mod_btn-more {
  position: relative;
  display: inline-block;
  background: #fff;
  color: #F66901;
  line-height: 1;
  padding: 8px 8px 8px 26px;
  border-radius: 20px;
  font-size: 1.6rem;
  &::before {
    content: '';
    width: 10px;
    height: 10px;
    border: 0;
    border-top: solid 4px #FCB48D;
    border-right: solid 4px #FCB48D;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    left: 10px;
    margin-top: -6px;
  }
  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
  &.btn-s {
    font-size: 1.2rem;
  }
}
.mod_btn-more-l {
  position: relative;
  display: inline-block;
  background: #fff;
  color: #F66901;
  line-height: 1;
  padding: 8px 15px 8px 30px;
  border-radius: 20px;
  //border: solid 2px #F66901;
  &::before {
    content: '';
    width: 10px;
    height: 10px;
    border: 0;
    border-top: solid 4px #FCB48D;
    border-right: solid 4px #FCB48D;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    left: 10px;
    margin-top: -6px;
  }
  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
}


.mod_btn {
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  display: inline-block;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;

  &.btn-normal {
    background-color: #F66900;
    border-color: #F66900;
    color: #fff;
    font-size: 1.6rem;
    padding: 4px 20px;
    @include media-breakpoint-down(md) {
      font-size: 1.2rem;
    }
  }

  &.btn-small {
    background-color: #F66900;
    border-color: #F66900;
    color: #fff;
    font-size: 1.4rem;
    padding: 4px 8px;
    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }

  &.btn-cancel {
    background-color: #999;
    border-color: #999;
    color: #fff;
    font-size: 1.6rem;
    padding: 4px;
    @include media-breakpoint-down(md) {
      font-size: 1.2rem;
    }
  }

  &.btn-maker-submit {
    background-color: #F66900;
    color: #fff;
    font-size: 1.6rem;
    font-weight: bold;
    padding: 10px 40px;
    border: solid 6px #fff;
    border-radius: 40px;
    @include media-breakpoint-down(md) {
      font-size: 1.2rem;
    }
    &:hover {
      background: #F68F44;
      opacity: 1;
    }
  }

  &.btn-block {
    display: block;
  }

  &.btn-inline-block {
    display: inline-block;
  }

  &.btn-s {
    font-size: 1.2rem;
  }
  &.btn-l {
    font-size: 2.0rem;
  }

  &.btn-r10 {
    border-radius: 30px;
  }

  &.btn-deco {
    position: relative;
    overflow: hidden;
    border: 1px solid transparent;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border-left: solid 5px rgba(255,255,255,.5);
      height: 100%;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      border-left: solid 5px rgba(255,255,255,.5);
      height: 100%;
    }
  }
  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }

  &.btn-disabled {
    cursor: default;
    background: #999;
    &:hover {
      opacity: 1;
    }
  }

  &.btn-radius20 {
    border-radius: 20px;
  }
}



.btn_play {
  display: block;
  position: relative;
  width: 240px;
  height: 240px;
  background: #E4712D;
  cursor: pointer;
  border: solid 10px #fff;
  border-radius: 120px;
  transition: transform 0.2s;
  animation: bntPlay 1s linear infinite;
  @include media-breakpoint-down(sm) {
    margin: auto;
  }

  .score {
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    text-align: center;
    color: #000;
    font-weight: bold;
    font-size: 21px;
    animation: bntPlayScore 1s linear infinite;
  }
  .monster {
    position: absolute;
    bottom: 60px;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .text {
    padding: 10px;
    color: #fff;
    font-size: 2.4rem;
    font-weight: bold;
    text-shadow: 3px 3px 3px rgba(0,0,0,.5);
  }
  .bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 120px;
  }

  &:hover {
    animation: paused;
    text-decoration: none;
    .monster {
      animation: bntPlayMonsterOn 1s linear infinite;
    }
    .score {
    }
  }


  &.step_1 {
    .bg {
      background: url("/statics/images/courses/btn_play_1_bg.svg") no-repeat center center;
      background-size: 110%;
    }
    &:hover {
      .bg {
        animation: bntPlay1on 4s linear infinite;
      }
    }
  }
}

.btn_answer {
  display: block;
  text-align: center;
  margin: 0;
  border: none;
  cursor: pointer;
  outline: none;
  appearance: none;

  border-radius: 10px;
  background-color: #F66900;
  width: 100%;
  padding: 5px;

  transition: background .2s;

  &:hover {
    background-color: #f6b672;
  }

  &.type_maru {
    &.checked {
      background: #ED1C24;
    }
    &.unchecked {
      background: #4D4D4D;
    }
  }

  &.type_batsu {
    &.checked {
      background: #0000FF;
    }
    &.unchecked {
      background: #4D4D4D;
    }
  }

  &.disabled {
    cursor: default !important;
  }
}

.btn_exam {
  display: block;
  position: relative;
  width: 160px;
  height: 160px;
  background: #E4712D;
  cursor: pointer;
  border: solid 10px #fff;
  border-radius: 120px;
  text-align: center;
  outline: none;
  appearance: none;
  @include media-breakpoint-down(sm) {
    margin: auto;
  }

  &.not_clear {

    .image {
      margin-top: -20px;
    }
  }

  &.has_clear {
    cursor: default;
    background: #999;
  }

  .text {
    padding: 10px;
    color: #fff;
    font-size: 1.8rem;
    font-weight: bold;
    text-shadow: 3px 3px 3px rgba(0,0,0,.5);
  }


  &.step_1 {
    .bg {
      background: url("/statics/images/courses/btn_play_1_bg.svg") no-repeat center center;
      background-size: 110%;
    }
    &:hover {
      .bg {
        animation: bntPlay1on 1s linear infinite;
      }
    }
  }
}


.btn_island_build {
  display: block;
  position: relative;
  width: 160px;
  height: 160px;
  background: #E4712D;
  cursor: pointer;
  border: solid 10px #fff;
  border-radius: 120px;
  text-align: center;
  transition: opacity .2s;
  outline: none;
  appearance: none;
  @include media-breakpoint-down(sm) {
    margin: auto;
  }
  &:focus,
  &:hover {
    opacity: .8;
    outline: none;
    appearance: none;
  }


  .image {
    position: relative;
    margin-top: -20px;
  }

  .text {
    padding: 0 10px;
    color: #fff;
    font-size: 1.6rem;
    line-height: 1.2;
    font-weight: bold;
    text-shadow: 3px 3px 3px rgba(0,0,0,.5);
  }


  &.step_1 {
    .bg {
      background: url("/statics/images/courses/btn_play_1_bg.svg") no-repeat center center;
      background-size: 110%;
    }
    &:hover {
      .bg {
        animation: bntPlay1on 1s linear infinite;
      }
    }
  }
}


// ボタンと共通デザイんだが、押せないやつ
.badge_play_not {
  display: block;
  position: relative;
  width: 240px;
  height: 240px;
  background: #999;
  border: solid 10px #fff;
  border-radius: 120px;
  transition: transform 0.2s;

  .text {
    position: absolute;
    top: 30%;
    left: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 36px;
    font-weight: bold;
  }
}

.badge_play_result {
  display: block;
  position: relative;
  width: 240px;
  height: 240px;
  background: #07008b;
  border-radius: 120px;
  transition: transform 0.2s;

  .stars {
    position: absolute;
    top: 33%;
    left: 0;
    width: 100%;
    text-align: center;
  }

  &.anim-perfect-stars {
    overflow: hidden;
    .bg {
      position: absolute;
      top: -150%;
      left: -130%;
      width: 300%;
      height: 300%;
      background: url("/statics/images/courses/btn_play_1_bg.svg") no-repeat center center;
      background-size: 100%;
      animation: perfectStartsBg 9s linear infinite, perfectStartsBgLight 9s ease infinite;
    }
    img {
      animation: perfectStarts 5s infinite;
    }
  }
}

.box_play_result {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  background: #07008b;
  border-radius: 20px;
  max-width: 500px;
  margin: auto;
  padding: 10px;
  transition: transform 0.2s;

  &.anim-perfect-stars {
    overflow: hidden;
    .stars {
      img {
        animation: perfectStarts 5s infinite;
      }
    }
  }

  .stars {
    width: 100%;
  }
}
.box_play_not {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  background: #999;
  border-radius: 20px;
  max-width: 500px;
  margin: auto;
  padding: 10px;
  border: solid 3px #fff;

  .preview {
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 36px;
    font-weight: bold;
    min-height: 64px;
  }
}



.btn_quest_next {
  position: relative;
  width: 160px;
  height: 160px;
  background: #E4712D;
  cursor: pointer;
  border: solid 10px #fff;
  border-radius: 120px;
  transition: transform 0.2s;
  animation: bntPlay 1s linear infinite;

  display: flex;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-down(sm) {
    margin: auto;
  }

  .text {
    color: #fff;
    font-size: 2.2rem;
    font-weight: bold;
    text-shadow: 3px 3px 3px rgba(0,0,0,.5);
  }

  &:hover {
    animation: paused;
    text-decoration: none;
    .monster {
      animation: bntPlayMonsterOn 1s linear infinite;
    }
  }

  &:hover,
  &:focus {
    outline: none;
    appearance: none;
  }

}

.btn_quest_cancel {
  position: relative;
  width: 160px;
  height: 160px;
  background: #A3A3A3;
  cursor: pointer;
  border: solid 10px #fff;
  border-radius: 120px;
  transition: transform 0.2s;
  animation: bntPlay 1s linear infinite;

  display: flex;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-down(sm) {
    margin: auto;
  }

  .text {
    padding: 10px;
    color: #fff;
    font-size: 2.2rem;
    font-weight: bold;
    text-shadow: 3px 3px 3px rgba(0,0,0,.5);
  }

  &:hover {
    animation: paused;
    text-decoration: none;
    .monster {
      animation: bntPlayMonsterOn 1s linear infinite;
    }
  }

  &:hover,
  &:focus {
    outline: none;
    appearance: none;
  }

}

.btn_quest_back {
  position: relative;
  width: 100px;
  height: 100px;
  background: #878787;
  cursor: pointer;
  border: solid 10px #fff;
  border-radius: 120px;
  transition: transform 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-down(sm) {
    margin: auto;
  }

  .text {
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 3px 3px 3px rgba(0,0,0,.5);
  }

  &:hover {
    animation: paused;
    text-decoration: none;
  }

  &:hover,
  &:focus {
    outline: none;
    appearance: none;
  }

}
#page-home {

  .gheader__logo {
    //transform: scale(1);
  }

  .content-wrapper {
    position: relative;
    z-index: 1;
  }

  .side-nav {
    position: absolute;
    top: 0;
    right: 0;
    width: 320px;
    background: #fff;
    border-radius: 3px;
    .nav {
      .nav-link {
        color: #999;
        border-bottom: solid 2px #e5e5e5;
        padding: 1rem;
        background: rgba(255,255,255,.055);
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  .main-content {
    min-height: 400px;

    .page-header {
      position: relative;
      z-index: 2;
      margin-top: -130px;
      margin-bottom: 30px;
      &__tips {
        position: absolute;
        top: 10px;
        background: #F66800;
        color: #fff;
        font-size: 10px;
        padding: 3px 10px;
        text-shadow: none;
      }
      &__title {
        position: absolute;
        width: 640px;
        box-sizing: border-box;
        bottom: 20px;
        margin: 0 0 0 200px;
        background: #F2E3D8;
        color: #333;
        font-weight: bold;
        font-size: 4em;
        text-shadow: 3px 3px 3px #eee;
        border: solid 6px #9D5928;
        padding: 20px;
      }
    }
    .categories {
      background: #fff;
      margin-bottom: 20px;
      padding: 20px;
      border-radius: 10px;
      &__title {
        font-size: 18px;
        font-weight: bold;
      }
      &__list {
        margin-bottom: 20px;
        .category-link {
          margin: 3px;
          padding: 3px 10px;
          color: #fff;
          background: rgba(246, 105, 0, 1);
          border-radius: 3px;
          border: solid 1px #333;
        }
      }
      .btn {
        color: #fff;
        //background: #F37A57;
        width: 16%;
        font-weight: bold;
      }
    }

  }


  .difficult {
    &__wrap {
      //display: flex;
      //align-items: center;
    }
    &__title {
      font-size: 18px;
      font-weight: bold;
    }
    &__list {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      background: #eee;
      border-radius: 10px;
      li {
        text-align: center;
        padding: 0 20px;
      }
    }
  }


}

#page-students {
  &.monsters {

    .news {
      position: relative;
      background: #fffac1;
      padding: 20px;
      margin-bottom: 20px;
      @include media-breakpoint-down(md) {
        padding: 15px 10px 5px 10px;
        font-size: 1.4rem;
      }
      &__ttl {
        position: absolute;
        top: -20px;
        left: -15px;
        transform: rotate(-10deg);
        @include media-breakpoint-down(md) {
          top: -25px;
          left: 0;
        }
      }
      &__detail {
        margin-bottom: 20px;
      }
      &__stars {
        display: flex;
        item-align: center;
        > div {
          padding: 0 10px;
        }
      }
    }


  }

  // --------------------------------------------------
  // page regist
  // --------------------------------------------------

  &.regist {

    .regist-ttl {
      position: relative;
      background: url(/statics/images/students/regist_ttl.svg) no-repeat center 0;
      background-size: 100% auto;
      padding: 30px 50px 0 50px;
      min-height: 100px;
      margin-bottom: 30px;
      text-align: center;
      @include media-breakpoint-down(md) {
        padding: 0 10vw 10vw;
        min-height: auto;
      }
      .title {
        font-weight: bold;
        font-size: 2.4rem;
        @include media-breakpoint-down(md) {
          position: absolute;
          left: 5vw;
          bottom: 2vw;
          @include fz_vw(12);
        }
      }
    }

    .reward-list {
      display: flex;
      justify-content: space-between;
      margin: 0 0 30px;
      padding: 0;
      list-style-type: none;
      > li {
        flex: 0 1 32%;
        border: solid 10px #fff;
        border-radius: 30px;
        padding: 10px 10px 240px 10px;
        text-align: center;
        @include media-breakpoint-down(md) {
          border-radius: 15px;
          border: solid 5px #fff;
          padding: 5px 5px 20vw 5px;
          font-size: 1.4rem;
        }
        &:nth-child(1) {
          background: #65DEF2 url(/statics/images/students/regist_img_1.svg) no-repeat center bottom -3px;
          background-size: 100% auto;
        }
        &:nth-child(2) {
          background: #65DEF2 url(/statics/images/students/regist_img_2.svg) no-repeat center bottom -3px;
          background-size: 102% auto;
        }
        &:nth-child(3) {
          background: #65DEF2 url(/statics/images/students/regist_img_3.svg) no-repeat center bottom -3px;
          background-size: 102% auto;
        }
      }
    }

    .regist-form {
      position: relative;
      background: #fff;
      border-radius: 30px;
      padding: 40px;
      @include media-breakpoint-down(md) {
        border-radius: 15px;
        padding: 20px;
      }

      .form-item {
        display: flex;
        width: 100%;
        margin-bottom: 1rem;
        @include media-breakpoint-down(md) {
          flex-flow: row wrap;
        }
        &__icon {
          flex: 0 1 10%;
          @include media-breakpoint-down(md) {
            flex: 0 1 20%;
            order: 2;
          }
        }
        &__name {
          flex: 0 1 30%;
          padding: 5px 0;
          @include media-breakpoint-down(md) {
            flex: 0 1 80%;
            order: 1;
          }
        }
        &__main {
          flex: 0 1 70%;
          @include media-breakpoint-down(md) {
            flex: 0 1 100%;
            order: 3;
          }
        }
        &__btn {
          flex: 0 1 100%;
          text-align: center;
        }
        .note {
          font-size: 1.4rem;
        }
      }

      .alert-error {
        color: #f00;
        font-size: 1.4rem;
        padding: 10px;
      }

      .btn-disabled {
        background: none;
        border: solid 1px #999;
        padding: 10px;
        font-size: 1.4rem;
        color: #666;
      }


      .illustration-1 {
        position: absolute;
        bottom: 60px;
        left: 60px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        align-items: flex-end;
        @include media-breakpoint-down(md) {
          position: relative;
          bottom: 0;
          left: 0;
        }
      }
      .illustration-2 {
        position: absolute;
        bottom: 60px;
        right: 60px;
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        align-items: flex-end;
        @include media-breakpoint-down(md) {
          position: relative;
          bottom: 0;
          right: 0;
          justify-content: flex-end;
        }
        li {
          &:nth-child(even) {
            padding-bottom: 40px;
          }
        }
      }
    }

    .regist-message {
      background: #fff;
      border-radius: 30px;
      padding: 40px;
      margin-bottom: 30px;
      @include media-breakpoint-down(md) {
        border-radius: 15px;
        padding: 20px;
      }
      .caution {
        border: solid 1px #f00;
        border-radius: 10px;
        padding: 20px;
        text-align: center;
      }
    }

    .regist-complete {
      background: #65DEF2;
      border: solid 10px #fff;
      border-radius: 30px;
      padding: 40px;
      margin-bottom: 30px;
      @include media-breakpoint-down(md) {
        border-radius: 15px;
        padding: 20px 0;
      }
      &__img {
        position: relative;
        background: url(/statics/images/students/regist_complete.png) no-repeat center center;
        background-size: 100% auto;
        padding: 90px 50px 0 50px;
        min-height: 358px;
        margin-bottom: 30px;
        text-align: center;
        @include media-breakpoint-down(md) {
          padding: 20vw 10vw 25vw;
          min-height: auto;
        }
        .title {
          font-weight: bold;
          font-size: 2.4rem;
          text-shadow: 0 0 3px rgba(255,255,255,1);
          @include media-breakpoint-down(md) {
            position: absolute;
            left: 0;
            top: 9vw;
            width: 100%;
            @include fz_vw(12);
          }
        }
      }
      &__btn {
        text-align: center;
      }
    }

  }




}